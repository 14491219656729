import axios from "axios";
import { GET_ALL_INSURANCE_URL } from "../api";
export const getInsuranceCompanies = () => {
  return axios
    .get(GET_ALL_INSURANCE_URL)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};
