import axios from "axios";
import {
    CREATE_PRODUCT_URL,
    GET_INSURANCE_PRODUCT_URL,
    UPDATE_PRODUCT_URL,
    ADD_DISCOUNT_URL,
    ADD_LOADING_URL,
    ADD_EXTENSION_URL,
    ADD_PRODUCT_PERIL_URL,
    UPDATE_PRODUCT_PERIL_URL,
    DELETE_PRODUCT_PERIL_URL
} from "../api";

export const saveProduct = (raw, id) => {
    return axios.post(CREATE_PRODUCT_URL(id), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};

export const updateProduct = (raw) => {
    return axios.put(UPDATE_PRODUCT_URL(1), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};

export const deleteProductPeril = (id) => {
    return axios.delete(DELETE_PRODUCT_PERIL_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
}

export const updateProductPeril = (id) => {
    return axios.put(UPDATE_PRODUCT_PERIL_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
}

export const addProductPeril = (id, raw) => {
    return axios.post(ADD_PRODUCT_PERIL_URL(id), raw).then((response) => {
        console.log(response.data);
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
}

export const getInsuranceProduct = (id) => {
    return axios.get(GET_INSURANCE_PRODUCT_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};

export const addons = (data, type) => {
    switch (type) {
        case "discount":
            return axios.post(ADD_DISCOUNT_URL, data).then((response) => {
                return {
                    error: false,
                    payload: response.data
                }
            }).catch((error) => {
                return {
                    error: true,
                    payload: error
                }
            });

        case "extension":
            return axios.post(ADD_EXTENSION_URL, data).then((response) => {
                return {
                    error: false,
                    payload: response.data
                }
            }).catch((error) => {
                return {
                    error: true,
                    payload: error
                }
            });

        case "loading":
            return axios.post(ADD_LOADING_URL, data).then((response) => {
                return {
                    error: false,
                    payload: response.data
                }
            }).catch((error) => {
                return {
                    error: true,
                    payload: error
                }
            });

        default:
            return {
                error: true,
                payload: "Invalid Type"
            }
    }
};
