import axios from "axios";
import {
  GET_INSURANCE_ALL_PRODUCT,
  UPLOAD_POLICY_TO_RTSA,
  UPLOAD_POLICY_TO_RTSA_AUTOMATIC,
} from "../api";

export const receiveInsurancePolicies = (id) => {
  return axios
    .get(GET_INSURANCE_ALL_PRODUCT(id))
    .then((response) => {
      return false;
    })
    .catch((error) => {
      console.log("Something went wrong");
    });
};

export const uploadToRtsa = (data) => {
  return axios
    .post(UPLOAD_POLICY_TO_RTSA, data)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data["message"],
      };
    });
};
export const uploadToRtsaAutomatic = (id) => {
  return axios
    .post(UPLOAD_POLICY_TO_RTSA_AUTOMATIC(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data["message"],
      };
    });
};
