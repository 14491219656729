import React, { useEffect, useState } from "react";
import {
  GET_INSURANCE_DISCOUNT_URL,
  GET_INSURANCE_EXTENSION_URL,
  GET_PRODUCT_URL,
  GET_THIRD_PARTY_RATE,
} from "../../../api";
import {
  actionInsuranceCompany,
  addons,
  addRateToRiskCategory,
  getOrganization,
  saveProduct,
  updateRateToRiskCategory,
  uploadGeneralDocument,
  uploadProductDocument,
} from "../../../request";
import $ from "jquery";
import "components-jqueryui";
import "parsleyjs";
import "select2";
import "datatables.net";
import "datatables.net-responsive";
import cogoToast from "cogo-toast";
import { useDispatch, useSelector } from "react-redux";
import truncate from "../../../utils/trucate";
import products from "../../../data/products.json";
import { decodeQueryParameter } from "../../../utils/url";
import moment from "moment";

var wordingsData = [];
var clausesData = [];
var perilsData = [];
var dtRiskCategoryId = 0;

const InsuranceDetails = (props) => {
  document.title = "Insurance Profile";

  const dispatch = useDispatch();

  let {
    id,
    name,
    logoUrl,
    registrationNumber,
    companyAddress,
    companyEmail,
    account,
    tpin,
  } = decodeQueryParameter(props.match.params.insurance);
  const { riskCategory } = useSelector(
    (state) => state.organization.riskCategory
  );

  const [createLoading, setCreateLoading] = useState(false);
  const [perilsName, setPerilsName] = useState([]);
  const [perilsType, setPerilsType] = useState([]);
  const { gariCode } = useSelector((state) => state.organization.gariCode);
  const { product } = useSelector((state) => state.organization);
  const [loading, setLoading] = useState(false);

  const [risk_category, set_risk_category] = useState({});
  const [premiumList, setPremiumList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState(0);

  const [branch, setBranch] = useState([]);
  const { bank } = useSelector((state) => state.organization.bank);
  const { accountType } = useSelector(
    (state) => state.organization.accountType
  );

  useEffect(() => {
    setInsurance(decodeQueryParameter(props.match.params.insurance));
    dispatch(getOrganization(id));

    var table = $("#product").DataTable({
      responsive: true,
      language: {
        processing:
          '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Product Data</p>',
      },
      searching: false,
      lengthMenu: [[10], [10]],
      ordering: false,
      info: true,
      bFilter: false,
      processing: true,
      pageLength: 10,
      serverSide: true,
      ajax: function (data, callback) {
        // make a regular ajax request using data.start and data.length
        $.ajax(GET_PRODUCT_URL(id), {
          type: "GET",
          data: {
            pageNumber: (data.length + data.start) / data.length,
            pageSize: data.length,
          },
          success: function (res) {
            let result = [];

            res.data.map((data) => {
              result.push({
                raw: data,
              });
            });

            callback({
              recordsTotal: res.totalRecords,
              recordsFiltered: res.totalRecords,
              data: result,
            });
          },
        });
      },
      columns: [
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
      ],
      columnDefs: [
        {
          render: function (data) {
            return data.name;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data.code;
          },
          targets: 1,
        },
        {
          render: function (data) {
            return data.policyNumberPrefix;
          },
          targets: 2,
        },
        {
          render: function (data) {
            return data.claimNumberPrefix;
          },
          targets: 3,
        },
        {
          render: function (data) {
            return data.gariCode ? data.gariCode.code : "Not Set";
          },
          targets: 4,
        },
      ],
    });

    $("#product tbody").on("click", "tr", function () {
      var extract_data = table.row(this).data();
      props.history.push(`/product-details/${extract_data.raw.id}`);
    });

    var discount_table = $("#discount").DataTable({
      responsive: true,
      language: {
        processing:
          '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Discount Data</p>',
      },
      searching: false,
      lengthMenu: [[10], [10]],
      ordering: false,
      info: true,
      bFilter: false,
      processing: true,
      pageLength: 10,
      serverSide: true,
      ajax: function (data, callback) {
        // make a regular ajax request using data.start and data.length
        $.ajax(GET_INSURANCE_DISCOUNT_URL(id), {
          type: "GET",
          data: {
            pageNumber: (data.length + data.start) / data.length,
            pageSize: data.length,
          },
          success: function (res) {
            let result = [];

            res.map((data) => {
              result.push({
                raw: data,
              });
            });

            callback({
              recordsTotal: res.length,
              recordsFiltered: res.length,
              data: result,
            });
          },
        });
      },
      columns: [
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
      ],
      columnDefs: [
        {
          render: function (data) {
            return data.name !== null ? data.name : "Not Set";
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data.description !== null
              ? truncate(data.description, 50, "...")
              : "Not Set";
          },
          targets: 1,
        },
        {
          render: function (data) {
            return moment(data.createdAt).format("Do MMM, YYYY");
          },
          targets: 2,
        },
        {
          render: function (data) {
            return moment(data.updateddAt).format("Do MMM, YYYY");
          },
          targets: 3,
        },
      ],
    });

    $("#discount tbody").on("click", "tr", function () {
      let extract_data = discount_table.row(this).data();
      if (!extract_data) return false;
      $("#modalDiscountInfo").modal("show");
      $(".tx_name").text(extract_data.raw.name);
      $(".tx_description").text(extract_data.raw.description);
    });

    var extension_table = $("#extension").DataTable({
      responsive: true,
      language: {
        processing:
          '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Extension Data</p>',
      },
      searching: false,
      lengthMenu: [[10], [10]],
      ordering: false,
      info: true,
      bFilter: false,
      processing: true,
      pageLength: 10,
      serverSide: true,
      ajax: function (data, callback) {
        // make a regular ajax request using data.start and data.length
        $.ajax(GET_INSURANCE_EXTENSION_URL(id), {
          type: "GET",
          data: {
            pageNumber: (data.length + data.start) / data.length,
            pageSize: data.length,
          },
          success: function (res) {
            let result = [];

            res.map((data) => {
              result.push({
                raw: data,
              });
            });

            callback({
              recordsTotal: res.length,
              recordsFiltered: res.length,
              data: result,
            });
          },
        });
      },
      columns: [
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
      ],
      columnDefs: [
        {
          render: function (data) {
            return data.name !== null ? data.name : "Not Set";
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data.description !== null
              ? truncate(data.description, 50, "...")
              : "Not Set";
          },
          targets: 1,
        },
        {
          render: function (data) {
            return moment(data.createdAt).format("Do MMM, YYYY");
          },
          targets: 2,
        },
        {
          render: function (data) {
            return moment(data.updateddAt).format("Do MMM, YYYY");
          },
          targets: 3,
        },
      ],
    });

    $("#extension tbody").on("click", "tr", function () {
      let extract_data = extension_table.row(this).data();
      if (!extract_data) return false;
      $("#modalExtensionInfo").modal("show");
      $(".tx_name").text(extract_data.raw.name);
      $(".tx_description").text(extract_data.raw.description);
    });

    set_risk_category(
      riskCategory.find((data) => {
        return data.id == riskCategory[0].id;
      })
    );

    dtRiskCategoryId = riskCategory[0].id;
    let risk_category_table = $("#risk_category").DataTable({
      responsive: true,
      language: {
        processing:
          '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Product Data</p>',
      },
      searching: false,
      lengthMenu: [[10], [10]],
      ordering: false,
      info: true,
      bFilter: false,
      processing: true,
      pageLength: 10,
      serverSide: true,
      ajax: function (data, callback) {
        // make a regular ajax request using data.start and data.length
        $.ajax(GET_THIRD_PARTY_RATE(dtRiskCategoryId), {
          type: "GET",
          data: {
            pageNumber: (data.length + data.start) / data.length,
            pageSize: data.length,
          },
          success: function (res) {
            let result = [];

            let products = res.filter((data) => {
              return (
                data.riskCategoryId == dtRiskCategoryId &&
                data.product.insuranceCompanyId == id
              );
            });

            products.map((data) => {
              result.push({
                raw: {
                  data: data,
                  products: products,
                },
              });
            });

            callback({
              recordsTotal: products.length,
              recordsFiltered: products.length,
              data: result,
            });
          },
        });
      },
      columns: [
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
      ],
      columnDefs: [
        {
          render: function (data) {
            return `${data.data.product.name} ${
              data.data.thirdPartyBasicPremium.quarter === 1
                ? `<span class="badge badge-success tx-white">${data.data.thirdPartyBasicPremium.quarter} Quarter</span>`
                : `<span class="badge badge-success tx-white">${data.data.thirdPartyBasicPremium.quarter} Quarters</span>`
            }`;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return `K${data.data.thirdPartyBasicPremium.premium}`;
          },
          targets: 1,
        },
        {
          render: function (data) {
            return moment(data.data.product.updatedAt).format("Do MMM, YYYY");
          },
          targets: 2,
        },
        {
          render: function () {
            return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_list"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_list"><i class="fa fa-trash-alt"></i> Delete</a></nav>`;
          },
          targets: 3,
        },
      ],
    });

    $(`#${riskCategory[0].id}`).addClass("active");

    $(`#risk_category`).on("click", ".edit_list", function (e) {
      e.preventDefault();
      let extract_td = risk_category_table.row($(this).closest("tr")).data();
      $("#basicRate").parsley();

      setEdit(true);
      setEditId(extract_td.raw.data.id);
      $("#rate_product")
        .val(extract_td.raw.data.product.id)
        .prop("disabled", true);
      $("#rate_name")
        .val(extract_td.raw.data.product.name)
        .prop("disabled", true);
      $("#rate_description")
        .val(extract_td.raw.data.product.description)
        .prop("disabled", true);
      $("#rate_quarter")
        .val(extract_td.raw.data.thirdPartyBasicPremium.quarter)
        .prop("disabled", true);
      $("#rate_premium").val(
        extract_td.raw.data.thirdPartyBasicPremium.premium
      );

      $("#modalRate").modal({ backdrop: "static", keyboard: false }, "show");
    });

    $(`#risk_category`).on("click", ".delete_list", function (e) {
      e.preventDefault();
      cogoToast.error("Sorry, delete option is disabled.", {
        position: "top-right",
      });
    });

    $(`#risk_category`).on("click", ".view_list", function (e) {
      e.preventDefault();
      let extract_td = risk_category_table.row($(this).closest("tr")).data();
      setPremiumList(extract_td.raw.data.product);
      $(".name").text(extract_td.raw.data.product.name);
      $(".code").text(extract_td.raw.data.product.code);
      $("#description").text(extract_td.raw.data.product.description);
      $("#policyNumberPrefix").text(
        extract_td.raw.data.product.policyNumberPrefix
      );
      $("#claimNumberPrefix").text(
        extract_td.raw.data.product.claimNumberPrefix
      );
      $("#modalViewRate").modal("show");

      $(".list-group-item").remove();

      extract_td.raw.products
        .filter((data) => data.product.id == extract_td.raw.data.product.id)
        .map((data, index) => {
          $(".list-group").append(
            '<li class="list-group-item d-flex pd-sm-x-20">\n' +
              '                                                    <div class="avatar d-none d-sm-block"><span class="avatar-initial rounded-circle bg-success"><i class="icon ion-md-checkmark"></i></span></div>\n' +
              '                                                    <div class="pd-sm-l-10">\n' +
              '                                                        <p class="tx-medium mg-b-0">' +
              (index + 1) +
              " Quarter" +
              (index > 0 ? "s" : "") +
              "</p>\n" +
              '                                                        <small class="tx-12 tx-color-03 mg-b-0">' +
              moment(data.thirdPartyBasicPremium.updatedAt).format(
                "Do MMM, YYYY"
              ) +
              "</small>\n" +
              "                                                    </div>\n" +
              '                                                    <div class="mg-l-auto text-right">\n' +
              '                                                        <p class="tx-medium mg-b-0">ZMW' +
              data.thirdPartyBasicPremium.premium +
              "</p>\n" +
              '                                                        <small class="tx-12 tx-success mg-b-0">Active</small>\n' +
              "                                                    </div>\n" +
              "                                                </li>"
          );
        });
    });
  }, []);

  const setInsurance = (data) => {
    let {
      id,
      name,
      logoUrl,
      registrationNumber,
      companyPhone,
      tpin,
      companyAddress,
      companyEmail,
      account,
    } = data;
    $("#companyName").text(name);
    $("#companyAddress").text(companyAddress);
    $("#companyEmail").text(companyEmail);
    $("#tpin").text(tpin);
    $("#companyPhone").text(companyPhone || "Not Set");
    $("#registrationNumber").text(registrationNumber);
    $("#accountName").text(account.accountName);
    $("#accountNumber").text(account.accountNumber);
  };

  const handleShowModal = () => {
    $("#wordingForm").parsley();
    $("#clausesForm").parsley();
    $("#basicInfo").parsley();

    $("#perilsName").prop("disabled", true);

    $("#td-wordings").DataTable({
      responsive: true,
      searching: false,
      lengthMenu: [[10], [10]],
      data: wordingsData,
      ordering: false,
      info: false,
      bFilter: false,
      processing: false,
      pageLength: 10,
      serverSide: false,
      columnDefs: [
        {
          render: function (data) {
            return data;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data;
          },
          targets: 1,
        },
        {
          render: function () {
            return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`;
          },
          targets: 2,
        },
      ],
    });

    $("#td-clauses").DataTable({
      responsive: true,
      searching: false,
      lengthMenu: [[10], [10]],
      data: clausesData,
      ordering: false,
      info: false,
      bFilter: false,
      processing: false,
      pageLength: 10,
      serverSide: false,
      columnDefs: [
        {
          render: function (data) {
            return data;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data;
          },
          targets: 1,
        },
        {
          render: function () {
            return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`;
          },
          targets: 2,
        },
      ],
    });

    $("#td-perils").DataTable({
      responsive: true,
      searching: false,
      lengthMenu: [[10], [10]],
      data: perilsData,
      ordering: false,
      info: false,
      bFilter: false,
      processing: false,
      pageLength: 10,
      serverSide: false,
      columnDefs: [
        {
          render: function (data) {
            return data;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data;
          },
          targets: 1,
        },
        {
          render: function () {
            return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`;
          },
          targets: 2,
        },
      ],
    });

    $("#modalAddProduct").modal(
      { backdrop: "static", keyboard: false },
      "show"
    );
  };

  const handleBasicSubmit = (e) => {
    e.preventDefault();
    $("#nav_basic .active").parent().next("li").find("a").trigger("click");
  };

  const handleProductSubmit = async (e) => {
    e.preventDefault();

    setCreateLoading(true);

    if (!perilsData.length)
      return cogoToast.error("Please, enter at least one perils or more.");

    let raw = {
      name: $("#name").val(),
      code: $("#code").val(),
      description: $("#description").val(),
      policyNumberPrefix: $("#policyNumber").val(),
      claimNumberPrefix: $("#claimNumber").val(),
      premiumRates: {
        premium: $("#premiumName").val(),
        levy: $("#levy").val(),
        maximumLimit: $("#maximumLimit").val(),
        minimumLimit: $("#minimumLimit").val(),
      },
      gariCodeId: $("#gariCode").val(),
      perils: perilsData.map((data) => ({
        name: data[0],
        type: data[1],
        description: data[2],
      })),
      policyWordings: [
        {
          documentUrl: await uploadDocument("wordingsFile"),
          description: $("#wordingsDescription").val(),
        },
      ],
      policyClauses: [
        {
          documentUrl: await uploadDocument("clausesFile"),
          description: $("#clausesDescription").val(),
        },
      ],
    };

    saveProduct(raw, id).then((res) => {
      const options = {
        position: "top-right",
      };

      setCreateLoading(false);

      if (res.error) {
        setCreateLoading(false);
        return cogoToast.error("Something went wrong.", options);
      }

      $("#modalAddProduct").modal("hide");
      $(".modal-backdrop").remove();
      $("#product").DataTable().ajax.reload();

      perilsData = [];
      cogoToast.success("Product successfully Added.", options);
    });
  };

  const handleResetForm = () => {
    destroyForm("basicInfo");
    destroyForm("wordingForm");
    destroyForm("clausesForm");
    destroyForm("perilsForm");
    $("#td-wordings").DataTable().destroy();
    $("#td-clauses").DataTable().destroy();
    $("#td-perils").DataTable().destroy();
    $("#perilsName").prop("disabled", true);
    $("#modalAddProduct").modal("hide");
    $(".modal-backdrop").remove();
    perilsData = [];
  };

  const onCloseModal = (id, form) => {
    const parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    $(`#${id}`).modal("hide");
    parsley.reset();
    parsley.destroy();
    $(".modal-backdrop").remove();
  };

  const destroyForm = (form) => {
    const parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    parsley.reset();
    parsley.destroy();
  };

  const handleWordingSubmit = (e) => {
    e.preventDefault();
    $("#nav_basic .active").parent().next("li").find("a").trigger("click");
  };

  const handleClausesSubmit = (e) => {
    e.preventDefault();
    $("#nav_basic .active").parent().next("li").find("a").trigger("click");
  };

  const handlePerilsSubmit = (e) => {
    e.preventDefault();

    perilsData = [
      [
        $("#perilsName").val(),
        $("#perilsType").val(),
        $("#perilsDescription").val(),
        Math.random(),
      ],
      ...perilsData,
    ];

    $("#td-perils").DataTable().destroy();

    reloadTable("td-perils", perilsData);

    $("#perilsName").val(null);
    $("#perilsType").val(null);
    $("#perilsDescription").val(null);
  };

  const handleShowDiscountModal = (form) => {
    $(`#${form}`).parsley();
    $("#modalDiscount").modal("show");
  };

  const reloadTable = (id, data) => {
    let table = $(`#${id}`).DataTable({
      responsive: true,
      searching: false,
      lengthMenu: [[3], [3]],
      data: data,
      ordering: false,
      info: false,
      bFilter: false,
      processing: false,
      pageLength: 3,
      serverSide: false,
      columnDefs: [
        {
          render: function (data) {
            return data;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return truncate(data, 25, "...");
          },
          targets: 1,
        },
        {
          render: function (data) {
            return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list" data-id=${data}><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_list"><i class="fa fa-trash-alt"></i> Delete</a></nav>`;
          },
          targets: 2,
        },
      ],
    });

    $(`#${id}`).on("click", ".edit_list", function () {
      let extract_td = table.row($(this).closest("tr")).data();

      if (extract_td !== undefined) {
        if (`${id.split("-").pop()}Data` === "perilsData") {
          $(`#${id.split("-").pop()}Name`).val(extract_td[0]);
          $(`#${id.split("-").pop()}Type`).val(extract_td[1]);
          $(`#${id.split("-").pop()}Description`).val(extract_td[2]);
        } else {
          $(`#${id.split("-").pop()}Heading`).val(extract_td[0]);
          $(`#${id.split("-").pop()}Description`).val(extract_td[1]);
        }
      }

      return false;
    });

    $(`#${id}`).on("click", ".delete_list", function () {
      let extract_td = table.row($(this).closest("tr")).data();

      if (extract_td !== undefined) {
        if (`${id.split("-").pop()}Data` === "wordingsData") {
          wordingsData = wordingsData.filter((data) => {
            return data[2] !== extract_td[2];
          });

          $(`#${id.split("-").pop()}Heading`).val(null);
          $(`#${id.split("-").pop()}Description`).val(null);

          reloadTable("td-wordings", wordingsData);
        } else if (`${id.split("-").pop()}Data` === "clausesData") {
          clausesData = clausesData.filter((data) => {
            return data[2] !== extract_td[2];
          });

          $(`#${id.split("-").pop()}Heading`).val(null);
          $(`#${id.split("-").pop()}Description`).val(null);

          reloadTable("td-clauses", clausesData);
        } else {
          perilsData = perilsData.filter((data) => {
            return data[2] !== extract_td[2];
          });

          $(`#PerilsName`).val(null);
          $(`#PerilsDescription`).val(null);
          $(`#PerilsType`).val(null);

          reloadTable("td-perils", perilsData);
        }
      }

      return false;
    });
  };

  const uploadDocument = (id) => {
    let [files] = $(`#${id}`).prop("files");

    if (files === undefined) return null;

    const formData = new FormData();

    formData.append("File", files);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      encType: "multipart/form-data",
    };

    return uploadProductDocument(formData, config).then((result) => {
      if (!result.error) {
        return result.payload.url;
      }

      return null;
    });
  };

  const onProductNameChange = (e) => {
    e.preventDefault();
    let findData = products.find((data) => {
      return data.name === e.target.value;
    });

    $("#perilsType").val(null);
    $("#perilsName").val(null);

    setPerilsType(findData.type);
  };

  const onPerilsTypeChange = (e) => {
    e.preventDefault();
    let findData = perilsType.find((data) => {
      return data.name === e.target.value;
    });

    $("#perilsName").prop("disabled", false);

    setPerilsName(findData.data);
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    $("#nav_basic .active").parent().prev("li").find("a").trigger("click");
  };

  const handleShowExtensionModal = (form) => {
    $(`#${form}`).parsley();
    $("#modalExtension").modal("show");
  };

  const onAddDiscountSubmit = (e) => {
    e.preventDefault();

    let data = {
      name: $("#discount_name").val(),
      description: $("#discount_description").val(),
      insuranceCompanyId: id,
    };

    setLoading(true);
    addons(data, "discount").then((result) => {
      setLoading(false);
      if (result.error) {
        return cogoToast.error("Something went wrong");
      }

      $("#discount").DataTable().ajax.reload();
      onCloseModal("modalDiscount", "discount_basic");
    });
  };

  const onAddExtensionSubmit = (e) => {
    e.preventDefault();

    let data = {
      name: $("#extension_name").val(),
      description: $("#extension_description").val(),
      insuranceCompanyId: id,
    };

    setLoading(true);
    addons(data, "extension").then((result) => {
      setLoading(false);
      if (result.error) {
        return cogoToast.error("Something went wrong");
      }

      $("#extension").DataTable().ajax.reload();
      onCloseModal("modalExtension", "extension_basic");
    });
  };

  const onBankChange = (e) => {
    let findBank = bank.find((data) => {
      return e.target.value == data.id;
    });

    findBank.branches.length
      ? $("#bankBranch").prop("disabled", false)
      : $("#bankBranch").prop("disabled", true);
    setBranch(findBank.branches);
  };

  const onBranchChange = (e) => {
    let filteredBranch = branch.find((data) => {
      return data.id == e.target.value;
    });
    $("#branchCode").val(filteredBranch.code);
  };

  const handleInsuranceSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let payload = {
      name: $("#name").val(),
      companyEmail: $("#email").val(),
      companyAddress: $("#address").val(),
      registrationNumber: $("#regNumber").val(),
      tpin: $("#tpin").val(),
      logoUrl: await uploadLogoDocument("Logo"),
      account: {
        accountName: $("#accountName").val(),
        accountNumber: $("#accountNumber").val(),
        accountTypeId: $("#accountType").val(),
        branchId: $("#bankBranch").val(),
      },
    };

    actionInsuranceCompany(payload, "put").then((result) => {
      setLoading(false);
      if (result.error) {
        return cogoToast.error("Something went wrong");
      }

      handleResetForm("modalInsurance", "insuranceForm");
      $("#insurance").DataTable().ajax.reload();
    });
  };

  const uploadLogoDocument = (type) => {
    let [files] = $(`#logo`).prop("files");

    if (files === undefined) return null;

    const formData = new FormData();

    formData.append("Description", `${type}`);
    formData.append("File", files);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      encType: "multipart/form-data",
    };

    return uploadGeneralDocument(formData, config).then((result) => {
      if (!result.error) {
        return result.payload.documentUrl;
      }

      return null;
    });
  };

  const handleEditInsuranceModal = () => {
    $("#_companyName").val(name);
    $("#email").val(companyEmail);
    $("#address").val(companyAddress);
    $("#regNumber").val(registrationNumber);
    $("#companyTpin").val(tpin);
    $("#_accountName").val(account.accountName);
    $("#_accountNumber").val(account.accountNumber);
    $("#accountType").val(account.accountType);

    $("#insuranceForm").parsley();
    $("#modalInsurance").modal("show");
  };

  const handleRiskCategory = (id) => {
    riskCategory.map((data) => {
      $(`#${data.id}`).removeClass("active");
    });

    dtRiskCategoryId = id;
    $("#risk_category").DataTable().ajax.reload();
    $(`#${id}`).addClass("active");
  };

  const onPremiumRateSubmit = (e) => {
    e.preventDefault();

    let raw = {
      productId: $("#rate_product").val(),
      riskCategoryId: dtRiskCategoryId,
      name: $("#rate_name").val(),
      description: $("#rate_description").val(),
      quarter: $("#rate_quarter").val(),
      premium: $("#rate_premium").val(),
    };

    setLoading(true);

    if (edit) {
      let edit_raw = {
        id: editId,
        quarter: $("#rate_quarter").val(),
        premium: $("#rate_premium").val(),
      };

      updateRateToRiskCategory(edit_raw).then(async (res) => {
        setLoading(false);
        if (res.error)
          return cogoToast.error("Something went wrong.", {
            position: "top-right",
          });

        cogoToast.success("Premium Rate successfully Updated.", {
          position: "top-right",
        });
        onCloseModal("modalRate", "basicRate");
        $("#risk_category").DataTable().ajax.reload();
      });
      return false;
    }

    addRateToRiskCategory(raw).then(async (res) => {
      setLoading(false);
      if (res.error)
        return cogoToast.error("Something went wrong.", {
          position: "top-right",
        });

      cogoToast.success("Premium Rate successfully created.", {
        position: "top-right",
      });
      onCloseModal("modalRate", "basicRate");
      $("#risk_category").DataTable().ajax.reload();
    });
  };

  const handleShowRate = (e) => {
    e.preventDefault();
    setEdit(false);
    $("#basicRate").parsley();
    $("#rate_product").prop("disabled", false);
    $("#rate_name").prop("disabled", false);
    $("#rate_description").prop("disabled", false);
    $("#rate_quarter").prop("disabled", false);
    $("#modalRate").modal({ backdrop: "static", keyboard: false }, "show");
  };

  return (
    <div className="content-body">
      <div className="container pd-x-0">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div className="media">
            <div className="wd-100 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center">
              <img src={logoUrl} className="img-fluid rounded-5" />
            </div>
            <div className="media-body pd-l-25 my-auto">
              <h5 className="mg-b-5">{name}</h5>
              <p className="mg-b-3">
                <span className="tx-medium tx-color-02">
                  {registrationNumber}
                </span>
                <br />
                {companyAddress}
              </p>
              <span className="d-block tx-13 tx-color-03">{companyEmail}</span>
            </div>
          </div>
          <div className="d-none d-md-block">
            <button
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
              onClick={handleEditInsuranceModal.bind()}
            >
              <i className="wd-10 mg-r-5 fa fa-user-plus"></i> Edit Profile
            </button>
          </div>
        </div>

        <div className="row row-xs">
          <div className="col-md-12 mg-t-10">
            <div className="nav-wrapper mg-b-20 tx-13">
              <div>
                <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                  <li className="nav-item">
                    <a
                      href="#insurance-tab"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      <i className="ion-ios-person" /> Insurance Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#product-tab"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="ion-ios-paper" /> Products
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#risk-category-tab"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="ion-ios-document" /> Risk Category{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#discount-tab"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="ion-ios-car" /> Discount{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#extension-tab"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="ion-ios-car" /> Extension{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tab-content">
              <div id="insurance-tab" className="tab-pane fade active show">
                <div className="row row-sm">
                  <div className="col-md-12">
                    <p>
                      <strong>Company Name:</strong>{" "}
                      <span id="companyName">Blank</span>
                    </p>
                    <p>
                      <strong>Company Email:</strong>{" "}
                      <span id="companyEmail">Blank</span>
                    </p>
                    <p>
                      <strong>Company Address:</strong>{" "}
                      <span id="companyAddress">Blank</span>
                    </p>
                    <p>
                      <strong>Registration Number:</strong>{" "}
                      <span id="registrationNumber">Blank</span>
                    </p>
                    <p>
                      <strong>TPIN:</strong> <span id="tpin">Blank</span>
                    </p>
                    <p>
                      <strong>Mobile Number:</strong>{" "}
                      <span id="companyPhone">Blank</span>
                    </p>
                    <p>
                      <strong>Account Name:</strong>{" "}
                      <span id="accountName">Blank</span>{" "}
                    </p>
                    <p>
                      <strong>Account Number:</strong>{" "}
                      <span id="accountNumber">Blank</span>
                    </p>
                  </div>
                </div>
              </div>

              <div id="product-tab" className="tab-pane fade">
                <div className="row row-xs">
                  <div className="col-md-12 mg-t-10">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h6 className="mg-b-0">Products</h6>
                        <div className="d-flex tx-18">
                          <a
                            href="javascript:void(0)"
                            className="link-03 lh-0"
                            onClick={handleShowModal.bind()}
                          >
                            <i className="icon ion-md-add"></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="link-03 lh-0 mg-l-10"
                            onClick={() =>
                              $("#product").DataTable().ajax.reload()
                            }
                          >
                            <i className="icon ion-md-refresh"></i>
                          </a>
                        </div>
                      </div>
                      <div className="card-body table-responsive">
                        <table
                          id="product"
                          className="table table-hover"
                          style={{ width: "100%" }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th className="wd-20p">Name</th>
                              <th className="wd-20p">Code</th>
                              <th className="wd-20p">Policy Prefix</th>
                              <th className="wd-20p">Claim Prefix</th>
                              <th className="wd-20p">Gari Code</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="risk-category-tab" className="tab-pane fade">
                <div className="row row-xs">
                  <div className="col-md-12 mg-t-10">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <nav className="nav nav-row-xs align-items-center">
                          {riskCategory.map((data) => {
                            return (
                              <a
                                href="javascript:void(0)"
                                id={data.id}
                                className="nav-link"
                                onClick={handleRiskCategory.bind(null, data.id)}
                              >
                                {data.name}
                              </a>
                            );
                          })}
                        </nav>
                        <div className="d-flex tx-18">
                          <a
                            href="javascript:void(0)"
                            className="link-03 lh-0"
                            onClick={handleShowRate.bind()}
                          >
                            <i className="icon ion-md-add"></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="link-03 lh-0 mg-l-10"
                            onClick={() =>
                              $("#discount").DataTable().ajax.reload()
                            }
                          >
                            <i className="icon ion-md-refresh"></i>
                          </a>
                        </div>
                      </div>
                      <div className="card-body table-responsive">
                        <table
                          id="risk_category"
                          className="table table-hover"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th className="wd-20p">Name</th>
                              <th className="wd-20p">Rate</th>
                              <th className="wd-20p">Date</th>
                              <th className="wd-20p">Action</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="discount-tab" className="tab-pane fade">
                <div className="row row-xs">
                  <div className="col-md-12 mg-t-10">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h6 className="mg-b-0">Discount</h6>
                        <div className="d-flex tx-18">
                          <a
                            href="javascript:void(0)"
                            className="link-03 lh-0"
                            onClick={handleShowDiscountModal.bind(
                              null,
                              "discount_basic"
                            )}
                          >
                            <i className="icon ion-md-add"></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="link-03 lh-0 mg-l-10"
                            onClick={() =>
                              $("#discount").DataTable().ajax.reload()
                            }
                          >
                            <i className="icon ion-md-refresh"></i>
                          </a>
                        </div>
                      </div>
                      <div className="card-body table-responsive">
                        <table
                          id="discount"
                          className="table table-hover"
                          style={{ width: "100%" }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th className="wd-25p">Name</th>
                              <th className="wd-30p">Description</th>
                              <th className="wd-20p">Date Created</th>
                              <th className="wd-20p">Date Updated</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="extension-tab" className="tab-pane fade">
                <div className="row row-xs">
                  <div className="col-md-12 mg-t-10">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h6 className="mg-b-0">Extension</h6>
                        <div className="d-flex tx-18">
                          <a
                            href="javascript:void(0)"
                            className="link-03 lh-0"
                            onClick={handleShowExtensionModal.bind(
                              null,
                              "extension_basic"
                            )}
                          >
                            <i className="icon ion-md-add"></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="link-03 lh-0 mg-l-10"
                            onClick={() =>
                              $("#extension").DataTable().ajax.reload()
                            }
                          >
                            <i className="icon ion-md-refresh"></i>
                          </a>
                        </div>
                      </div>
                      <div className="card-body table-responsive">
                        <table
                          id="extension"
                          className="table table-hover"
                          style={{ width: "100%" }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th className="wd-25p">Name</th>
                              <th className="wd-30p">Description</th>
                              <th className="wd-20p">Date Created</th>
                              <th className="wd-20p">Date Updated</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalAddProduct"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href="javascript:void(0)"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  onClick={handleResetForm.bind()}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Create Product</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Create products to be added to the system
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <div className="nav-wrapper mg-b-20 tx-13">
                <div>
                  <ul
                    id="nav_basic"
                    className="nav nav-line nav-fill tx-medium nav-disabled"
                  >
                    <li className="nav-item">
                      <a
                        href="#basic"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        <i className="ion-md-information-circle" /> Basic
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#wordings"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-md-document" /> Wordings
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#clauses" className="nav-link" data-toggle="tab">
                        <i className="ion-md-document" /> Clauses
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#perils" className="nav-link" data-toggle="tab">
                        <i className="ion-md-document" /> Perils
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tab-content">
                <div id="basic" className="tab-pane fade active show">
                  <form
                    id="basicInfo"
                    className="parsley-style-1"
                    onSubmit={handleBasicSubmit.bind()}
                  >
                    <div className="row row-sm">
                      <div
                        id="gariWrapper"
                        className="form-group parsley-input col-sm-12"
                      >
                        <label>
                          Gari Code<span className="tx-danger">*</span>
                        </label>
                        <select
                          className="custom-select"
                          id="gariCode"
                          required
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          {gariCode.map((data) => (
                            <option value={data.id}>{data.code}</option>
                          ))}
                        </select>
                      </div>

                      <div
                        id="nameWrapper"
                        className="form-group parsley-input col-sm-12"
                      >
                        <label>
                          Name<span className="tx-danger">*</span>
                        </label>
                        <select
                          className="custom-select"
                          id="name"
                          onChange={onProductNameChange.bind()}
                          required
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          {products.map((data) => (
                            <option value={data.name}>{data.name}</option>
                          ))}
                        </select>
                      </div>

                      <div
                        id="codeWrapper"
                        className="form-group parsley-input col-sm-12"
                      >
                        <label>Code</label>
                        <input
                          id="code"
                          className="form-control"
                          placeholder="Enter code"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#codeWrapper"
                          required
                        />
                      </div>

                      <div
                        id="descriptionWrapper"
                        className="form-group parsley-input col-sm-12"
                      >
                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                          Description
                        </label>
                        <textarea
                          id="description"
                          rows="5"
                          className="form-control"
                          placeholder="Provide a description of the property"
                          data-parsley-class-handler="#descriptionWrapper"
                          required
                        />
                      </div>

                      <div
                        id="premiumNameWrapper"
                        className="form-group parsley-input col-sm-12"
                      >
                        <label>
                          Premium Rate(%)<span className="tx-danger">*</span>
                        </label>
                        <input
                          id="premiumName"
                          className="form-control"
                          placeholder="Enter amount"
                          type="number"
                          step="any"
                          autoComplete="off"
                          data-parsley-class-handler="#premiumNameWrapper"
                          required
                        />
                      </div>

                      <div
                        id="levyWrapper"
                        className="form-group parsley-input col-sm-4"
                      >
                        <label>Levy Rate(%)</label>
                        <input
                          id="levy"
                          className="form-control"
                          placeholder="Enter levy"
                          type="number"
                          step="any"
                          autoComplete="off"
                          data-parsley-class-handler="#levyWrapper"
                          required
                        />
                      </div>

                      <div
                        id="minimumLimitWrapper"
                        className="form-group parsley-input col-sm-4"
                      >
                        <label>Minimum Limit Rate(%)</label>
                        <input
                          id="minimumLimit"
                          className="form-control"
                          placeholder="Enter minimum limit"
                          type="number"
                          autoComplete="off"
                          step="any"
                          data-parsley-class-handler="#minimumLimitWrapper"
                          required
                        />
                      </div>

                      <div
                        id="maximumLimitWrapper"
                        className="form-group parsley-input col-sm-4"
                      >
                        <label>Maximum Limit Rate(%)</label>
                        <input
                          id="maximumLimit"
                          className="form-control"
                          placeholder="Enter maximum limit"
                          type="number"
                          autoComplete="off"
                          step="any"
                          data-parsley-class-handler="#maximumLimitWrapper"
                          required
                        />
                      </div>

                      <div
                        id="policyPrefixWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>
                          Policy Number Prefix
                          <span className="tx-danger">*</span>
                        </label>
                        <input
                          id="policyNumber"
                          className="form-control"
                          placeholder="Enter policy number prefix"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#policyPrefixWrapper"
                          required
                        />
                      </div>

                      <div
                        id="claimPrefixWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>
                          Claim Number Prefix
                          <span className="tx-danger">*</span>
                        </label>
                        <input
                          id="claimNumber"
                          className="form-control"
                          placeholder="Enter claim prefix number"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#claimPrefixWrapper"
                          required
                        />
                      </div>
                    </div>
                    <button className="btn btn-brand-01 float-right btnNextAd">
                      Next <i className="ion-md-arrow-forward" />
                    </button>
                  </form>
                </div>

                <div id="wordings" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="row row-sm">
                        <form
                          id="wordingForm"
                          className="parsley-style-1 col-md-12"
                          onSubmit={handleWordingSubmit.bind()}
                        >
                          <div className="row row-sm">
                            <div
                              id="headingWrapper"
                              className="form-group parsley-input col-sm-12"
                            >
                              <label>
                                Upload Wording File
                                <span className="tx-danger">*</span>
                              </label>
                              <input
                                id="wordingsFile"
                                className="form-control"
                                placeholder="Select"
                                type="file"
                                data-parsley-class-handler="#headingWrapper"
                                required
                              />
                            </div>

                            <div
                              id="wordingWrapper"
                              className="form-group parsley-input col-sm-12"
                            >
                              <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                                Description<span className="tx-danger">*</span>
                              </label>
                              <textarea
                                id="wordingsDescription"
                                rows="5"
                                className="form-control"
                                placeholder="Provide a description of the wordings"
                                data-parsley-class-handler="#wordingWrapper"
                                required
                              />
                            </div>
                          </div>

                          <div className="mt-3">
                            <hr />
                            <a
                              href="#"
                              className="btn btn-dark float-left btnPrevious"
                              onClick={handlePrevious.bind()}
                            >
                              <i className="ion-md-arrow-back" /> Back
                            </a>
                            <button className="btn btn-brand-01 float-right btnNextAd">
                              Next <i className="ion-md-arrow-forward" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="clauses" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="row row-sm">
                        <form
                          id="clausesForm"
                          className="parsley-style-1 col-md-12"
                          onSubmit={handleClausesSubmit.bind()}
                        >
                          <div className="row row-sm">
                            <div
                              id="clausesWrapper"
                              className="form-group parsley-input col-sm-12"
                            >
                              <label>
                                Upload Clauses File
                                <span className="tx-danger">*</span>
                              </label>
                              <input
                                id="clausesFile"
                                className="form-control"
                                placeholder="Select"
                                type="file"
                                data-parsley-class-handler="#clausesWrapper"
                                required
                              />
                            </div>

                            <div
                              id="clausesWrapper"
                              className="form-group parsley-input col-sm-12"
                            >
                              <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                                Description<span className="tx-danger">*</span>
                              </label>
                              <textarea
                                id="clausesDescription"
                                rows="5"
                                className="form-control"
                                placeholder="Provide a description of the wordings"
                                data-parsley-class-handler="#clausesWrapper"
                                required
                              />
                            </div>
                          </div>

                          <div className="mt-3">
                            <hr />
                            <a
                              href="#"
                              className="btn btn-dark float-left btnPrevious"
                              onClick={handlePrevious.bind()}
                            >
                              <i className="ion-md-arrow-back" /> Back
                            </a>
                            <button className="btn btn-brand-01 float-right btnNextAd">
                              Next <i className="ion-md-arrow-forward" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="perils" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <table
                        id="td-perils"
                        className="table"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th className="wd-30p">Name</th>
                            <th className="wd-30p">Type</th>
                            <th className="wd-30p">Actions</th>
                          </tr>
                        </thead>
                      </table>

                      <div className="row row-sm">
                        <form
                          id="perilsForm"
                          className="parsley-style-1 col-md-12"
                          data-parsley-validate
                          noValidate
                          onSubmit={handlePerilsSubmit.bind()}
                        >
                          <div className="row row-sm">
                            <div
                              id="typeWrapper"
                              className="form-group parsley-input col-sm-12"
                            >
                              <label>
                                Type<span className="tx-danger">*</span>
                              </label>
                              <select
                                className="custom-select"
                                id="perilsType"
                                onChange={onPerilsTypeChange.bind()}
                                required
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                {perilsType.map((data) => (
                                  <option value={data.name}>{data.name}</option>
                                ))}
                              </select>
                            </div>

                            <div
                              id="perilsWrapper"
                              className="form-group parsley-input col-sm-12"
                            >
                              <label>
                                Name<span className="tx-danger">*</span>
                              </label>
                              <select
                                className="custom-select"
                                id="perilsName"
                                required
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                {perilsName.map((data) => (
                                  <option value={data}>{data}</option>
                                ))}
                              </select>
                            </div>

                            <div
                              id="perilsDescriptionWrapper"
                              className="form-group parsley-input col-sm-12"
                            >
                              <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                                Description<span className="tx-danger">*</span>
                              </label>
                              <textarea
                                id="perilsDescription"
                                type="text"
                                rows="5"
                                className="form-control"
                                placeholder="Provide a descipriton of the perils"
                                data-parsley-class-handler="#perilsDescriptionWrapper"
                                required
                              ></textarea>
                            </div>

                            <div className="col-md-12 text-center">
                              <button className="btn btn-brand-02 mt-2">
                                <i className="ion-md-add" /> Add List
                              </button>
                            </div>
                          </div>
                        </form>

                        <div className="col-md-12 mt-3">
                          <hr />
                          <button
                            className="btn btn-dark float-left btnPrevious"
                            onClick={handlePrevious.bind()}
                          >
                            <i className="ion-md-arrow-back" /> Back
                          </button>
                          {createLoading ? (
                            <button
                              disabled
                              className="btn btn-brand-02 float-right"
                            >
                              <span
                                className="spinner-border spinner-border-sm mg-r-10"
                                role="status"
                                aria-hidden="true"
                              />
                              Please Wait
                            </button>
                          ) : (
                            <button
                              className="btn btn-brand-01 float-right btnNextFront"
                              onClick={handleProductSubmit.bind()}
                            >
                              Finish <i className="ion-md-arrow-forward" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalDiscount"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href="javascript:void(0)"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  onClick={onCloseModal.bind(
                    null,
                    "modalDiscount",
                    "discount_basic"
                  )}
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Add Discount</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Fill the information below.
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <form
                id="discount_basic"
                className="parsley-style-1"
                onSubmit={onAddDiscountSubmit.bind()}
              >
                <div className="row row-sm">
                  <div
                    id="nameWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Name<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="discount_name"
                      className="form-control"
                      placeholder="Enter name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#nameWrapper"
                      required
                    />
                  </div>

                  <div
                    id="descriptionWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                      Description
                    </label>
                    <textarea
                      id="discount_description"
                      type="text"
                      rows="5"
                      className="form-control"
                      placeholder="Provide a description"
                      data-parsley-class-handler="#descriptionWrapper"
                      required
                    ></textarea>
                  </div>
                </div>
                {loading ? (
                  <button disabled className="btn btn-brand-02 btn-block">
                    <span
                      className="spinner-border spinner-border-sm mg-r-10"
                      role="status"
                      aria-hidden="true"
                    />
                    Please Wait
                  </button>
                ) : (
                  <button className="btn btn-brand-02 btn-block mt-2">
                    <i className="ion-md-add" /> Add Discount
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalDiscountInfo"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href=""
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Discount</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Discount full information.
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <div className="row row-sm">
                <div className="col-md-12">
                  <p>
                    <strong>Name:</strong>
                    <br />
                    <span className="tx_name">Blank</span>
                  </p>
                  <p>
                    <strong>Description:</strong>
                    <br />
                    <span className="tx_description">Blank</span>
                  </p>
                  <button
                    className="btn btn-brand-01 mt-4"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalExtension"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href="javascript:void(0)"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  onClick={onCloseModal.bind(
                    null,
                    "modalExtension",
                    "extension_basic"
                  )}
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Add Extension</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Fill the information below.
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <form
                id="extension_basic"
                className="parsley-style-1"
                data-parsley-validate
                noValidate
                onSubmit={onAddExtensionSubmit.bind()}
              >
                <div className="row row-sm">
                  <div
                    id="nameWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Name<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="extension_name"
                      className="form-control"
                      placeholder="Enter name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#nameWrapper"
                      required
                    />
                  </div>

                  <div
                    id="descriptionWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                      Description
                    </label>
                    <textarea
                      id="extension_description"
                      type="text"
                      rows="5"
                      className="form-control"
                      placeholder="Provide a description"
                      data-parsley-class-handler="#descriptionWrapper"
                      required
                    ></textarea>
                  </div>
                </div>
                {loading ? (
                  <button disabled className="btn btn-brand-02 btn-block">
                    <span
                      className="spinner-border spinner-border-sm mg-r-10"
                      role="status"
                      aria-hidden="true"
                    />
                    Please Wait
                  </button>
                ) : (
                  <button className="btn btn-brand-02 btn-block mt-2">
                    <i className="ion-md-add" /> Add Extension
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalExtensionInfo"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href=""
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Excess</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Extension full information.
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <div className="row row-sm">
                <div className="col-md-12">
                  <p>
                    <strong>Name:</strong>
                    <br />
                    <span className="tx_name">Blank</span>
                  </p>
                  <p>
                    <strong>Description:</strong>
                    <br />
                    <span className="tx_description">Blank</span>
                  </p>
                  <button
                    className="btn btn-brand-01 mt-4"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalInsurance"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href="javascript:void(0)"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  onClick={onCloseModal.bind(
                    null,
                    "modalInsurance",
                    "insuranceForm"
                  )}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Insurance Form</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Fill all the information below.
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <form
                id="insuranceForm"
                className="row parsley-style-1"
                onSubmit={handleInsuranceSubmit.bind()}
              >
                <div
                  id="companyNameWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>
                    Company Name<span className="tx-danger">*</span>
                  </label>
                  <input
                    id="_companyName"
                    className="form-control"
                    placeholder="Enter company name"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#companyNameWrapper"
                    required
                  />
                </div>

                <div
                  id="companyEmailWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>Company Email</label>
                  <input
                    id="email"
                    className="form-control"
                    placeholder="Enter company email"
                    type="email"
                    autoComplete="off"
                    data-parsley-class-handler="#companyEmailWrapper"
                    required
                  />
                </div>

                <div
                  id="companyAddressWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>Company Address</label>
                  <input
                    id="address"
                    className="form-control"
                    placeholder="Enter company address"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#companyAddressWrapper"
                    required
                  />
                </div>

                <div
                  id="registrationNumberWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Registration Number<span className="tx-danger">*</span>
                  </label>
                  <input
                    id="regNumber"
                    className="form-control"
                    placeholder="Enter last name"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#registrationNumberWrapper"
                    required
                  />
                </div>

                <div
                  id="tpinWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    TPIN<span className="tx-danger">*</span>
                  </label>
                  <input
                    id="companyTpin"
                    className="form-control"
                    placeholder="Enter tpin number"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#tpinWrapper"
                    required
                  />
                </div>

                <div
                  id="logoWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>Upload Logo</label>
                  <input
                    id="logo"
                    className="form-control"
                    placeholder="Select"
                    type="file"
                    autoComplete="off"
                    data-parsley-class-handler="#logoWrapper"
                  />
                </div>

                <hr />

                <div className="col-md-12 mg-t-30">
                  <h5>Bank Account</h5>
                </div>

                <div
                  id="accountTypeWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>
                    Account Type<span className="tx-danger">*</span>
                  </label>
                  <select className="custom-select" id="accountType" required>
                    <option value="" disabled selected>
                      Select
                    </option>
                    {accountType.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  id="bankNameWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Bank Name<span className="tx-danger">*</span>
                  </label>
                  <select
                    className="custom-select"
                    id="bankName"
                    onChange={onBankChange.bind()}
                    required
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    {bank.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  id="bankBranchWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Bank Branch<span className="tx-danger">*</span>
                  </label>
                  <select
                    className="custom-select"
                    id="bankBranch"
                    onChange={onBranchChange.bind()}
                    required
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    {branch.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  id="accountNameWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>
                    Account Name<span className="tx-danger">*</span>
                  </label>
                  <input
                    id="_accountName"
                    className="form-control"
                    placeholder="Enter account name"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#accountNameWrapper"
                    required
                  />
                </div>

                <div
                  id="accountNumberWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>
                    Account Number<span className="tx-danger">*</span>
                  </label>
                  <input
                    id="_accountNumber"
                    className="form-control"
                    placeholder="Enter account number"
                    type="number"
                    autoComplete="off"
                    data-parsley-class-handler="#accountNumberWrapper"
                    required
                  />
                </div>

                <div className="col-md-12">
                  {loading ? (
                    <button disabled className="btn btn-brand-02 float-right">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button className="btn btn-brand-01 btnNextFront btn-block">
                      Add Insurance
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalRate"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href="javascript:void(0)"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  onClick={onCloseModal.bind(null, "modalRate", "basicRate")}
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Premium Rate</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Fill the information below.
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <form
                id="basicRate"
                className="parsley-style-1"
                onSubmit={onPremiumRateSubmit.bind()}
              >
                <div className="row row-sm">
                  <div
                    id="riskWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Product<span className="tx-danger">*</span>
                    </label>
                    <select
                      className="custom-select"
                      id="rate_product"
                      required
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      {product.product.map((data) => (
                        <option value={data.id}>{data.name}</option>
                      ))}
                    </select>
                  </div>

                  <div
                    id="nameWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Name<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="rate_name"
                      className="form-control"
                      placeholder="Enter name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#nameWrapper"
                      required
                    />
                  </div>

                  <div
                    id="descriptionWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Description<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="rate_description"
                      className="form-control"
                      placeholder="Enter description"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#descriptionWrapper"
                      required
                    />
                  </div>

                  <div
                    id="quarterWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Quarter<span className="tx-danger">*</span>
                    </label>
                    <select
                      className="custom-select"
                      id="rate_quarter"
                      required
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>

                  <div
                    id="premiumWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Premium<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="rate_premium"
                      className="form-control"
                      placeholder="Enter premium"
                      type="number"
                      autoComplete="off"
                      data-parsley-class-handler="#premiumWrapper"
                      required
                    />
                  </div>
                </div>
                {loading ? (
                  <button disabled className="btn btn-brand-02 btn-block">
                    <span
                      className="spinner-border spinner-border-sm mg-r-10"
                      role="status"
                      aria-hidden="true"
                    />
                    Please Wait
                  </button>
                ) : (
                  <button className="btn btn-brand-02 btn-block mt-2">
                    <i className="ion-md-add" /> {edit ? "Update" : "Add"}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalViewRate"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href=""
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2 name">Not Set</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Product Code: <span className="code">Not Set</span>
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <div className="row row-sm">
                <div className="col-md-12">
                  <p>
                    <strong>Name:</strong> <span className="name">Not Set</span>
                  </p>
                  <p>
                    <strong>Code:</strong> <span className="code">Not Set</span>
                  </p>
                  <p>
                    <strong>Description:</strong>{" "}
                    <span id="description">Not Set</span>
                  </p>
                  <p>
                    <strong>Policy Number Prefix:</strong>{" "}
                    <span id="policyNumberPrefix">Not Set</span>
                  </p>
                  <p>
                    <strong>claim Number Prefix:</strong>{" "}
                    <span id="claimNumberPrefix">Not Set</span>
                  </p>
                </div>

                <div className="col-md-12">
                  <div className="card ht-100p">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <h6 className="mg-b-0">Premium Rate List</h6>
                    </div>
                    <ul className="list-group list-group-flush tx-13"></ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceDetails;
