import React, { useEffect, useState } from "react";
import { GET_ALL_AGENTS_URL, SEARCH_AGENT_URL } from "api";
import "datatables.net";
import "datatables.net-responsive";

import $ from "jquery";
import { createAgent, deleteAgent, updateAgent } from "../../../request";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";

const Agent = (props) => {
  document.title = "Agents";

  const [loading, setLoading] = useState(false);
  const [editAgent, setEditAgent] = useState(false);
  const [agentId, setAgentId] = useState(null);
  const auth = useSelector((state) => state.auth.user);
  useEffect(() => {
    let table = $("#agents").DataTable({
      responsive: true,
      language: {
        processing:
          '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Agent Data</p>',
      },
      searching: true,
      lengthMenu: [[10], [10]],
      ordering: false,
      info: true,
      bFilter: false,
      processing: true,
      pageLength: 10,
      serverSide: true,
      ajax: function (data, callback) {
        // make a regular ajax request using data.start and data.length
        $.ajax(
          $(".dataTables_filter input").val().length > 0
            ? SEARCH_AGENT_URL($(".dataTables_filter input").val())
            : GET_ALL_AGENTS_URL,
          {
            type: "GET",
            data: {
              pageNumber: (data.length + data.start) / data.length,
              pageSize: data.length,
            },
            success: function (res) {
              let result = [];

              res.data.map((data) => {
                result.push({
                  raw: data,
                });
              });

              callback({
                recordsTotal: res.totalRecords,
                recordsFiltered: res.totalRecords,
                data: result,
              });
            },
          }
        );
      },
      columns: [
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
      ],
      columnDefs: [
        {
          render: function (data) {
            return `${data.firstName} ${data.lastName}`;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data.gender;
          },
          targets: 1,
        },
        {
          render: function (data) {
            return data.phoneNumber;
          },
          targets: 2,
        },
        {
          render: function (data) {
            return data.email;
          },
          targets: 3,
        },
        {
          render: function () {
            return `<nav class="nav nav-icon-only">
                                    <a href="#" class="nav-link edit_agent"><i class="fa fa-pencil"></i> Edit</a><a href="#" class="nav-link view_agent"><i class="fa fa-eye"></i> View</a><a href="#" class="nav-link delete_agent"><i class="fa fa-trash"></i> Delete</a>
                                </nav>`;
          },
          targets: 4,
        },
      ],
    });

    $("#agents").on("click", ".edit_agent", function () {
      $("#modalAgent").modal({ backdrop: "static", keyboard: false }, "show");

      $("#agentForm").parsley();

      setEditAgent(true);

      let extract_td = table.row($(this).closest("tr")).data();

      setAgentId(extract_td.raw.id);
      $("#firstName").val(extract_td.raw.firstName);
      $("#lastName").val(extract_td.raw.lastName);
      $("#email").val(extract_td.raw.email);
      $("#phoneNumber").val(extract_td.raw.phoneNumber);
      $("#gender").val(extract_td.raw.gender);
      $("#nrc").val(extract_td.raw.idNumber);
      $("#address").val(extract_td.raw.physicalAddress);
      $("#password").prop("disabled", true).prop("required", false);

      return false;
    });

    $("#agents").on("click", ".view_agent", function () {
      let extract_td = table.row($(this).closest("tr")).data();
      props.history.push(`/agent-details/${extract_td.raw.id}`);

      return false;
    });

    $("#agents").on("click", ".delete_agent", function () {
      let extract_td = table.row($(this).closest("tr")).data();

      let { hide } = cogoToast.loading("Please wait... contacting to server.", {
        position: "top-right",
        hideAfter: 3,
      });

      deleteAgent(extract_td.raw.id).then((result) => {
        hide();

        if (!result.error) {
          $("#agents").DataTable().ajax.reload();
          return cogoToast.success("Agent successfully removed", {
            position: "top-right",
            hideAfter: 3,
          });
        }

        cogoToast.error("Failed to delete agent", {
          position: "top-right",
          hideAfter: 3,
        });
      });

      return false;
    });
  }, []);

  const handleShowModal = () => {
    $("#agentForm").parsley();
    $("#password").prop("disabled", false).prop("required", true);
    setEditAgent(false);
    $("#modalAgent").modal({ backdrop: "static", keyboard: false }, "show");
  };

  const onSubmitNewAgent = (e) => {
    e.preventDefault();

    let raw = {
      firstName: $("#firstName").val(),
      lastName: $("#lastName").val(),
      email: $("#email").val(),
      companyId: auth.user.id,
      phoneNumber: $("#phoneNumber").val(),
      gender: $("#gender").val(),
      idType: "NRC",
      idNumber: $("#nrc").val(),
      password: $("#password").val(),
      physicalAddress: $("#address").val(),
      commissionRate: Number($("#commissionRate").val()),
    };

    setLoading(true);

    if (editAgent) {
      updateAgent(raw, agentId).then((res) => {
        const options = {
          position: "top-right",
        };

        setLoading(false);

        if (res.error) return cogoToast.error("Something went wrong.", options);

        onCloseModal("modalAgent", "agentForm");
        $("#agents").DataTable().ajax.reload();

        cogoToast.success("Agent successfully Updated.", options);
      });

      return false;
    }

    createAgent(raw).then((res) => {
      const options = {
        position: "top-right",
      };

      setLoading(false);

      if (res.error) {
        return cogoToast.error(res.payload, options);
      }

      onCloseModal("modalAgent", "agentForm");
      $("#agents").DataTable().ajax.reload();

      cogoToast.success("Agent successfully Added.", options);
    });
  };

  const onCloseModal = (id, form) => {
    let parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    $(`#${id}`).modal("hide");
    parsley.reset();
    parsley.destroy();
    $(".modal-backdrop").remove();
    $("#nav_basic a:first").tab("show");
  };

  return (
    <div className="content-body">
      <div className="container pd-x-0">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                <li className="breadcrumb-item">
                  <a href="#">Menu</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Agents
                </li>
              </ol>
            </nav>
            <h4 className="mg-b-0 tx-spacing--1">Agents</h4>
          </div>
          <div className="d-none d-md-block">
            <button
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
              onClick={handleShowModal.bind()}
            >
              <i className="wd-10 mg-r-5 fa fa-plus"></i> Add New
            </button>
          </div>
        </div>

        <div className="row row-xs">
          <div className="col-md-12 mg-t-10">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="mg-b-0">Agents</h6>
                <div className="d-flex tx-18">
                  <a
                    href="javascript:void(0)"
                    onClick={() => $("#agents").DataTable().ajax.reload()}
                    className="link-03 lh-0"
                  >
                    <i className="icon ion-md-refresh"></i>
                  </a>
                </div>
              </div>
              <div className="card-body table-responsive">
                <table id="agents" className="table table-hover">
                  <thead>
                    <tr>
                      <th className="wd-20p">Names</th>
                      <th className="wd-20p">Gender</th>
                      <th className="wd-20p">Phone Number</th>
                      <th className="wd-20p">Email Address</th>
                      <th className="wd-20p">Date Created</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalAgent"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href=""
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  onClick={onCloseModal.bind(null, "modalAgent", "agentForm")}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">
                  {editAgent ? "Edit" : "Add"} Agent
                </h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  {editAgent ? "Edit" : "Add"} gari agent
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <form
                id="agentForm"
                className="parsley-style-1"
                onSubmit={onSubmitNewAgent.bind()}
              >
                <div className="row row-sm">
                  <div
                    id="firstNameWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      First Name<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="firstName"
                      className="form-control"
                      placeholder="Enter first name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#firstNameWrapper"
                      required
                    />
                  </div>

                  <div
                    id="lastNameWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Last Name<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="lastName"
                      className="form-control"
                      placeholder="Enter last name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#lastNameWrapper"
                      required
                    />
                  </div>

                  <div
                    id="genderWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Gender<span className="tx-danger">*</span>
                    </label>
                    <select className="custom-select" id="gender" required>
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>

                  <div
                    id="emailWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Email Address<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="email"
                      className="form-control"
                      placeholder="Enter email address"
                      type="email"
                      autoComplete="off"
                      data-parsley-class-handler="#emailWrapper"
                      required
                    />
                  </div>

                  <div
                    id="phoneWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Phone Number<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="phoneNumber"
                      className="form-control"
                      placeholder="Enter phone number"
                      type="number"
                      autoComplete="off"
                      data-parsley-class-handler="#phoneWrapper"
                      required
                    />
                  </div>

                  <div
                    id="nrcWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      ID Number<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="nrc"
                      className="form-control"
                      placeholder="Enter id number"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#nrcWrapper"
                      required
                    />
                  </div>

                  <div
                    id="passwordWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Password<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="password"
                      className="form-control"
                      placeholder="Create password"
                      type="password"
                      autoComplete="off"
                      data-parsley-class-handler="#passwordWrapper"
                      required
                    />
                  </div>

                  <div
                    id="addressWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Physical Address<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="address"
                      className="form-control"
                      placeholder="Enter physical address"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#addressWrapper"
                      required
                    />
                  </div>
                  <div
                    id="commissionRateWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Commision Rate<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="commissionRate"
                      className="form-control"
                      placeholder="Enter Commision Rate"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#lastNameWracommissionRateWrapperpper"
                      required
                    />
                  </div>
                </div>
                <hr />
                {loading ? (
                  <button disabled className="btn btn-brand-02 btn-block">
                    <span
                      className="spinner-border spinner-border-sm mg-r-10"
                      role="status"
                      aria-hidden="true"
                    />
                    Please Wait
                  </button>
                ) : (
                  <button className="btn btn-brand-01 btnNextFront btn-block">
                    {editAgent ? "Update" : "Create"}{" "}
                    <i className="ion-md-arrow-forward" />
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agent;
