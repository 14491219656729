import React, { useEffect, useState } from "react";
import {
  GET_CLIENT_RECEIPT_URL,
  GET_CLIENT_TRANSACTION_URL,
  GET_CLIENT_VEHICLE_URL,
  GET_CLIENT_ALL_POLICIES_URL,
  GET_CLIENT_QUOTATION_URL,
  GET_CLIENT_CLAIM_URL,
  CLIENTS_ENGAGEMENT_URL,
} from "../../../api";
import {
  getClientDetail,
  updateUser,
  getOrganization,
  addEngagement,
  addVehicle,
  uploadClientDocument,
  getVehicleInfo,
  deleteVehicle,
  createQuotation,
  updateVehicle,
  getClientDocument,
} from "../../../request";
import $ from "jquery";
import "components-jqueryui";
import "parsleyjs";
import "select2";
import "datatables.net";
import "datatables.net-responsive";
import moment from "moment";
import formatNumber from "../../../utils/formatNumber";
import cogoToast from "cogo-toast";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "../../../utils/capitalize";
import { encodeQueryParameter } from "../../../utils/url";

const ClientDetails = (props) => {
  document.title = "Client Details";

  const dispatch = useDispatch();

  const { id } = useSelector((state) => state.auth.user.user);

  const { products } = useSelector((state) => state.organization.products);

  const [loading, setLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [labelIdType, setLabelIdType] = useState("NRC");
  const [createLoading, setCreateLoading] = useState(false);
  const [type, setType] = useState("Amount");
  const [accountType, setAccountType] = useState("Individual");
  const [vehicleData, setVehicleData] = useState({});
  const [create, setCreate] = useState(true);
  //   const [createEngagement, setCreateEngagement] = useState(true);
  const { bodyType } = useSelector((state) => state.organization.bodyType);
  const { riskCategory } = useSelector(
    (state) => state.organization.riskCategory
  );

  $(function () {
    $("#Date_from").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: "-120Y",
      maxDate: "-18Y",
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });

  $(function () {
    $("#date_from").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });

  useEffect(() => {
    getClientDetail(props.match.params.id).then(async (response) => {
      // dispatch(getOrganization(id));
      if (!response.error) {
        setLoading(false);
        setLabelIdType(response.payload.idType);
        $("#type").val(response.payload.clientType);
        $("#accountType").text(response.payload.clientType);
        $("#name").text(
          `${response.payload.firstName} ${
            response.payload.middleName ? response.payload.middleName : ""
          } ${response.payload.lastName}`
        );
        $("#fullName").text(
          `${response.payload.firstName} ${
            response.payload.middleName ? response.payload.middleName : ""
          } ${response.payload.lastName}`
        );
        $("#gender").text(response.payload.gender);
        $("#mobile").text(response.payload.phoneNumber);
        $("#occupation").text(response.payload.occupation);
        $("#dob").text(
          moment(response.payload.dateOfBirth).format("Do MMM, YYYY")
        );
        $("#email").text(response.payload.email);
        $("#idType").text(`${response.payload.idType}:`);
        $("#idNumber").text(response.payload.idNumber || "Blank");
        // $("#idNumber").text(response.payload.idNumber || "Blank").attr("href", await clientDocument() || "").attr('target','_blank');
        $("#address").text(response.payload.physicalAddress || "Blank");
        $("#accountCreated").text(
          moment(response.payload.createdAt).format("Do MMM, YYYY - HH:mm")
        );

        $("#FirstName").val(response.payload.firstName);
        $("#MiddleName").val(response.payload.middleName || "");
        $("#LastName").val(response.payload.lastName);
        $("#Date_from").val(
          moment(response.payload.dateOfBirth).format("Do MMM, YYYY")
        );
        $("#Gender").val(response.payload.gender);
        $("#IdType").val(response.payload.idType);
        $("#IdTypeValue").val(response.payload.idNumber);
        $("#Occupation").val(response.payload.occupation);
        $("#Address").val(response.payload.physicalAddress);
        $("#PhoneNumber").val(response.payload.phoneNumber);
        $("#EmailAddress").val(response.payload.email);

        let policy_table = $("#policy_table").DataTable({
          responsive: true,
          destroy: true,
          language: {
            processing:
              '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Policies Data</p>',
          },
          searching: false,
          lengthMenu: [[10], [10]],
          ordering: false,
          info: true,
          bFilter: false,
          processing: true,
          pageLength: 10,
          serverSide: true,
          ajax: function (data, callback) {
            // make a regular ajax request using data.start and data.length
            $.ajax(`${GET_CLIENT_ALL_POLICIES_URL(props.match.params.id)}`, {
              type: "GET",
              data: {
                pageNumber: (data.length + data.start) / data.length,
                pageSize: data.length,
              },
              success: function (res) {
                let result = [];

                $("#total_policies").text(formatNumber(res.totalRecords));

                res.data.map((data) => {
                  result.push({
                    raw: data,
                  });
                });

                callback({
                  recordsTotal: res.totalRecords,
                  recordsFiltered: res.totalRecords,
                  data: result,
                });
              },
            });
          },
          columns: [
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
          ],
          columnDefs: [
            {
              render: function (data) {
                return data.policyNumber;
              },
              targets: 0,
            },
            {
              render: function (data) {
                return data.sourceOfBusiness;
              },
              targets: 1,
            },
            {
              render: function (data) {
                return moment(data.startDate)
                  .add(1, "days")
                  .format("Do MMM, YYYY");
              },
              targets: 2,
            },
            {
              render: function (data) {
                return moment(data.endDate)
                  .add(1, "days")
                  .format("Do MMM, YYYY");
              },
              targets: 3,
            },
            {
              render: function (data) {
                return moment(data.startDate).format("YYYY");
              },
              targets: 4,
            },
            {
              render: function (data) {
                return {
                  Active: `<span class="badge badge-success tx-white">${data.status}</span>`,
                  Expired: `<span class="badge badge-warning tx-white">${data.status}</span>`,
                  Cancelled: `<span class="badge badge-danger">${data.status}</span>`,
                  InClaim: `<span class="badge badge-warning">${data.status}</span>`,
                  InReview: `<span class="badge badge-info">${data.status}</span>`,
                }[data.status];
              },
              targets: 5,
            },
          ],
        });

        $("#policy_table tbody").on("click", "tr", function () {
          var extract_data = policy_table.row(this).data();
          props.history.push(
            `/policy-details/${
              extract_data.raw.clientId
            }/${encodeQueryParameter(extract_data.raw.id)}`
          );
        });

        let quotation_table = $("#quotation_table").DataTable({
          responsive: true,
          destroy: true,
          language: {
            processing:
              '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Quotation Data</p>',
          },
          searching: false,
          lengthMenu: [[10], [10]],
          ordering: false,
          info: true,
          bFilter: false,
          processing: true,
          pageLength: 10,
          serverSide: true,
          ajax: function (data, callback) {
            // make a regular ajax request using data.start and data.length
            $.ajax(`${GET_CLIENT_QUOTATION_URL(props.match.params.id)}`, {
              type: "GET",
              data: {
                pageNumber: (data.length + data.start) / data.length,
                pageSize: data.length,
              },
              success: function (res) {
                let result = [];

                $("#total_quotations").text(formatNumber(res.totalRecords));

                res.data.map((data) => {
                  result.push({
                    raw: data,
                  });
                });

                callback({
                  recordsTotal: res.totalRecords,
                  recordsFiltered: res.totalRecords,
                  data: result,
                });
              },
            });
          },
          columns: [
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
          ],
          columnDefs: [
            {
              render: function (data) {
                return data.quotationNumber;
              },
              targets: 0,
            },
            {
              render: function (data) {
                return data.risks.length;
              },
              targets: 1,
            },
            {
              render: function (data) {
                return moment(data.startDate).format("Do MMM, YYYY");
              },
              targets: 2,
            },
            {
              render: function (data) {
                return moment(data.endDate).format("Do MMM, YYYY");
              },
              targets: 3,
            },
            {
              render: function (data) {
                return moment(data.expiryDate).format("Do MMM, YYYY");
              },
              targets: 4,
            },
            {
              render: function (data) {
                return moment(data.startDate).format("YYYY");
              },
              targets: 5,
            },
            {
              render: function (data) {
                return {
                  Approved: `<span class="badge badge-success tx-white">${data.status}</span>`,
                  Draft: `<span class="badge badge-secondary tx-white">${data.status}</span>`,
                  Expired: `<span class="badge badge-danger tx-white">${data.status}</span>`,
                }[data.status];
              },
              targets: 6,
            },
          ],
        });

        $("#quotation_table tbody").on("click", "tr", function () {
          var extract_data = quotation_table.row(this).data();
          props.history.push(`/quotation-details/${extract_data.raw.id}`);
        });

        var vehicle_table = $("#vehicle_table").DataTable({
          responsive: true,
          destroy: true,
          language: {
            processing:
              '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Vehicle Data</p>',
          },
          searching: false,
          lengthMenu: [[10], [10]],
          ordering: false,
          info: true,
          bFilter: true,
          processing: true,
          pageLength: 10,
          serverSide: true,
          ajax: function (data, callback) {
            // make a regular ajax request using data.start and data.length
            $.ajax(`${GET_CLIENT_VEHICLE_URL(props.match.params.id)}`, {
              type: "GET",
              data: {
                pageNumber: (data.length + data.start) / data.length,
                pageSize: data.length,
              },
              success: function (res) {
                let result = [];

                $("#total_vehicles").text(formatNumber(res.totalRecords));

                res.data.map((data) => {
                  result.push({
                    raw: data,
                  });
                });

                callback({
                  recordsTotal: res.totalRecords,
                  recordsFiltered: res.totalRecords,
                  data: result,
                });
              },
            });
          },
          columns: [
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
          ],
          columnDefs: [
            {
              render: function (data) {
                return data.regNumber;
              },
              targets: 0,
            },
            {
              render: function (data) {
                return data.make;
              },
              targets: 1,
            },
            {
              render: function (data) {
                return data.model;
              },
              targets: 2,
            },
            {
              render: function (data) {
                return data.engineNumber;
              },
              targets: 3,
            },
            {
              render: function (data) {
                return data.category == null ? "Not Set" : data.category.name;
              },
              targets: 4,
            },
            {
              render: function (data) {
                return {
                  HasPolicy: `<span class="badge badge-success tx-white">${data.status}</span>`,
                  InClaim: `<span class="badge badge-warning">${data.status}</span>`,
                  InReview: `<span class="badge badge-info">${data.status}</span>`,
                  NoPolicy: `<span class="badge badge-danger tx-white">${data.status}</span>`,
                }[data.status];
              },
              targets: 5,
            },
          ],
        });

        var claim_table = $("#claim_table").DataTable({
          responsive: true,
          destroy: true,
          language: {
            processing:
              '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Claim Data</p>',
          },
          searching: false,
          lengthMenu: [[10], [10]],
          ordering: false,
          info: true,
          bFilter: true,
          processing: true,
          pageLength: 10,
          serverSide: true,
          ajax: function (data, callback) {
            // make a regular ajax request using data.start and data.length
            $.ajax(`${GET_CLIENT_CLAIM_URL(props.match.params.id)}`, {
              type: "GET",
              data: {
                pageNumber: (data.length + data.start) / data.length,
                pageSize: data.length,
              },
              success: function (res) {
                let result = [];

                $("#total_claims").text(formatNumber(res.totalRecords));

                res.data.map((data) => {
                  result.push({
                    raw: data,
                  });
                });

                callback({
                  recordsTotal: res.totalRecords,
                  recordsFiltered: res.totalRecords,
                  data: result,
                });
              },
            });
          },
          columns: [
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
          ],
          columnDefs: [
            {
              render: function (data) {
                return data.claimNumber;
              },
              targets: 0,
            },
            {
              render: function (data) {
                return data.claimType;
              },
              targets: 1,
            },
            {
              render: function (data) {
                return data.claimStage;
              },
              targets: 2,
            },
            {
              render: function (data) {
                return `K${data.lossEstimate}`;
              },
              targets: 3,
            },
            {
              render: function (data) {
                return moment(data.lossDate).format("Do MMM, YYYY");
              },
              targets: 4,
            },
            {
              render: function (data) {
                return {
                  Approved: `<span class="badge badge-success tx-white">${data.claimStatus}</span>`,
                  Resolved: `<span class="badge badge-primary tx-white">${data.claimStatus}</span>`,
                  Pending: `<span class="badge badge-info">${data.claimStatus}</span>`,
                  Cancelled: `<span class="badge badge-warning">${data.claimStatus}</span>`,
                  Rejected: `<span class="badge badge-danger tx-white">${data.claimStatus}</span>`,
                }[data.claimStatus];
              },
              targets: 5,
            },
          ],
        });

        $("#vehicle_table tbody").on("click", "tr", function () {
          var extract_data = vehicle_table.row(this).data();

          if (extract_data === undefined) return null;

          $("#regNumber").text(extract_data.raw.regNumber);
          $("#make").text(extract_data.raw.make);
          $("#model").text(extract_data.raw.model);
          $("#engineNumber").text(extract_data.raw.engineNumber);
          $("#chassisNumber").text(extract_data.raw.chassisNumber);
          $("#cubicCapacity").text(extract_data.raw.cubicCapacity);
          $("#vehicleStatus").text(extract_data.raw.status);
          $("#yearOfManufacture").text(extract_data.raw.yearOfManufacture);
          extract_data.raw.whiteBookUrl
            ? $("#whitebook")
                .text("View Whitebook")
                .attr("href", extract_data.raw.whiteBookUrl || "")
                .attr("target", "_blank")
            : $("#whitebook")
                .text("No Document available")
                .attr("href", "javascript:void(0)");
          extract_data.raw.licenseUrl
            ? $("#license")
                .text("View License")
                .attr("href", extract_data.raw.licenseUrl || "")
                .attr("target", "_blank")
            : $("#license")
                .text("No Document available")
                .attr("href", "javascript:void(0)");
          $("#color").text(extract_data.raw.color);
          $("#category").text(extract_data.raw.category.name || "Not Set");
          $("#bodyType").text(extract_data.raw.bodyType.name || "Not Set");
          $("#createdAt").text(
            moment(extract_data.raw.createdAt).format("Do MMM, YYYY")
          );
          $("#updatedAt").text(
            moment(extract_data.raw.updatedAt).format("Do MMM, YYYY")
          );
          setVehicleData(extract_data.raw);
          $("#modalVehicle").modal("show");
        });

        $("#transaction_table").DataTable({
          responsive: true,
          destroy: true,
          language: {
            processing:
              '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Transaction Data</p>',
          },
          searching: false,
          lengthMenu: [[10], [10]],
          ordering: false,
          info: true,
          bFilter: false,
          processing: true,
          pageLength: 10,
          serverSide: true,
          ajax: function (data, callback) {
            // make a regular ajax request using data.start and data.length
            $.ajax(`${GET_CLIENT_TRANSACTION_URL(props.match.params.id)}`, {
              type: "GET",
              data: {
                pageNumber: (data.length + data.start) / data.length,
                pageSize: data.length,
              },
              success: function (res) {
                let result = [];

                $("#total_transactions").text(formatNumber(res.totalRecords));

                res.data.map((data) => {
                  result.push({
                    raw: data,
                  });
                });

                callback({
                  recordsTotal: res.totalRecords,
                  recordsFiltered: res.totalRecords,
                  data: result,
                });
              },
            });
          },
          columns: [
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
          ],
          columnDefs: [
            {
              render: function (data) {
                return `ZMW${formatNumber(Math.round(data.amount))}`;
              },
              targets: 0,
            },
            {
              render: function (data) {
                return data.paymentMethod;
              },
              targets: 1,
            },
            {
              render: function (data) {
                return moment(data.createdAt).format("Do MMM, YYYY");
              },
              targets: 2,
            },
            {
              render: function (data) {
                return {
                  Success: `<span class="badge badge-success tx-white">${data.status}</span>`,
                  Failed: `<span class="badge badge-danger tx-white">${data.status}</span>`,
                  Pending: `<span class="badge badge-secondary tx-white">${data.status}</span>`,
                  Cancelled: `<span class="badge badge-danger tx-white">${data.status}</span>`,
                }[data.status];
              },
              targets: 3,
            },
          ],
        });

        var receipt_table = $("#receipt_table").DataTable({
          responsive: true,
          destroy: true,
          language: {
            processing:
              '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Receipt Data</p>',
          },
          searching: false,
          lengthMenu: [[10], [10]],
          ordering: false,
          info: true,
          bFilter: false,
          processing: true,
          pageLength: 10,
          serverSide: true,
          ajax: function (data, callback) {
            // make a regular ajax request using data.start and data.length
            $.ajax(`${GET_CLIENT_RECEIPT_URL(props.match.params.id)}`, {
              type: "GET",
              data: {
                pageNumber: (data.length + data.start) / data.length,
                pageSize: data.length,
              },
              success: function (res) {
                let result = [];

                $("#total_receipts").text(formatNumber(res.totalRecords));

                // eslint-disable-next-line array-callback-return
                res.data.map((data) => {
                  result.push({
                    raw: data,
                  });
                });

                callback({
                  recordsTotal: res.totalRecords,
                  recordsFiltered: res.totalRecords,
                  data: result,
                });
              },
            });
          },
          columns: [
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
            { data: "raw" },
          ],
          columnDefs: [
            {
              render: function (data) {
                return data.quotation.quotationNumber;
              },
              targets: 0,
            },
            {
              render: function (data) {
                return `ZMW${formatNumber(Math.round(data.amount))}`;
              },
              targets: 1,
            },
            {
              render: function (data) {
                return data.paymentMethod;
              },
              targets: 2,
            },
            {
              render: function (data) {
                return data.accountNumber;
              },
              targets: 3,
            },
            {
              render: function (data) {
                return moment(data.createdAt).format("Do MMM, YYYY");
              },
              targets: 4,
            },
          ],
        });
        $("#receipt_table tbody").on("click", "tr", function () {
          var extract_data = receipt_table.row(this).data();

          if (extract_data === undefined) return null;

          $("#transactDate").text(
            moment(extract_data.raw.createdAt).format("Do MMM, YYYY")
          );
          $("#receiptNumber").text(extract_data.raw.receiptNumber);
          $("#receiptAmount").text(extract_data.raw.amount);
          $("#receiptPayment").text(extract_data.raw.paymentMethod);
          $("#receiptNarration").text(extract_data.raw.narration);
          $("#receiptRemarks").text(extract_data.raw.remarks);
          $("#receiptCaptureBy").text(extract_data.raw.capturedBy);
          $("#receiptSourceOfBusiness").text(extract_data.raw.sourceOfBusiness);
          $("#receiptAccountNumber").text(extract_data.raw.accountNumber);
          $("#modalReceipt").modal("show");
        });
        var engagements_table = $("#engagements_table").DataTable({
          responsive: true,
          destroy: true,
          language: {
            processing:
              '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Receipt Data</p>',
          },
          searching: false,
          lengthMenu: [[10], [10]],
          ordering: false,
          info: true,
          bFilter: false,
          processing: true,
          pageLength: 10,
          serverSide: true,
          ajax: function (data, callback) {
            // make a regular ajax request using data.start and data.length
            $.ajax(`${CLIENTS_ENGAGEMENT_URL(props.match.params.id)}`, {
              type: "GET",
              data: {
                pageNumber: (data.length + data.start) / data.length,
                pageSize: data.length,
              },
              success: function (res) {
                let result = [];

                $("#total_engagements").text(formatNumber(res.totalRecords));

                // eslint-disable-next-line array-callback-return
                res.data.map((data) => {
                  result.push({
                    raw: data,
                  });
                });

                callback({
                  recordsTotal: res.totalRecords,
                  recordsFiltered: res.totalRecords,
                  data: result,
                });
              },
            });
          },
          columns: [{ data: "raw" }, { data: "raw" }, { data: "raw" }],
          columnDefs: [
            {
              render: function (data) {
                return data.engagementType;
              },
              targets: 0,
            },
            {
              render: function (data) {
                return data.engagementDescription;
              },
              targets: 1,
            },

            {
              render: function (data) {
                return moment(data.engagementDate).format("Do MMM, YYYY");
              },
              targets: 2,
            },
          ],
        });
      }
    });
  }, []);

  const clientDocument = () => {
    return getClientDocument(props.match.params.id).then((response) => {
      let result = response.payload.data.find((data) => {
        return data.documentType == "Passport" || "NRC";
      });

      return result !== null ? result.documentUrl : null;
    });
  };

  const onAddVehicleSubmit = async (e) => {
    e.preventDefault();
    setVehicleLoading(true);

    let raw = {
      clientId: props.match.params.id,
      riskCategoryId: $("#RiskCategory").val(),
      make: $("#Make").val(),
      model: $("#Model").val(),
      regNumber: $("#RegNumber").val(),
      engineNumber: $("#EngineNumber").val(),
      chassisNumber: $("#ChassisNumber").val(),
      cubicCapacity: $("#CubicCapacity").val(),
      yearOfManufacture: $("#YearOfManufacture").val(),
      whiteBookUrl: !create
        ? vehicleData.whiteBookUrl
        : await uploadDocument("Whitebook"),
      licenseUrl: !create
        ? vehicleData.licenseUrl
        : await uploadDocument("DriversLicense"),
      color: $("#Color").val(),
      bodyTypeId: $("#BodyType").val(),
    };

    if (!create) {
      updateVehicle(raw, vehicleData.id).then((res) => {
        const options = {
          position: "top-right",
          hideAfter: 2,
        };

        setVehicleLoading(false);

        if (res.error)
          return cogoToast.error("Something went wrong. 1", options);

        onCloseModal("modalAddVehicle", "basicVehicle");
        $("#vehicle_table").DataTable().ajax.reload();

        cogoToast.success("Vehicle successfully Updated.", options);
      });

      return false;
    }

    addVehicle(raw).then((res) => {
      const options = {
        position: "top-right",
        hideAfter: 2,
      };

      setVehicleLoading(false);

      if (res.error) return cogoToast.error("Something went wrong.", options);

      onCloseModal("modalAddVehicle", "basicVehicle");
      $("#vehicle_table").DataTable().ajax.reload();

      cogoToast.success("Vehicle successfully Added.", options);
    });
  };

  const uploadDocument = (id) => {
    let [files] = $(`#${id}`).prop("files");

    if (files === undefined) return null;

    const formData = new FormData();

    formData.append("ClientId", props.match.params.id);
    formData.append("DocumentType", `${id}`);
    formData.append("Description", `${id}`);
    formData.append("File", files);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      encType: "multipart/form-data",
      onUploadProgress: (progress) => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
        // uploadProgress = FileLoaded * uploadPercentage;
      },
    };

    return uploadClientDocument(formData, config).then((result) => {
      if (!result.error) {
        return result.payload.documentUrl;
      }

      return null;
    });
  };

  const handleShowVehicle = (data) => {
    setCreate(data);
    $("#basicVehicle").parsley();
    $("#modalAddVehicle").modal(
      { backdrop: "static", keyboard: false },
      "show"
    );
  };
  const handleShowEngagement = (data) => {
    setCreate(data);
    $("#basicEngagement").parsley();
    $("#modalAddEngagement").modal(
      { backdrop: "static", keyboard: false },
      "show"
    );
  };
  const onCloseModal = (id, form) => {
    let parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    parsley.reset();
    parsley.destroy();
    $(`#${id}`).modal("hide");
    $(".modal-backdrop").remove();
  };

  const onEditClient = () => {
    $("#modalEditClient").modal("show");
  };

  const submitUpdateClient = (e) => {
    e.preventDefault();
    setEditLoading(true);
    let raw = {
      firstName: $("#FirstName").val(),
      middleName: $("#MiddleName").val(),
      lastName: $("#LastName").val(),
      phoneNumber: $("#PhoneNumber").val(),
      occupation: $("#Occupation").val(),
      gender: $("#Gender").val(),
      idType: $("#IdType").val(),
      idNumber: $("#IdTypeValue").val(),
      physicalAddress: $("#Address").val(),
      dateOfBirth: new Date(),
    };

    updateUser(props.match.params.id, raw).then((response) => {
      setEditLoading(false);

      const options = {
        position: "top-right",
      };

      if (response.error)
        return cogoToast.error("Something went wrong.", options);

      $("#type").val(response.payload.clientType);
      $("#accountType").text(response.payload.clientType);
      $("#name").text(
        `${response.payload.firstName} ${
          response.payload.middleName ? response.payload.middleName : ""
        } ${response.payload.lastName}`
      );
      $("#fullName").text(
        `${response.payload.firstName} ${
          response.payload.middleName ? response.payload.middleName : ""
        } ${response.payload.lastName}`
      );
      $("#gender").text(response.payload.gender);
      $("#mobile").text(response.payload.phoneNumber);
      $("#dob").text(
        moment(response.payload.dateOfBirth).format("Do MMM, YYYY")
      );
      $("#email").text(response.payload.email);
      $("#idType").text(`${response.payload.idType}:`);
      $("#idNumber").text(response.payload.idNumber || "Blank");
      $("#address").text(response.payload.physicalAddress || "Blank");
      $("#accountCreated").text(
        moment(response.payload.createdAt).format("Do MMM, YYYY - HH:mm")
      );

      $("#modalEditClient").modal("hide");
      $(".modal-backdrop").remove();

      cogoToast.success("Client successfully Updated.", options);
    });
  };

  const onModalGenerateQuotation = () => {
    $("#quotationForm").parsley();
    $("#modalAddQuotation").modal(
      { backdrop: "static", keyboard: false },
      "show"
    );
  };
  const onSubmitEngagement = (e) => {
    e.preventDefault();

    setCreateLoading(true);

    let raw = {
      engagementType: $("#engagementType").val(),
      engagementDate: moment().toISOString(),
      engagementDescription: $("#engagementDescription").val(),
    };

    addEngagement(props.match.params.id, raw).then((res) => {
      const options = {
        position: "top-right",
      };

      setCreateLoading(false);

      if (res.error) return cogoToast.error("Something went wrong.", options);

      $("#modalAddEngagement").modal("hide");
      $(".modal-backdrop").remove();

      cogoToast.success("Client successfully Engaged.", options);

      $("#engagements_table").DataTable().ajax.reload();
    });
  };

  const onSubmitQuotation = (e) => {
    e.preventDefault();

    setCreateLoading(true);

    let raw = {
      productId: $("#product").val(),
      startDate: new Date(),
      quarters: $("#quarter").val(),
      vehicleId: vehicleData.id,
      sourceOfBusiness: $("#sourceOfBusiness").val(),
      clientId: props.match.params.id,
      gariCode: "AT-012",
      sumInsured: $("#sumInsured").val(),
      premiumType: $("#valueType").val(),
      amount: $("#value").val(),
      rate: $("#value").val(),
    };

    createQuotation(raw, id).then((res) => {
      console.log("creating quotation", JSON.stringify(raw));
      const options = {
        position: "top-right",
      };

      setCreateLoading(false);

      if (res.error) return cogoToast.error("Something went wrong.", options);

      $("#modalAddQuotation").modal("hide");
      $(".modal-backdrop").remove();

      cogoToast.success("Quotation successfully Added.", options);

      $("#quotation_table").DataTable().ajax.reload();
    });
  };

  const showModalVehicle = () => {
    setCreate(false);
    $("#RiskCategory").val(vehicleData.category.id);
    $("#BodyType").val(vehicleData.bodyType.id);
    $("#RegNumber").val(vehicleData.regNumber);
    $("#Make").val(vehicleData.make);
    $("#Model").val(vehicleData.model);
    $("#EngineNumber").val(vehicleData.engineNumber);
    $("#YearOfManufacture").val(vehicleData.yearOfManufacture);
    $("#ChassisNumber").val(vehicleData.chassisNumber);
    $("#CubicCapacity").val(vehicleData.cubicCapacity);
    $("#Color").val(vehicleData.color);

    $("#basicVehicle").parsley();
    $("#modalAddVehicle").modal("show");
  };

  const onDeleteVehicle = (e) => {
    e.preventDefault();
    setVehicleLoading(true);
    getVehicleInfo(vehicleData.id).then((result) => {
      if (result.payload.status === "HasPolicy") {
        setVehicleLoading(false);
        cogoToast.error(
          "Sorry, This vehicle has policy and cannot be deleted.",
          { position: "top-right", hideAfter: 3 }
        );
      } else {
        deleteVehicle(vehicleData.id).then((result) => {
          setVehicleLoading(false);
          if (!result.error) {
            $("#modalVehicle").modal("hide");
            $(".modal-backdrop").remove();
            cogoToast.success("Vehicle successfully removed.", {
              position: "top-right",
              hideAfter: 3,
            });
            $("#vehicle_table").DataTable().ajax.reload();
          } else {
            cogoToast.error(result.payload, {
              position: "top-right",
              hideAfter: 3,
            });
          }
        });
      }
    });
  };

  const onTypeChange = (e) => {
    setType(e.target.value);
  };

  if (!loading) {
    return (
      <div className="content-body">
        <div className="container pd-x-0">
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <a href="#">Menu</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Client</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Client Details
                  </li>
                </ol>
              </nav>
              <h4 id="name" className="mg-b-0 tx-spacing--1">
                ---
              </h4>
            </div>
            <div className="d-none d-md-block">
              <button
                className="btn btn-sm pd-x-15 btn-brand-01 btn-uppercase mg-l-5 outline-none"
                onClick={onEditClient.bind()}
              >
                <i className="wd-10 mg-r-5 fa fa-edit"></i> Edit
              </button>
            </div>
          </div>

          <div className="row row-xs">
            <div className="col-md-12 mg-t-10">
              <div className="nav-wrapper mg-b-20 tx-13">
                <div>
                  <ul
                    id="nav_basic"
                    className="nav nav-line nav-fill tx-medium"
                  >
                    <li className="nav-item">
                      <a
                        href="#customer"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-person" /> Customer Details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#policy" className="nav-link" data-toggle="tab">
                        <i className="ion-ios-paper" /> Policies{" "}
                        <span
                          id="total_policies"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#quotation"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-document" /> Quotations{" "}
                        <span
                          id="total_quotations"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#vehicle" className="nav-link" data-toggle="tab">
                        <i className="ion-ios-car" /> Vehicles{" "}
                        <span
                          id="total_vehicles"
                          className="badge badge-light mg-l-2"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#claim" className="nav-link" data-toggle="tab">
                        <i className="ion-ios-car" /> Claims{" "}
                        <span
                          id="total_claims"
                          className="badge badge-light mg-l-2"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#transaction"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-card" /> Transactions{" "}
                        <span
                          id="total_transactions"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#receipt" className="nav-link" data-toggle="tab">
                        <i className="ion-ios-checkmark-circle" /> Receipts{" "}
                        <span
                          id="total_receipts"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#engagements_tab"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-checkmark-circle" /> Engagements{" "}
                        <span
                          id="total_engagements"
                          className="badge badge-light mg-l-5"
                        >
                          0
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tab-content">
                <div id="customer" className="tab-pane fade active show">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <p>
                        <strong>Account Type:</strong>{" "}
                        <span id="accountType">Blank</span>
                      </p>
                      <p>
                        <strong>Full Name:</strong>{" "}
                        <span id="fullName">Blank</span>
                      </p>
                      <p>
                        <strong>DOB:</strong> <span id="dob">Blank</span>
                      </p>
                      <p>
                        <strong>Gender:</strong> <span id="gender">Blank</span>
                      </p>
                      <p>
                        <strong>Occupation:</strong>{" "}
                        <span id="occupation">Blank</span>
                      </p>
                      <p>
                        <strong>Mobile Number:</strong>{" "}
                        <span id="mobile">Blank</span>
                      </p>
                      <p>
                        <strong id="idType">Passport:</strong>{" "}
                        <a href="#" id="idNumber">
                          Blank
                        </a>
                      </p>
                      <p>
                        <strong>Email:</strong> <span id="email">Blank</span>
                      </p>
                      <p>
                        <strong>Physical Address:</strong>{" "}
                        <span id="address">Blank</span>
                      </p>
                      <p>
                        <strong>Account Created:</strong>{" "}
                        <span id="accountCreated">Blank</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div id="policy" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Policies</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#policy_table").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="policy_table"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-20p">Policy #</th>
                                <th className="wd-20p">Source Of Business</th>
                                <th className="wd-15p">Start Date</th>
                                <th className="wd-15p">End Date</th>
                                <th className="wd-10p">U/W Year</th>
                                <th className="wd-10p">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="quotation" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Quotations</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0"
                            >
                              <i className="icon ion-md-add"></i>
                            </a>
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#quotation_table").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="quotation_table"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-20p">Quotation #</th>
                                <th className="wd-10p"># of Risks</th>
                                <th className="wd-15p">Start Date</th>
                                <th className="wd-15p">End Date</th>
                                <th className="wd-15p">Expiry Date</th>
                                <th className="wd-10p">U/W Year</th>
                                <th className="wd-10p">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="vehicle" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Vehicles</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0"
                              onClick={handleShowVehicle.bind(null, true)}
                            >
                              <i className="icon ion-md-add"></i>
                            </a>
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#vehicle_table").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="vehicle_table"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-15p">Reg #</th>
                                <th className="wd-15p">Make</th>
                                <th className="wd-15p">Model</th>
                                <th className="wd-15p">Engine #</th>
                                <th className="wd-15p">Category</th>
                                <th className="wd-15p">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="claim" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Claims</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#claim_table").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="claim_table"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-15p">Claim #</th>
                                <th className="wd-15p">Type</th>
                                <th className="wd-15p">Stage</th>
                                <th className="wd-15p">Estimate Cost</th>
                                <th className="wd-15p">Loss Date</th>
                                <th className="wd-15p">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="transaction" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Transactions</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#transaction_table")
                                  .DataTable()
                                  .ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="transaction_table"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-25p">Amount</th>
                                <th className="wd-25p">Payment Method</th>
                                <th className="wd-25p">Date Created</th>
                                <th className="wd-25p">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="receipt" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Receipts</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#receipt_table").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="receipt_table"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-15p">Quotation #</th>
                                <th className="wd-15p">Amount</th>
                                <th className="wd-15p">Payment</th>
                                <th className="wd-15p">Account #</th>
                                <th className="wd-15p">Date</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="engagements_tab" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Engagements</h6>
                          <div className="d-flex tx-18">
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0"
                              onClick={handleShowEngagement.bind(null, true)}
                            >
                              <i className="icon ion-md-add"></i>
                            </a>{" "}
                            <a
                              href="javascript:void(0)"
                              className="link-03 lh-0 mg-l-10"
                              onClick={() =>
                                $("#engagements_table")
                                  .DataTable()
                                  .ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="engagements_table"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-15p">Engagement Type</th>
                                <th className="wd-15p">Description</th>
                                <th className="wd-15p">Date</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalVehicle"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href=""
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">Vehicle Detail</h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Customer vehicle full information.
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <div className="row row-sm">
                  <div className="col-md-12">
                    <p>
                      <strong>Registration #:</strong>{" "}
                      <span id="regNumber">Blank</span>
                    </p>
                    <p>
                      <strong>Make:</strong> <span id="make">Blank</span>
                    </p>
                    <p>
                      <strong>Model:</strong> <span id="model">Blank</span>
                    </p>
                    <p>
                      <strong>Engine Number:</strong>{" "}
                      <span id="engineNumber">Blank</span>
                    </p>
                    <p>
                      <strong>Chassis Number:</strong>{" "}
                      <span id="chassisNumber">Blank</span>
                    </p>
                    <p>
                      <strong>Cubic Capacity:</strong>{" "}
                      <span id="cubicCapacity">Blank</span>
                    </p>
                    <p>
                      <strong>Color:</strong> <span id="color">Blank</span>
                    </p>
                    <p>
                      <strong>Category:</strong>{" "}
                      <span id="category">Blank</span>
                    </p>
                    <p>
                      <strong>body Type:</strong>{" "}
                      <span id="bodyType">Blank</span>
                    </p>
                    <p>
                      <strong>Created At:</strong>{" "}
                      <span id="createdAt">Blank</span>
                    </p>
                    <p>
                      <strong>Updated At:</strong>{" "}
                      <span id="updatedAt">Blank</span>
                    </p>
                    <p>
                      <strong>Status:</strong>{" "}
                      <span id="vehicleStatus">Blank</span>
                    </p>
                    <p>
                      <strong>Year Of Manufacture:</strong>{" "}
                      <span id="yearOfManufacture">Blank</span>
                    </p>
                    <p>
                      <strong>License:</strong>{" "}
                      <a href="#" id="license">
                        View License
                      </a>
                    </p>
                    <p>
                      <strong>White Book:</strong>{" "}
                      <a href="#" id="whitebook">
                        View Whitebook
                      </a>
                    </p>
                    <button
                      className="btn btn-brand-02 btn-block mt-4"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={onModalGenerateQuotation.bind()}
                    >
                      <i className="fa fa-file"></i> Generate Quotation
                    </button>
                    <button
                      className="btn btn-secondary btn-block mt-2"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={showModalVehicle.bind()}
                    >
                      <i className="fa fa-pencil"></i> Edit Vehicle
                    </button>
                    {vehicleLoading ? (
                      <button disabled className="btn btn-danger btn-block">
                        <span
                          className="spinner-border spinner-border-sm mg-r-10"
                          role="status"
                          aria-hidden="true"
                        />
                        Please Wait
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger btn-block mt-2"
                        onClick={onDeleteVehicle.bind()}
                      >
                        <i className="fa fa-trash-alt"></i> Delete vehicle
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalReceipt"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href=""
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">Receipt Detail</h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Customer receipt full information.
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <div className="row row-sm">
                  <div className="col-md-12">
                    <p>
                      <strong>Date Issued:</strong>{" "}
                      <span id="transactDate">Blank</span>
                    </p>
                    <p>
                      <strong>Receipt:</strong>{" "}
                      <span id="receiptNumber">Blank</span>
                    </p>
                    <p>
                      <strong>Amount:</strong>{" "}
                      <span id="receiptAmount">Blank</span>
                    </p>
                    <p>
                      <strong>Payment Method:</strong>{" "}
                      <span id="receiptPayment">Blank</span>
                    </p>
                    <p>
                      <strong>Narration:</strong>{" "}
                      <span id="receiptNarration">Blank</span>
                    </p>
                    <p>
                      <strong>Remarks:</strong>{" "}
                      <span id="receiptRemarks">Blank</span>
                    </p>
                    <p>
                      <strong>Captured By:</strong>{" "}
                      <span id="receiptCaptureBy">Blank</span>
                    </p>
                    <p>
                      <strong>Source of Business:</strong>{" "}
                      <span id="receiptSourceOfBusiness">Blank</span>
                    </p>
                    <p>
                      <strong>Account Number:</strong>{" "}
                      <span id="receiptAccountNumber">Blank</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="modalAddEngagement"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href="javascript:void(0)"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    onClick={onCloseModal.bind(
                      null,
                      "modalAddEngagement",
                      "basicEngagement"
                    )}
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">
                    {create ? " Add Engagement" : "Edit Engagement"}
                  </h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Fill the information below.
                  </p>
                </div>
              </div>{" "}
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="basicEngagement"
                  className="parsley-style-1"
                  onSubmit={onSubmitEngagement.bind()}
                >
                  <div
                    id="engagementTypeWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Engagement Type<span className="tx-danger"></span>
                    </label>
                    <select
                      className="custom-select"
                      id="engagementType"
                      require
                      d
                    >
                      <option value="" disabled selected>
                        Select
                      </option>

                      <option value={"Call"}>Call</option>
                      <option value={"Message"}>Message</option>
                      <option value={"Email"}>Email</option>
                      <option value={"Meeting"}>Meeting</option>
                    </select>
                  </div>
                  <div
                    id="engagementDescriptionWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>Description</label>
                    <textarea
                      id="engagementDescription"
                      className="form-control"
                      placeholder="Enter Engagement Description  "
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#engagementDescriptionWrapper"
                    />
                  </div>
                  <div className="col-md-12">
                    {createLoading ? (
                      <button disabled className="btn btn-brand-02 float-right">
                        <span
                          className="spinner-border spinner-border-sm mg-r-10"
                          role="status"
                          aria-hidden="true"
                        />
                        Please Wait
                      </button>
                    ) : (
                      <button className="btn btn-brand-01 btnNextFront btn-block">
                        Add Engagement
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="modalAddVehicle"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href="javascript:void(0)"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    onClick={onCloseModal.bind(
                      null,
                      "modalAddVehicle",
                      "basicVehicle"
                    )}
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">
                    {create ? "Add Vehicle" : "Edit Vehicle"}
                  </h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Fill the information below.
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="basicVehicle"
                  className="parsley-style-1"
                  onSubmit={onAddVehicleSubmit.bind()}
                >
                  <div className="row row-sm">
                    <div
                      id="riskWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Category<span className="tx-danger">*</span>
                      </label>
                      <select
                        className="custom-select"
                        id="RiskCategory"
                        required
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {riskCategory.map((data) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                      </select>
                    </div>

                    <div
                      id="bodyTypeWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Body Type<span className="tx-danger">*</span>
                      </label>
                      <select className="custom-select" id="BodyType" required>
                        <option value="" disabled selected>
                          Select
                        </option>
                        {bodyType.map((data) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                      </select>
                    </div>

                    <div
                      id="regWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Registration #<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="RegNumber"
                        className="form-control"
                        placeholder="Enter registration number"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#regWrapper"
                        required
                      />
                    </div>

                    <div
                      id="makeWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Make<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="Make"
                        className="form-control"
                        placeholder="Enter make"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#makeWrapper"
                        required
                      />
                    </div>

                    <div
                      id="modelWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Model<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="Model"
                        className="form-control"
                        placeholder="Enter model"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#modelWrapper"
                        required
                      />
                    </div>

                    <div
                      id="engineWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Engine Number<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="EngineNumber"
                        className="form-control"
                        placeholder="Enter engine number"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#engineWrapper"
                        required
                      />
                    </div>

                    <div
                      id="yearWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Year Of Manufacture<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="YearOfManufacture"
                        className="form-control"
                        placeholder="Enter year of manufacture"
                        type="number"
                        autoComplete="off"
                        data-parsley-class-handler="#yearWrapper"
                        required
                      />
                    </div>

                    <div
                      id="chassisWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Chassis Number<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="ChassisNumber"
                        className="form-control"
                        placeholder="Enter chassis number"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#chassisWrapper"
                        required
                      />
                    </div>

                    <div
                      id="cubicWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Cubic Capacity<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="CubicCapacity"
                        className="form-control"
                        placeholder="Enter cubic capacity"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#cubicWrapper"
                        required
                      />
                    </div>

                    <div
                      id="colorWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Color<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="Color"
                        className="form-control"
                        placeholder="Select Color"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#colorWrapper"
                        required
                      />
                    </div>

                    <div
                      id="LicenseWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>License(Optional)</label>
                      <input
                        id="DriversLicense"
                        className="form-control"
                        placeholder="Select"
                        type="file"
                        autoComplete="off"
                        data-parsley-class-handler="#licenseWrapper"
                      />
                    </div>

                    <div
                      id="WhitebookWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>WhiteBook(Optional)</label>
                      <input
                        id="Whitebook"
                        className="form-control"
                        placeholder="Select"
                        type="file"
                        data-parsley-class-handler="#whitebookWrapper"
                      />
                    </div>
                  </div>
                  {vehicleLoading ? (
                    <button disabled className="btn btn-brand-02 btn-block">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : create ? (
                    <button className="btn btn-brand-02 btn-block mt-2">
                      <i className="ion-md-add" /> Add Vehicle
                    </button>
                  ) : (
                    <button className="btn btn-brand-02 btn-block mt-2">
                      <i className="ion-md-save" /> Update vehicle
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalEditClient"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href="javascript:void(0)"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">Edit Client</h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Fill the information below.
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="basicClient"
                  className="parsley-style-1"
                  data-parsley-validate
                  noValidate
                  onSubmit={submitUpdateClient.bind()}
                >
                  <div className="row row-sm">
                    <div
                      id="typeWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Type<span className="tx-danger">*</span>
                      </label>
                      <select className="custom-select" id="type" disabled>
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value="Individual">Individual</option>
                        <option value="Corporate">Corporate</option>
                      </select>
                    </div>
                    {(() => {
                      switch (accountType) {
                        case "Individual":
                          return (
                            <>
                              <div
                                id="firstWrapper"
                                className="form-group parsley-input col-sm-4"
                              >
                                <label>
                                  First Name<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="FirstName"
                                  className="form-control"
                                  placeholder="Enter first name"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#firstWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="middleWrapper"
                                className="form-group parsley-input col-sm-4"
                              >
                                <label>Middle Name (Optional)</label>
                                <input
                                  id="MiddleName"
                                  className="form-control"
                                  placeholder="Enter middle name"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#middleWrapper"
                                />
                              </div>

                              <div
                                id="lastWrapper"
                                className="form-group parsley-input col-sm-4"
                              >
                                <label>
                                  Last Name<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="LastName"
                                  className="form-control"
                                  placeholder="Enter last name"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#lastWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="dateWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Date of Birth
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="Date_from"
                                  className="form-control"
                                  placeholder="Select Start Date"
                                  autoComplete="off"
                                  data-parsley-class-handler="#dateWrapper"
                                  required
                                  readOnly
                                />
                              </div>

                              <div
                                id="genderWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Gender<span className="tx-danger">*</span>
                                </label>
                                <select
                                  className="custom-select"
                                  id="Gender"
                                  data-parsley-class-handler="#genderWrapper"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>

                              <div
                                id="idWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  ID Type<span className="tx-danger">*</span>
                                </label>
                                <select
                                  className="custom-select"
                                  id="IdType"
                                  data-parsley-class-handler="#idWrapper"
                                  required
                                >
                                  <option value="NRC">NRC</option>
                                  <option value="Passport">Passport</option>
                                  <option value="DriversLicense">
                                    Drivers License
                                  </option>
                                </select>
                              </div>

                              <div
                                id="idTypeValueWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  {labelIdType} Number
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="IdTypeValue"
                                  className="form-control"
                                  placeholder="Enter value"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#idTypeValueWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="occupationWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Occupation<span className="tx-danger">*</span>
                                </label>
                                <select
                                  className="custom-select"
                                  id="Occupation"
                                  data-parsley-class-handler="#occupationWrapper"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Employed">Employed</option>
                                  <option value="Unemployed">Unemployed</option>
                                  <option value="Student">Student</option>
                                </select>
                              </div>

                              <div
                                id="addressWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Address<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="Address"
                                  className="form-control"
                                  placeholder="Enter address"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#addressWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="phoneWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Phone Number
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="PhoneNumber"
                                  className="form-control"
                                  placeholder="Enter phone number"
                                  type="number"
                                  autoComplete="off"
                                  data-parsley-class-handler="#phoneWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="emailWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Email<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="EmailAddress"
                                  className="form-control"
                                  placeholder="Enter last name"
                                  type="email"
                                  autoComplete="off"
                                  data-parsley-class-handler="#emailWrapper"
                                  required
                                />
                              </div>
                            </>
                          );
                        case "Corporate":
                          return (
                            <>
                              <div
                                id="companyWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Company Name
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="companyName"
                                  className="form-control"
                                  placeholder="Enter company name"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#companyWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="cRegWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Company Registration #
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="companyReg"
                                  className="form-control"
                                  placeholder="Enter first name"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#cRegWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="cPhoneWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Phone Number
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="phone"
                                  className="form-control"
                                  placeholder="Enter phone number"
                                  type="number"
                                  autoComplete="off"
                                  data-parsley-class-handler="#cPhoneWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="cEmailWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Email<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="email"
                                  className="form-control"
                                  placeholder="Enter last name"
                                  type="email"
                                  autoComplete="off"
                                  data-parsley-class-handler="#cEmailWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="cAddressWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Address<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="address"
                                  className="form-control"
                                  placeholder="Enter address"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#cAddressWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="cBoxWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  P.O.Box<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="pobox"
                                  className="form-control"
                                  placeholder="Enter P.O. Box"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#cBoxWrapper"
                                  required
                                />
                              </div>

                              <hr />

                              <div className="col-md-12 mg-t-30">
                                <h5>Person Contact</h5>
                              </div>

                              <div
                                id="titleWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Title<span className="tx-danger">*</span>
                                </label>
                                <select
                                  className="custom-select"
                                  id="titleWrapper"
                                  data-parsley-class-handler="#genderWrapper"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Mr">Mr</option>
                                  <option value="Mrs">Mrs</option>
                                  <option value="Miss">Miss</option>
                                </select>
                              </div>

                              <div
                                id="cFirstWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  First Name<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="firstName"
                                  className="form-control"
                                  placeholder="Enter first name"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#cFirstWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="lastWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Last Name<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="lastName"
                                  className="form-control"
                                  placeholder="Enter last name"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#lastWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="genderWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Gender<span className="tx-danger">*</span>
                                </label>
                                <select
                                  className="custom-select"
                                  id="gender"
                                  data-parsley-class-handler="#genderWrapper"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Rent">Male</option>
                                  <option value="Sale">Female</option>
                                  <option value="Sale">Other</option>
                                </select>
                              </div>

                              <div
                                id="contactPhoneWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Phone Number
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="contactNumber"
                                  className="form-control"
                                  placeholder="Enter phone number"
                                  type="number"
                                  autoComplete="off"
                                  data-parsley-class-handler="#contactPhoneWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="contactEmailWrapper"
                                className="form-group parsley-input col-sm-6"
                              >
                                <label>
                                  Email<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="contactEmail"
                                  className="form-control"
                                  placeholder="Enter last name"
                                  type="email"
                                  autoComplete="off"
                                  data-parsley-class-handler="#contactEmailWrapper"
                                  required
                                />
                              </div>

                              <div
                                id="designationWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Designation
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="designation"
                                  className="form-control"
                                  placeholder="Enter designation"
                                  type="text"
                                  autoComplete="off"
                                  data-parsley-class-handler="#designationWrapper"
                                  required
                                />
                              </div>
                            </>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                  {editLoading ? (
                    <button disabled className="btn btn-brand-02 float-right">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button className="btn btn-brand-01 float-right btnNextFront">
                      Update <i className="ion-md-arrow-forward" />
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalAddQuotation"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href=""
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                    onClick={onCloseModal.bind(
                      null,
                      "modalAddQuotation",
                      "quotationForm"
                    )}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">Add Quotation</h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Generate quotation for the vehicle with Reg: (
                    {vehicleData.regNumber})
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="quotationForm"
                  className="parsley-style-1"
                  onSubmit={onSubmitQuotation.bind()}
                >
                  <div className="row row-sm">
                    <div className="col-sm-12 mg-b-10">
                      <h5>Vehicle Reg# {vehicleData.regNumber}</h5>
                    </div>

                    <div
                      id="sourceWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Source Of Business<span className="tx-danger">*</span>
                      </label>
                      <select
                        className="custom-select"
                        id="sourceOfBusiness"
                        required
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value="Direct">Direct</option>
                        <option value="Broker">Broker</option>
                        <option value="Agent">Agent</option>
                      </select>
                    </div>

                    <div
                      id="quarterWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Product<span className="tx-danger">*</span>
                      </label>
                      <select className="custom-select" id="product" required>
                        <option value="" disabled selected>
                          Select
                        </option>
                        {products.map((data) => (
                          <option value={data.id}>
                            {data.name} - {data.code}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div
                      id="dateWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Start Date<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="date_from"
                        className="form-control"
                        placeholder="Select Start Date"
                        autoComplete="off"
                        data-parsley-class-handler="#dateWrapper"
                        required
                        readOnly
                      />
                    </div>

                    <div
                      id="quarterWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Number of Quarter<span className="tx-danger">*</span>
                      </label>
                      <select className="custom-select" id="quarter" required>
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>

                    <div
                      id="sumInsuredWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Sum Insured<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="sumInsured"
                        className="form-control"
                        placeholder="Enter sum amount"
                        type="number"
                        autoComplete="off"
                        data-parsley-class-handler="#sumInsuredWrapper"
                        required
                      />
                    </div>

                    <div
                      id="typeWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Type<span className="tx-danger">*</span>
                      </label>
                      <select
                        className="custom-select"
                        id="valueType"
                        onChange={onTypeChange.bind(null)}
                        required
                      >
                        <option value="amount">Amount</option>
                        <option value="rate">Rate</option>
                      </select>
                    </div>

                    <div
                      id="numberWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        {capitalize(type)}
                        <span className="tx-danger">*</span>
                      </label>
                      <input
                        id="value"
                        className="form-control"
                        placeholder="Enter value"
                        type="number"
                        autoComplete="off"
                        data-parsley-class-handler="#numberWrapper"
                        required
                      />
                    </div>
                  </div>
                  <hr />
                  {createLoading ? (
                    <button disabled className="btn btn-brand-02 float-right">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button className="btn btn-brand-01 float-right btnNextFront">
                      Finish <i className="ion-md-arrow-forward" />
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container d-flex justify-content-center ht-100p">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mg-t-10">Collecting Data</p>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
