import axios from "axios";
import {
    UPLOAD_PRODUCT_DOCUMENT_URL,
    DELETE_PRODUCT_WORD_DOCUMENT_URL,
    ADD_PRODUCT_WORD_DOCUMENT_URL,
    UPDATE_PRODUCT_WORD_DOCUMENT_URL,
    DELETE_PRODUCT_CLAUSE_DOCUMENT_URL,
    ADD_PRODUCT_CLAUSE_DOCUMENT_URL,
    UPDATE_PRODUCT_CLAUSE_DOCUMENT_URL,
    ADD_RATE_RISK_CATEGORY_URL,
    UPDATE_RATE_RISK_CATEGORY_URL
} from "../api";

export const uploadProductDocument = (raw, config) => {
    return axios.post(UPLOAD_PRODUCT_DOCUMENT_URL, raw, config).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const deleteProductWordDocument = (product_id, document_id) => {
    return axios.delete(DELETE_PRODUCT_WORD_DOCUMENT_URL(product_id, document_id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const addProductWordDocument = (product_id, raw) => {
    return axios.post(ADD_PRODUCT_WORD_DOCUMENT_URL(product_id), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const updateProductWordDocument = (product_id, raw) => {
    return axios.put(UPDATE_PRODUCT_WORD_DOCUMENT_URL(product_id), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const deleteProductClauseDocument = (product_id, document_id) => {
    return axios.delete(DELETE_PRODUCT_CLAUSE_DOCUMENT_URL(product_id, document_id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const addProductClauseDocument = (product_id, raw) => {
    return axios.post(ADD_PRODUCT_CLAUSE_DOCUMENT_URL(product_id), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const updateProductClauseDocument = (product_id, raw) => {
    return axios.put(UPDATE_PRODUCT_CLAUSE_DOCUMENT_URL(product_id), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const addRateToRiskCategory = (raw) => {
    return axios.post(ADD_RATE_RISK_CATEGORY_URL, raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const updateRateToRiskCategory = (raw) => {
    return axios.put(UPDATE_RATE_RISK_CATEGORY_URL(raw.id), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};
