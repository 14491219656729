import axios from "axios";
import {
    GET_TOTAL_PREMIUM_URL,
    GET_TOTAL_GROSS_PREMIUM_URL
} from "../api";

export const getTotalPremiums = (id) => {
    return axios.all([
        axios.get(GET_TOTAL_PREMIUM_URL(id)),
        axios.get(GET_TOTAL_GROSS_PREMIUM_URL(id)),
    ]).then(axios.spread((response1, response2) => {
        // Multiple requests are now complete
        return {
            premium: response1.data,
            gross: response2.data
        }
    })).catch(() => {
        return null;
    });
};
