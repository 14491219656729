import React, { useEffect } from 'react';
import {GET_RECEIPT_URL, GET_UNRECEIPTED_URL} from '../../../api';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import 'select2';
import 'datatables.net';
import 'datatables.net-responsive';
import moment from "moment";
import logo from '../../../assets/img/logo.png';
import {useSelector} from "react-redux";
import formatNumber from "../../../utils/formatNumber";
import {getQuotation, getTotalPremiums, getPolicyReceipt} from '../../../request';
import cogoToast from "cogo-toast";

const Receipt = () => {

    document.title = "Receipt";

    const {id} = useSelector((state) => state.auth.user.user);

    useEffect(() => {
        getTotalPremiums(id).then((response) => {
            $('#tlPremium').text(formatNumber(response.premium.toFixed(2)));
            $('#tlReceipted').text(formatNumber(response.premium.toFixed(2)));
            $('#tlGrossPremium').text(formatNumber(response.gross.toFixed(2)));
            $('#tlUnreceipted').text(formatNumber(formatNumber((response.gross - response.premium).toFixed(2))));
        });

        var table = $('#receipt_table').DataTable({
            responsive: true,
            language: {
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Receipt Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(`${GET_RECEIPT_URL(id)}`, {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        res.data.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        $("#total_receipted").text(res.totalRecords);

                        callback({
                            recordsTotal: res.totalRecords,
                            recordsFiltered: res.totalRecords,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.policyNumber;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.client.firstName} ${data.client.middleName ? data.client.middleName : ""} ${data.client.lastName}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.sourceOfBusiness
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "Active" : `<span class="badge badge-success tx-white">${data.status}</span>`,
                            "Expired" : `<span class="badge badge-warning tx-white">${data.status}</span>`,
                            "Cancelled" : `<span class="badge badge-danger">${data.status}</span>`,
                            "InClaim" : `<span class="badge badge-warning">${data.status}</span>`,
                            "InReview" : `<span class="badge badge-info">${data.status}</span>`,
                            "PayLater" : `<span class="badge badge-secondary">Pay Later</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.createdAt).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        var unreceipted_table = $('#unreceipt_table').DataTable({
            responsive: true,
            language: {
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Receipt Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(`${GET_UNRECEIPTED_URL(id)}`, {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        res.data.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        $("#total_unreceipted").text(res.totalRecords);

                        callback({
                            recordsTotal: res.totalRecords,
                            recordsFiltered: res.totalRecords,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.policyNumber;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${data.client.firstName} ${data.client.middleName ? data.client.middleName : ""} ${data.client.lastName}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.sourceOfBusiness
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            "Active" : `<span class="badge badge-success tx-white">{data.status}</span>`,
                            "Expired" : `<span class="badge badge-warning tx-white">{data.status}</span>`,
                            "Cancelled" : `<span class="badge badge-danger">{data.status}</span>`,
                            "InClaim" : `<span class="badge badge-warning">${data.status}</span>`,
                            "InReview" : `<span class="badge badge-info">${data.status}</span>`,
                            "PayLater" : `<span class="badge badge-secondary">Pay Later</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.createdAt).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        $('#receipt_table tbody').on('click', 'tr', function () {
            let extract_data = table.row(this).data();

            let {hide} = cogoToast.loading('Please wait. Fetching document.', {position: "top-right"});
            getPolicyReceipt(extract_data.raw.clientId, extract_data.raw.id).then((response) => {
                if(!response.error) {
                    hide();
                    $("#receiptNumber").text(extract_data.raw.receiptNumber);
                    $("#sumInsured").text(`ZMW${response.payload.receipt.amount.toFixed(2)}`);
                    $("#remarks").text(response.payload.receipt.remarks);
                    $("#clientName").text(`${response.payload.policy.client.firstName} ${response.payload.policy.client.middleName ? response.payload.policy.client.middleName : ""} ${response.payload.policy.client.lastName}`);
                    $("#insuranceAddress").text(response.payload.policy.product.insuranceCompany.companyAddress);
                    $("#insuranceName").text(response.payload.policy.product.insuranceCompany.name);

                    $("#paymentMethod").text(response.payload.receipt.paymentMethod);
                    $("#policyNumber").text(response.payload.policy.policyNumber).attr("href", `#/policy-details/${response.payload.policy.client.id}/${response.payload.receipt.quotationId}`);
                    $("#createdAt").text(moment(response.payload.createdAt).format("Do MMM, YYYY"));
                    $("#capturedBy").text(response.payload.receipt.capturedBy);
                    $("#amount").text(response.payload.receipt.amount.toFixed(2));
                    $("#accountNumber").text(response.payload.receipt.accountNumber);
                    $("#date").text(moment(response.payload.createdAt).format("DD/MM/YYYY"));
                    $("#time").text(moment(response.payload.createdAt).format("HH:mm:ss"));
                    $("#modalViewReceipt").modal("show");
                }
            });
        });

        $('#unreceipt_table tbody').on('click', 'tr', function () {
            let extract_data = unreceipted_table.row(this).data();

            let {hide} = cogoToast.loading('Please wait. Fetching document.', {position: "top-right"});
            getPolicyReceipt(extract_data.raw.clientId, extract_data.raw.id).then((response) => {
                if(!response.error) {
                    hide();
                    $("#receiptNumber").text(extract_data.raw.receiptNumber);
                    $("#sumInsured").text(`ZMW${response.payload.receipt.amount.toFixed(2)}`);
                    $("#remarks").text(response.payload.receipt.remarks);
                    $("#clientName").text(`${response.payload.policy.client.firstName} ${response.payload.policy.client.middleName ? response.payload.policy.client.middleName : ""} ${response.payload.policy.client.lastName}`);
                    $("#insuranceAddress").text(response.payload.policy.product.insuranceCompany.companyAddress);
                    $("#insuranceName").text(response.payload.policy.product.insuranceCompany.name);

                    $("#paymentMethod").text(response.payload.receipt.paymentMethod);
                    $("#policyNumber").text(response.payload.policy.policyNumber).attr("href", `#/policy-details/${response.payload.policy.client.id}/${response.payload.receipt.quotationId}`);
                    $("#createdAt").text(moment(response.payload.createdAt).format("Do MMM, YYYY"));
                    $("#capturedBy").text(response.payload.receipt.capturedBy);
                    $("#amount").text(response.payload.receipt.amount.toFixed(2));
                    $("#accountNumber").text(response.payload.receipt.accountNumber);
                    $("#date").text(moment(response.payload.createdAt).format("DD/MM/YYYY"));
                    $("#time").text(moment(response.payload.createdAt).format("HH:mm:ss"));
                    $("#modalViewReceipt").modal("show");
                }
            });
        });

    }, []);

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Receipt</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Receipt</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Premium</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="tlPremium">0</span></h3>
                            </div>
                            <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">---</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Receipted</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="tlReceipted">0</span></h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">---</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Unreceipted</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="tlUnreceipted">0</span></h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">---</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Gross Premium</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="tlGrossPremium">0</span></h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">---</span>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-50">
                        <div className="nav-wrapper mg-b-20 tx-13">
                            <div>
                                <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                    <li className="nav-item"><a href="#receipted" className="nav-link active" data-toggle="tab"><i className="ion-ios-attach"/> Receipted Transaction <span id="total_receipted" className="badge badge-light mg-l-5">0</span></a></li>
                                    <li className="nav-item"><a href="#unreceipted" className="nav-link" data-toggle="tab"><i className="ion-md-document"/> Unreceipted Transaction <span id="total_unreceipted" className="badge badge-light mg-l-5">0</span></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="tab-content">
                            <div id="receipted" className="tab-pane fade active show">
                                <div className="row row-xs">
                                    <div className="col-md-12 mg-t-10">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <h6 className="mg-b-0">Receipted Transaction</h6>
                                                <div className="d-flex tx-18">
                                                    <a href="javascript:void(0)" onClick={() => $('#receipt_table').DataTable().ajax.reload()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                                </div>
                                            </div>
                                            <div className="card-body table-responsive">
                                                <table id="receipt_table" className="table table-hover">
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th className="wd-25p">Receipt #</th>
                                                        <th className="wd-25p">Client</th>
                                                        <th className="wd-20p">Source Of Business</th>
                                                        <th className="wd-10p">Status</th>
                                                        <th className="wd-20p">Date</th>
                                                    </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="unreceipted" className="tab-pane fade">
                                <div className="row row-xs">
                                    <div className="col-md-12 mg-t-10">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <h6 className="mg-b-0">Unreceipted Transaction</h6>
                                                <div className="d-flex tx-18">
                                                    <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                                </div>
                                            </div>
                                            <div className="card-body table-responsive">
                                                <table id="unreceipt_table" className="table table-hover" style={{width: "100%"}}>
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th className="wd-25p">Receipt #</th>
                                                        <th className="wd-25p">Client</th>
                                                        <th className="wd-20p">Source Of Business</th>
                                                        <th className="wd-10p">Status</th>
                                                        <th className="wd-20p">Date</th>
                                                    </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalViewReceipt" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Receipt</h4><p className="tx-13 tx-color-02 mg-b-0">Receipt #: <span id="receiptNumber">Not Set</span></p></div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <div className="row row-sm">
                                <div className="col-md-6">
                                    <img src={logo} height="55"/>
                                </div>
                                <div className="col-md-6">

                                </div>
                                <div className="col-md-12 mg-t-20">
                                    <p><strong>Received From:</strong> <span id="clientName">Not Set</span></p>
                                    <p><strong>On Behalf Of:</strong> <span id="insuranceName">Not Set</span></p>
                                    <p><strong>Address:</strong> <span id="insuranceAddress">Not Set</span></p>
                                    <p><strong>The Sum Of:</strong> <span id="sumInsured">Not Set</span></p>
                                    <p><strong>Agent Id:</strong> Not Set</p>
                                    <p><strong>Payment Method:</strong> <span id="paymentMethod">Not Set</span></p>
                                    <p><strong>Policy No:</strong> <a href="#" target="_blank" id="policyNumber">Not Set</a></p>
                                    <p><strong>Remarks:</strong> <span id="remarks">Not Set</span></p>
                                    <p><strong>Date Received:</strong> <span id="createdAt">Not Set</span></p>
                                </div>

                                <div className="col-md-12">
                                    <hr/>
                                    <p><strong>Date:</strong> <span id="date">Not Set</span></p>
                                    <p><strong>Time:</strong> <span id="time">Not Set</span></p>
                                    <p><strong>A/C No:</strong> <span id="accountNumber">Not Set</span></p>
                                    <p><strong>ZMW:</strong> <span id="amount">Not Set</span></p>
                                    <p><strong>Captured By:</strong> <span id="capturedBy">Not Set</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Receipt;
