export const gariCodes = [
  {
    code: "G-ACO",
    description: "Act Only Cover",
    id: 3,
    createdAt: "2021-07-12T06:26:13.295+00:00",
    updatedAt: "2021-07-12T06:26:13.295+00:00",
  },
  {
    code: "G-TFT",
    description: "Third Party Fire & Theft",
    id: 4,
    createdAt: "2021-07-12T06:25:56.735+00:00",
    updatedAt: "2021-07-12T06:25:56.735+00:00",
  },
  {
    code: "G-CMP",
    description: "Comprehensive Cover",
    id: 2,
    createdAt: "2021-07-12T06:25:22.93+00:00",
    updatedAt: "2021-07-12T06:25:22.93+00:00",
  },
  {
    code: "G-THP",
    description: "Motor Third Party",
    id: 1,
    createdAt: "2021-07-12T06:24:32.783+00:00",
    updatedAt: "2021-07-12T06:24:32.783+00:00",
  },
];
