import setAuthToken from "../utils/setAuthToken";
import { SET_AUTH_USER, UNSET_USER } from "../stores/auth";
import user from "../data/user.json";
import cogoToast from "cogo-toast";
import axios from "axios";
import { LOGIN_URL } from "api";
const option = {
  position: "top-right",
  hideAfter: 2,
};

//Login - Auth Token
export const loginUser = (userData) => {
  console.log("login req", userData);
  return async function (dispatch) {
    await axios
      .post(LOGIN_URL, userData)
      .then((response) => {
        if (response.status === 200) {
          let findUser = user.find(
            (data) => data.email === "tech@hobbiton.co.zm"
          );
          if (findUser !== undefined) {
            dispatch(SET_AUTH_USER({ user: findUser, isAuthenticated: true }));
            return false;
          }
        }
      })
      .catch((error) => {
        cogoToast.error("Email or password is in correct.", option);
        return false;
      });
  };
};

export const logoutUser = () => {
  return function (dispatch) {
    //Remove toke from localStorage
    localStorage.clear();

    //Remove auth header for future requests
    setAuthToken(false);

    //Set current user to {} which will set isAuthenticated to false
    dispatch(UNSET_USER());
  };
};
