import React, {useEffect, useState} from 'react';
import {GET_INSURANCE_DISCOUNT_URL} from '../../../api';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import 'select2';
import 'datatables.net';
import 'datatables.net-responsive';
import moment from "moment";
import {addons} from "../../../request";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import truncate from "../../../utils/trucate";

const Discount = (props) => {

    document.title = "Discount";

    const {id} = useSelector((state) => state.auth.user.user);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        var table = $('#discount').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Discount Data</p>'
            },
            "searching": false,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_INSURANCE_DISCOUNT_URL(id), {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        res.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.length,
                            recordsFiltered: res.length,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name !== null ? data.name : "Not Set";
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.description !== null ? truncate(data.description, 50, "...") : "Not Set";
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return moment(data.createdAt).format("Do MMM, YYYY");
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.updateddAt).format("Do MMM, YYYY");
                    },
                    "targets": 3
                }
            ]
        });

        $('#discount tbody').on('click', 'tr', function () {
            let extract_data = table.row(this).data();
            if(!extract_data) return false;
            $("#modalInfo").modal("show");
            $('#tx_name').text(extract_data.raw.name);
            $('#tx_description').text(extract_data.raw.description)
        });
    }, []);

    const onAddDiscountSubmit = (e) => {
        e.preventDefault();

        let data = {
            "name": $('#name').val(),
            "description": $('#description').val(),
            "insuranceCompanyId": id
        };

        setLoading(true);
        addons(data, "discount").then((result) => {
             setLoading(false);
             if(result.error) {
                 return cogoToast.error("Something went wrong");
             }

            $('#discount').DataTable().ajax.reload();
             onCloseModal("modalDiscount", "discount_basic");
        });

    };

    const onCloseModal = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleShowModal = (form) => {
        $(`#${form}`).parsley();
        $("#modalDiscount").modal("show");
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Addons</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Discount</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Discount</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind(null, "discount_basic")}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Discount</h6>
                                <div className="d-flex tx-18">
                                    <a href="javascript:void(0)" className="link-03 lh-0" onClick={() => $('#discount').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="discount" className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="wd-25p">Name</th>
                                        <th className="wd-30p">Description</th>
                                        <th className="wd-20p">Date Created</th>
                                        <th className="wd-20p">Date Updated</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDiscount" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="javascript:void(0)" role="button" className="close pos-absolute t-15 r-15" onClick={onCloseModal.bind(null, "modalDiscount", "discount_basic")}><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Add Vehicle</h4><p className="tx-13 tx-color-02 mg-b-0">Fill the information below.</p></div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="discount_basic" className="parsley-style-1" onSubmit={onAddDiscountSubmit.bind()}>
                                    <div className="row row-sm">

                                        <div id="nameWrapper" className="form-group parsley-input col-sm-12">
                                            <label>Name<span className="tx-danger">*</span></label>
                                            <input id="name" className="form-control"
                                                   placeholder="Enter name"
                                                   type="text"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="descriptionWrapper" className="form-group parsley-input col-sm-12">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description</label>
                                            <textarea id="description" type="text" rows="5" className="form-control" placeholder="Provide a description" data-parsley-class-handler="#descriptionWrapper" required></textarea>
                                        </div>
                                    </div>
                                    {loading ? <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-02 btn-block mt-2"><i className="ion-md-add"/> Add Discount</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalInfo" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">Discount</h4><p className="tx-13 tx-color-02 mg-b-0">Discount full information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="row row-sm">
                                    <div className="col-md-12">
                                        <p><strong>Name:</strong><br/><span id="tx_name">Blank</span></p>
                                        <p><strong>Description:</strong><br/><span id="tx_description">Blank</span></p>
                                        <button className="btn btn-brand-01 mt-4" data-dismiss="modal" aria-label="Close">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Discount;
