import React, { useEffect, useState } from "react";
import { GET_ALL_CLIENTS_URL, SEARCH_CLIENT_URL } from "../../../api";
import {
  saveUser,
  verifyEmailAddress,
  verifyPhoneNumber,
  getClientStats,
} from "../../../request";
import $ from "jquery";
import "components-jqueryui";
import "parsleyjs";
import "select2";
import "datatables.net";
import "datatables.net-responsive";
import cogoToast from "cogo-toast";
// import {useSelector} from "react-redux";
import validatePhone from "../../../utils/validatePhone";

const Client = (props) => {
  document.title = "Client";

  // const {id} = useSelector((state) => state.auth.user.user);

  const [type, setType] = useState("2");
  const [basicLoading, setBasicLoading] = useState(false);
  const [labelIdType, setLabelIdType] = useState("NRC");
  // const [branch, setBranch] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);

  // const {bank} = useSelector((state) => state.organization.bank);
  // const {accountType} = useSelector((state) => state.organization.accountType);

  useEffect(() => {
    $("#bankBranch").prop("disabled", true);
    getClientStats("clients").then((stats) => {
      $("#totalLeads").text(stats.payload.total);
      $("#dailyTotal").text(stats.payload.dailyTotal);
      $("#monthlyTotal").text(stats.payload.monthlyTotal);
      $("#yearlyTotal").text(stats.payload.yearlyTotal);
    });
    var table = $("#user").DataTable({
      buttons: ["copy", "excel", "pdf"],
      responsive: true,
      language: {
        processing:
          '<div className="spinner-border"></div><p className="mg-t-10 tx-12">Collecting Client Data</p>',
      },

      searching: true,
      lengthMenu: [[10], [10]],
      ordering: false,
      info: true,
      bFilter: true,

      processing: true,
      pageLength: 10,
      serverSide: true,
      orderCellsTop: true,

      ajax: function (data, callback) {
        // make a regular ajax request using data.start and data.length
        $.ajax(
          $(".dataTables_filter input").val().length > 0
            ? SEARCH_CLIENT_URL($(".dataTables_filter input").val())
            : GET_ALL_CLIENTS_URL,
          {
            type: "GET",
            data: {
              pageNumber: (data.length + data.start) / data.length,
              pageSize: data.length,
            },
            success: function (res) {
              let result = [];

              res.data.map((data) => {
                result.push({
                  raw: data,
                });
              });

              console.log("Clients Data", result);

              callback({
                recordsTotal: res.totalRecords,
                recordsFiltered: res.totalRecords,
                data: result.filter((data) => data.raw.clientStatus === "WithPolicy"),
              });
            },
          }
        );
      },
      columns: [
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
      ],
      columnDefs: [
        {
          render: function (data) {
            return `${data.firstName} ${
              data.middleName ? data.middleName : ""
            } ${data.lastName}`;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data.gender;
          },
          targets: 1,
        },
        {
          render: function (data) {
            return data.phoneNumber ? data.phoneNumber : "Not Set";
          },
          targets: 2,
        },
        {
          render: function (data) {
            return data.email;
          },
          targets: 3,
        },
        {
          render: function (data) {
            return data.status;
          },
          targets: [4],
          visible: false,
        },
      ],
    });

    $("#user tbody").on("click", "tr", function () {
      var extract_data = table.row(this).data();
      props.history.push(`/client-details/${extract_data.raw.id}`);
    });

    return () => {
      $("#date_from").datepicker("destroy");
    };
  }, []);

  $(function () {
    $("#date_from").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: "-120Y",
      maxDate: "-18Y",
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });

    $(".ad-select2").select2({
      minimumResultsForSearch: Infinity,
      placeholder: "Select",
    });

    $("#category_data").select2({
      placeholder: "Please select an skill",
      allowClear: true,
    });
  });

  const handleShowModal = () => {
    $("#modalAddUser").modal("show");
  };

  const handleClientSubmit = (e) => {
    e.preventDefault();

    let checkPhoneNumber = validatePhone($("#phoneNumber").val());

    if (checkPhoneNumber === null)
      return cogoToast.error("Please enter Zambia Phone Number.", {
        position: "top-right",
      });

    setBasicLoading(true);

    verifyPhoneNumber(checkPhoneNumber).then((res) => {
      if (res.error) {
        setBasicLoading(false);
        return cogoToast.error("Sorry, User phone number already exists.", {
          position: "top-right",
        });
      }

      verifyEmailAddress($("#emailAddress").val()).then((res) => {
        if (res.error) {
          setBasicLoading(false);
          return cogoToast.error("Sorry, User email address already exists.", {
            position: "top-right",
          });
        }

        setBasicLoading(false);
        $("#resFullName").text(
          $("#firstName").val() +
            " " +
            $("#middleName").val() +
            " " +
            $("#lastName").val()
        );
        $("#resGender").text($("#gender").val());
        $("#resPhone").text(validatePhone($("#phoneNumber").val()));
        $("#resOccupation").text($("#occupation").val());
        $("#resIdType").text($("#idTypeValue").val());
        $("#resEmail").text($("#emailAddress").val());
        $("#resAddress").text($("#address").val());
        $("#nav_basic .active").parent().next("li").find("a").trigger("click");
      });
    });
  };

  const handleCreateUser = () => {
    setCreateLoading(true);

    let raw = {
      firstName: $("#firstName").val(),
      middleName: $("#middleName").val(),
      lastName: $("#lastName").val(),
      password: $("#password").val(),
      phoneNumber: validatePhone($("#phoneNumber").val()),
      email: $("#emailAddress").val(),
      occupation: $("#occupation").val(),
      gender: $("#gender").val(),
      idType: $("#idType").val(),
      idNumber: $("#idTypeValue").val(),
      dateOfBirth: new Date($("#date_from").val()),
    };

    saveUser(raw).then((res) => {
      const options = {
        position: "top-right",
      };

      setCreateLoading(false);

      if (res.error) return cogoToast.error(res.payload.errors[0], options);

      $("#modalAddUser").modal("hide");
      $(".modal-backdrop").remove();
      $("#user").DataTable().ajax.reload();

      cogoToast.success("Client successfully Added.", options);
    });
  };

  const handleResetForm = () => {
    $("#basicInfo").parsley().reset();
    $("#modalAddUser").modal("hide");
    $(".modal-backdrop").remove();
  };

  const onTypeChange = (e) => {
    $("#basicInfo").parsley().reset();
    setType(e.target.value);
  };

  const handlePrevious = () => {
    $("#nav_basic .active").parent().prev("li").find("a").trigger("click");
  };

  const onIdTypeChange = (e) => {
    setLabelIdType(e.target.value);
  };

  return (
    <div className="content-body">
      <div className="container pd-x-0">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                <li className="breadcrumb-item">
                  <a href="#">Menu</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Client
                </li>
              </ol>
            </nav>
            <h4 className="mg-b-0 tx-spacing--1">Client</h4>
          </div>

          <div className="d-none d-md-block">
            <button
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
              onClick={handleShowModal.bind()}
            >
              <i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New
            </button>
          </div>
        </div>

        <div className="row row-xs">
          <div className="col-sm-6 col-lg-3">
            <div className="card card-body">
              <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                Total
              </h6>
              <div className="d-flex d-lg-block d-xl-flex align-items-end">
                <h3
                  id="totalLeads"
                  className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                >
                  -
                </h3>
              </div>

              <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">
                {" "}
                <br />{" "}
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
            <div className="card card-body">
              <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                Today
              </h6>
              <div className="d-flex d-lg-block d-xl-flex align-items-end">
                <h3
                  id="dailyTotal"
                  className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                >
                  -
                </h3>
              </div>
              <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">
                <br />
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
            <div className="card card-body">
              <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                Monthly
              </h6>
              <div className="d-flex d-lg-block d-xl-flex align-items-end">
                <h3
                  id="monthlyTotal"
                  className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                >
                  -
                </h3>
              </div>

              <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">
                <br />
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
            <div className="card card-body">
              <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                yearly
              </h6>
              <div className="d-flex d-lg-block d-xl-flex align-items-end">
                <h3
                  id="yearlyTotal"
                  className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                ></h3>
              </div>

              <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">
                <br />
              </span>
            </div>
          </div>
          <div className="col-md-12 mg-t-10">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="mg-b-0">Clients</h6>
                <div className="flex mx-4">
                  <div className="d-flex tx-18">
                    <a
                      href="javascript:void(0)"
                      onClick={() => $("#user").DataTable().ajax.reload()}
                      className="link-03 lh-0"
                    >
                      <i className="icon ion-md-refresh"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive">
                <table id="user" className="table">
                  <thead>
                    <tr>
                      <th className="wd-20p">Client Name</th>
                      <th className="wd-20p">Gender</th>
                      <th className="wd-20p">Phone</th>
                      <th className="wd-20p">Email</th>
                      <th className="wd-20p">Status</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalAddUser"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href="javascript:void(0)"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  onClick={handleResetForm.bind()}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Create User</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Create user to have access to the app
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <div className="nav-wrapper mg-b-20 tx-13">
                <div>
                  <ul
                    id="nav_basic"
                    className="nav nav-line nav-fill tx-medium nav-disabled"
                  >
                    <li className="nav-item">
                      <a
                        href="#basic"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        <i className="ion-md-information-circle" /> Basic Info
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#confirmation"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-checkmark-circle" /> Confirmation
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tab-content">
                <div id="basic" className="tab-pane fade active show">
                  <form
                    id="basicInfo"
                    className="parsley-style-1"
                    onSubmit={handleClientSubmit.bind()}
                  >
                    <div className="row row-sm">
                      <div
                        id="typeWrapper"
                        className="form-group parsley-input col-sm-12"
                      >
                        <label>
                          Type<span className="tx-danger">*</span>
                        </label>
                        <select
                          className="custom-select"
                          id="type"
                          onChange={onTypeChange.bind(null)}
                          required
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          <option value="Individual">Individual</option>
                          <option value="Corporate">Corporate</option>
                        </select>
                      </div>
                      {(() => {
                        switch (type) {
                          case "Individual":
                            return (
                              <>
                                <div
                                  id="firstWrapper"
                                  className="form-group parsley-input col-sm-4"
                                >
                                  <label>
                                    First Name
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="firstName"
                                    className="form-control"
                                    placeholder="Enter first name"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#firstWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="middleWrapper"
                                  className="form-group parsley-input col-sm-4"
                                >
                                  <label>Middle Name (Optional)</label>
                                  <input
                                    id="middleName"
                                    className="form-control"
                                    placeholder="Enter middle name"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#middleWrapper"
                                  />
                                </div>

                                <div
                                  id="lastWrapper"
                                  className="form-group parsley-input col-sm-4"
                                >
                                  <label>
                                    Last Name
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="lastName"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#lastWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="dateWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Date of Birth
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="date_from"
                                    className="form-control"
                                    placeholder="Select Start Date"
                                    autoComplete="off"
                                    data-parsley-class-handler="#dateWrapper"
                                    required
                                    readOnly
                                  />
                                </div>

                                <div
                                  id="genderWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Gender<span className="tx-danger">*</span>
                                  </label>
                                  <select
                                    className="custom-select"
                                    id="gender"
                                    data-parsley-class-handler="#genderWrapper"
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>

                                <div
                                  id="idWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    ID Type<span className="tx-danger">*</span>
                                  </label>
                                  <select
                                    className="custom-select"
                                    id="idType"
                                    data-parsley-class-handler="#idWrapper"
                                    onChange={onIdTypeChange.bind()}
                                    required
                                  >
                                    <option value="NRC">NRC</option>
                                    <option value="Passport">Passport</option>
                                    <option value="DriversLicense">
                                      Drivers License
                                    </option>
                                  </select>
                                </div>

                                <div
                                  id="idTypeValueWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    {labelIdType} Number
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="idTypeValue"
                                    className="form-control"
                                    placeholder="Enter value"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#idTypeValueWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="occupationWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    Occupation
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <select
                                    className="custom-select"
                                    id="occupation"
                                    data-parsley-class-handler="#occupationWrapper"
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="Employed">Employed</option>
                                    <option value="Unemployed">
                                      Unemployed
                                    </option>
                                    <option value="Student">Student</option>
                                  </select>
                                </div>

                                <div
                                  id="addressWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    Address<span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="address"
                                    className="form-control"
                                    placeholder="Enter address"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#addressWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="phoneWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Phone Number
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="phoneNumber"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    type="number"
                                    autoComplete="off"
                                    data-parsley-class-handler="#phoneWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="emailWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Email<span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="emailAddress"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    type="email"
                                    autoComplete="off"
                                    data-parsley-class-handler="#emailWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="passwordWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    Password<span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#passwordWrapper"
                                    required
                                  />
                                </div>
                              </>
                            );
                          case "Corporate":
                            return (
                              <>
                                <div
                                  id="companyWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    Company Name
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="companyName"
                                    className="form-control"
                                    placeholder="Enter company name"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#companyWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="cRegWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    Company Registration #
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="companyReg"
                                    className="form-control"
                                    placeholder="Enter first name"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#cRegWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="cPhoneWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Phone Number
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="phone"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    type="number"
                                    autoComplete="off"
                                    data-parsley-class-handler="#cPhoneWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="cEmailWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Email<span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="email"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    type="email"
                                    autoComplete="off"
                                    data-parsley-class-handler="#cEmailWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="cAddressWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Address<span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="address"
                                    className="form-control"
                                    placeholder="Enter address"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#cAddressWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="cBoxWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    P.O.Box<span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="pobox"
                                    className="form-control"
                                    placeholder="Enter P.O. Box"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#cBoxWrapper"
                                    required
                                  />
                                </div>

                                <hr />

                                <div className="col-md-12 mg-t-30">
                                  <h5>Person Contact</h5>
                                </div>

                                <div
                                  id="titleWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    Title<span className="tx-danger">*</span>
                                  </label>
                                  <select
                                    className="custom-select"
                                    id="titleWrapper"
                                    data-parsley-class-handler="#genderWrapper"
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Miss">Miss</option>
                                  </select>
                                </div>

                                <div
                                  id="cFirstWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    First Name
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="firstName"
                                    className="form-control"
                                    placeholder="Enter first name"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#cFirstWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="lastWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Last Name
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="lastName"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#lastWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="genderWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    Gender<span className="tx-danger">*</span>
                                  </label>
                                  <select
                                    className="custom-select"
                                    id="gender"
                                    data-parsley-class-handler="#genderWrapper"
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="Rent">Male</option>
                                    <option value="Sale">Female</option>
                                    <option value="Sale">Other</option>
                                  </select>
                                </div>

                                <div
                                  id="contactPhoneWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Phone Number
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="contactNumber"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    type="number"
                                    autoComplete="off"
                                    data-parsley-class-handler="#contactPhoneWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="contactEmailWrapper"
                                  className="form-group parsley-input col-sm-6"
                                >
                                  <label>
                                    Email<span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="contactEmail"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    type="email"
                                    autoComplete="off"
                                    data-parsley-class-handler="#contactEmailWrapper"
                                    required
                                  />
                                </div>

                                <div
                                  id="designationWrapper"
                                  className="form-group parsley-input col-sm-12"
                                >
                                  <label>
                                    Designation
                                    <span className="tx-danger">*</span>
                                  </label>
                                  <input
                                    id="designation"
                                    className="form-control"
                                    placeholder="Enter designation"
                                    type="text"
                                    autoComplete="off"
                                    data-parsley-class-handler="#designationWrapper"
                                    required
                                  />
                                </div>
                              </>
                            );
                          default:
                            return null;
                        }
                      })()}
                    </div>
                    {basicLoading ? (
                      <button disabled className="btn btn-brand-02 float-right">
                        <span
                          className="spinner-border spinner-border-sm mg-r-10"
                          role="status"
                          aria-hidden="true"
                        />
                        Please Wait
                      </button>
                    ) : (
                      <button className="btn btn-brand-01 float-right btnNextFront">
                        Next <i className="ion-md-arrow-forward" />
                      </button>
                    )}
                  </form>
                </div>

                <div id="confirmation" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <h6 className="tx-purple">Person Details</h6>
                      <p>
                        <strong>Full Name:</strong>{" "}
                        <span id="resFullName">Not Set</span>
                      </p>
                      <p>
                        <strong>Gender:</strong>{" "}
                        <span id="resGender">Not Set</span>
                      </p>
                      <p>
                        <strong>Mobile Number:</strong>{" "}
                        <span id="resPhone">Not Set</span>
                      </p>
                      <p>
                        <strong>{labelIdType}:</strong>{" "}
                        <span id="resIdType">Not Set</span>
                      </p>
                      <p>
                        <strong>Occupation:</strong>{" "}
                        <span id="resOccupation">Not Set</span>
                      </p>
                      <p>
                        <strong>Email:</strong>{" "}
                        <span id="resEmail">Not Set</span>
                      </p>
                      <p>
                        <strong>Physical Address:</strong>{" "}
                        <span id="resAddress">Not Set</span>
                      </p>
                    </div>
                  </div>

                  <hr />
                  <button
                    className="btn btn-dark float-left btnPrevious"
                    onClick={handlePrevious.bind()}
                  >
                    <i className="ion-md-arrow-back" /> Back
                  </button>

                  {createLoading ? (
                    <button disabled className="btn btn-brand-02 float-right">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button
                      className="btn btn-brand-01 float-right btnNextAd"
                      onClick={handleCreateUser.bind()}
                    >
                      Finish <i className="ion-md-arrow-forward" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
