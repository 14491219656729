// const BASE_URL = "https://gari-dev.hobbiton.tech";
const BASE_URL = "https://clientsapi.gari.dev";

exports.SIGNIN_URL = `${BASE_URL}/signin`;
exports.LOGIN_URL = `${BASE_URL}/login`;
exports.GET_ALL_INSURANCE_URL = `${BASE_URL}/insurance-companies`;
exports.GET_CLIENTS_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/clients`;
exports.ADD_INSURANCE_SALVAGE_URL = (id) =>
  `${BASE_URL}/salvages/insurance-company/${id}/upload`;
exports.DELETE_SALVAGE_URL = (id) => `${BASE_URL}/salvages/${id}/delete`;
exports.GET_CLIENTS_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/clients`;
exports.GET_ALL_CLIENTS_URL = `${BASE_URL}/clients`;
exports.GET_VEHICLES_URL = `${BASE_URL}/vehicles`;

exports.UPLOAD_POLICY_TO_RTSA = `${BASE_URL}/rtsa/upload/manual`;
exports.GET_ALL_POLICIES_URL = `${BASE_URL}/policies`;
exports.GET_ALL_CLIENTS_URL = `${BASE_URL}/clients`;
exports.GET_ALL_QUOTATIONS_URL = `${BASE_URL}/quotations`;
exports.GET_ALL_AGENTS_URL = `${BASE_URL}/gari-agents?PageNumber=1&PageSize=300`;
exports.LEADS_URL = `${BASE_URL}/leads`;
exports.CLIENTS_STATS__URL = (type) => `${BASE_URL}/stats/${type}`;
exports.PROSPECTS = `${BASE_URL}/leads/prospects`;
exports.CREATE_AGENT_URL = `${BASE_URL}/gari-agents`;
exports.GET_ALL_GARAGE_URL = `${BASE_URL}/garage`;
exports.MAKE_PAYMENT_URL = `${BASE_URL}/payments/pay-premium`;

exports.MAKE_PAYMENT_URL = `${BASE_URL}/payments/pay-premium`;
exports.GET_CLAIMS_URL = `${BASE_URL}/claims`;
exports.GET_SALVAGES_URL = `${BASE_URL}/salvages?PageNumber=1&PageSize=300`;

exports.CLIENTS_ENGAGEMENT_URL = (id) =>
  `${BASE_URL}/clients/${id}/engagements`;
exports.UPLOAD_POLICY_TO_RTSA_AUTOMATIC = (id) =>
  `${BASE_URL}/rtsa/upload/${id}`;
exports.GET_REPORTS = () => {
  return `${BASE_URL}/payments/reports`;
};
exports.DOWNLOAD_REPORTS = () => {
  return `${BASE_URL}/payments/reports/download`;
};

exports.LEAD_ACTIVITY_BY_ID_URL = (id) => `${BASE_URL}/leads/${id}/activities`;
exports.LEAD_TO_Client = (id) => `${BASE_URL}/leads/${id}/to-client`;
exports.LEAD_BY_ID_URL = (id) => `${BASE_URL}/leads/${id}`;
exports.CREATE_LEADS_ACTIVITY = (id) => `${BASE_URL}/leads/${id}`;
exports.GARI_AGENT_ID_URL = (id) => `${BASE_URL}/gari-agents/${id}`;
exports.GET_AGENT_CLIENTS_URL = (id) => `${BASE_URL}/gari-agents/${id}/clients`;
exports.GET_AGENT_LEADS_URL = (id) => `${BASE_URL}/gari-agents/${id}/leads`;
exports.GET_AGENT_COMMISSION_URL = (id) =>
  `${BASE_URL}/gari-agents/${id}/policies/commissions`;
exports.GET_AGENT_POLICIES_URL = (id) =>
  `${BASE_URL}/gari-agents/${id}/policies-v2`;
exports.GET_AGENT_TRANSACTIONS_URL = (id) =>
  `${BASE_URL}/gari-agents/${id}/transactions-v2`;
exports.GARI_AGENT_STATUS_URL = (id) =>
  `${BASE_URL}/gari-agents/${id}/stats-v2`;
exports.GET_PRODUCT_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/products`;
exports.GET_POLICIES_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/policies`;
exports.GET_THIRD_PARTY_RATE = (id) =>
  `${BASE_URL}/risk-categories/third-party-rates/${id}`;
exports.GET_QUOTATION_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/quotations`;
exports.GET_RECEIPT_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/receipted-policies`;
exports.GET_POLICY_RENEWAL_URL = (id) =>
  `${BASE_URL}/endorsements/renewals/${id}`;
exports.GET_UNRECEIPTED_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/unreceipted-policies`;
exports.GET_INSURANCE_DISCOUNT_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/discounts`;
exports.GET_INSURANCE_EXTENSION_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/extensions`;
exports.GET_INSURANCE_LOADING_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/loadings`;
exports.GET_TOTAL_GROSS_PREMIUM_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/gross-premium`;
exports.GET_TOTAL_PREMIUM_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/net-premium`;
exports.ADD_DISCOUNT_URL = `${BASE_URL}/policy-addons/discounts`;
exports.ADD_EXTENSION_URL = `${BASE_URL}/policy-addons/extensions`;
exports.ADD_LOADING_URL = `${BASE_URL}/policy-addons/loadings`;
exports.ADD_QUOTATION_DISCOUNT = `${BASE_URL}/quotations/discounts`;
exports.ADD_QUOTATION_EXTENSION = `${BASE_URL}/quotations/extensions`;
exports.ADD_QUOTATION_LIMIT_OF_LIABILITY = `${BASE_URL}/quotations/limits-of-liability`;
exports.ADD_PRODUCT_PERIL_URL = (id) =>
  `${BASE_URL}/insurance-companies/products/perils/${id}`;
exports.DELETE_PRODUCT_PERIL_URL = (id) =>
  `${BASE_URL}/insurance-companies/products/perils/${id}`;
exports.UPDATE_PRODUCT_PERIL_URL = (id) =>
  `${BASE_URL}/insurance-companies/products/perils/${id}`;
exports.GET_QUOTATION_ID_URL = (id) => `${BASE_URL}/quotations/${id}`;
exports.CREATE_QUOTATION_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/quotations`;
exports.GET_CLIENT_ALL_POLICIES_URL = (id) =>
  `${BASE_URL}/clients/${id}/policies`;
exports.GET_TP_ACT_RATES_POLICY_EXTENSION_URL = (id) =>
  `${BASE_URL}/policies/${id}/extension-rates`;
exports.EXTEND_THIRD_PARTY_ACT_ONLY_POLICY_URL = (id) =>
  `${BASE_URL}/policies/${id}/extend-third-party`;
exports.GET_POLICY_RECEIPT_URL = (clientId, id) =>
  `${BASE_URL}/clients/${clientId}/policy-receipts/${id}`;
exports.UPDATE_CLIENT_VEHICLE_URL = (id) => `${BASE_URL}/vehicles/${id}`;
exports.GET_CLIENT_POLICIES_URL = (client_id, policy_id) =>
  `${BASE_URL}/clients/${client_id}/policies/${policy_id}`;
exports.GET_CLIENT_QUOTATION_URL = (id) =>
  `${BASE_URL}/clients/${id}/quotations`;
exports.GET_CLIENT_VEHICLE_URL = (id) => `${BASE_URL}/clients/${id}/vehicles`;
exports.GET_CLIENT_CLAIM_URL = (id) => `${BASE_URL}/clients/${id}/claims`;
exports.GET_CLIENT_TRANSACTION_URL = (id) =>
  `${BASE_URL}/clients/${id}/transactions`;
exports.GET_CLIENT_RECEIPT_URL = (id) => `${BASE_URL}/clients/${id}/receipts`;
exports.GET_CLIENT_DETAIL_URL = (id) => `${BASE_URL}/clients/${id}`;
exports.GET_CLIENT_DOCUMENT_URL = (id) => `${BASE_URL}/clients/${id}/documents`;
exports.GET_BANK_BRANCH_URL = (id) => `${BASE_URL}/banks/${id}/branches`;
exports.GET_ACCOUNT_TYPE_URL = `${BASE_URL}/account-types`;
exports.CREATE_CLIENT_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/clients`;
exports.CLIENT_REGISTRATION = `${BASE_URL}/signup`;
exports.GET_VEHICLE_INFO_URL = (id) => `${BASE_URL}/vehicles/${id}`;
exports.DELETE_VEHICLE_URL = (id) => `${BASE_URL}/vehicles/${id}`;
exports.GET_INSURANCE_PRODUCT_URL = (id) =>
  `${BASE_URL}/insurance-companies/products/${id}`;
exports.GET_INSURANCE_ALL_PRODUCT = (id) =>
  `${BASE_URL}/insurance-companies/${id}/products`;
exports.GET_ALL_PRODUCTS = `${BASE_URL}/insurance-companies/products`;
exports.GET_INSURANCE_LOADING = (id) =>
  `${BASE_URL}/insurance-companies/${id}/loadings`;
exports.GET_INSURANCE_DISCOUNT = (id) =>
  `${BASE_URL}/insurance-companies/${id}/discounts`;
exports.GET_INSURANCE_EXTENSION = (id) =>
  `${BASE_URL}/insurance-companies/${id}/extensions`;
exports.UPDATE_CLIENT_URL = (id) => `${BASE_URL}/clients/${id}`;
exports.DELETE_PRODUCT_WORD_DOCUMENT_URL = (product_id, document_id) =>
  `${BASE_URL}/insurance-companies/products/wordings/${product_id}/${document_id}`;
exports.ADD_PRODUCT_WORD_DOCUMENT_URL = (product_id) =>
  `${BASE_URL}/insurance-companies/products/wordings/${product_id}`;
exports.UPDATE_PRODUCT_WORD_DOCUMENT_URL = (product_id) =>
  `${BASE_URL}/insurance-companies/products/wordings/${product_id}`;
exports.DELETE_PRODUCT_CLAUSE_DOCUMENT_URL = (product_id, document_id) =>
  `${BASE_URL}/insurance-companies/products/clause/${product_id}/${document_id}`;
exports.ADD_PRODUCT_CLAUSE_DOCUMENT_URL = (product_id) =>
  `${BASE_URL}/insurance-companies/products/clause/${product_id}`;
exports.UPDATE_PRODUCT_CLAUSE_DOCUMENT_URL = (product_id) =>
  `${BASE_URL}/insurance-companies/products/clause/${product_id}`;
exports.ADD_RATE_RISK_CATEGORY_URL = `${BASE_URL}/third-party-rates`;
exports.UPDATE_RATE_RISK_CATEGORY_URL = (id) =>
  `${BASE_URL}/third-party-basic-premiums/${id}`;
exports.CLIENT_VEHICLE_URL = `${BASE_URL}/vehicles`;
exports.VERIFY_MOBILE_NUMBER_URL = `${BASE_URL}/signup/check-user-phone`;
exports.VERIFY_EMAIL_ADDRESS_URL = `${BASE_URL}/signup/check-user`;
exports.UPLOAD_CLIENT_DOCUMENT_URL = `${BASE_URL}/uploads/clients`;
exports.UPLOAD_INSURANCE_DOCUMENT_URL = `${BASE_URL}/uploads/companies`;
exports.UPLOAD_GENERAL_DOCUMENT_URL = `${BASE_URL}/uploads/general`;
exports.ADD_INSURANCE_COMPANY_URL = `${BASE_URL}/insurance-companies`;
exports.ADD_GARAGE_COMPANY_URL = `${BASE_URL}/garage`;
exports.ADD_INSURANCE_COMPANY_URL = `${BASE_URL}/insurance-companies`;
exports.UPLOAD_PRODUCT_DOCUMENT_URL = `${BASE_URL}/uploads/companies-product`;
exports.GET_ALL_BANKS_URL = `${BASE_URL}/banks`;
exports.GET_RISK_CATEGORY_URL = `${BASE_URL}/risk-categories`;
exports.GET_BODY_TYPE_URL = `${BASE_URL}/body-types`;
exports.GET_GARI_CODE_URL = `${BASE_URL}/gari-codes`;
exports.SEARCH_CLIENT_URL = (query) => `${BASE_URL}/clients/search/${query}`;
exports.SEARCH_AGENT_URL = (query) => `${BASE_URL}/gari-agents/search/${query}`;
exports.CREATE_PRODUCT_URL = (id) =>
  `${BASE_URL}/insurance-companies/${id}/products`;
exports.UPDATE_PRODUCT_URL = (id) =>
  `${BASE_URL}/insurance-companies/products/${id}`;
exports.SEARCH_QUOTATION_URL = (query, id) =>
  `${BASE_URL}/insurance-companies/${id}/quotations/search/${query}`;
exports.SEARCH_CLIENT_VEHICLE_URL = (id, query) =>
  `${BASE_URL}/clients/${id}/vehicles/search/${query}`;
