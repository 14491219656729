import React, { useEffect, useState } from 'react';
import {
    getInsuranceProduct,
    addProductPeril,
    deleteProductPeril,
    updateProductPeril,
    deleteProductWordDocument,
    uploadProductDocument,
    addProductWordDocument,
    updateProductWordDocument,
    addProductClauseDocument,
    updateProductClauseDocument,
    deleteProductClauseDocument

} from '../../../request';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import 'select2';
import 'datatables.net';
import 'datatables.net-responsive';
import moment from "moment";
import cogoToast from "cogo-toast";
import products from "../../../data/products.json";
import {GET_INSURANCE_PRODUCT_URL} from "../../../api";
import {useSelector} from "react-redux";
import formatNumber from "../../../utils/formatNumber";

const ProductDetails = (props) => {

    document.title = "Product Details";

    const [loading, setLoading] = useState(true);
    const [perilsLoading, setPerilsLoading] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editId, setEditId] = useState(0);
    const [documentType, setDocumentType] = useState("wordings");
    const [perilsType, setPerilsType] = useState([]);
    const [perilsName, setPerilsName] = useState([]);
    const [documentLoading, setDocumentLoading] = useState(false);

    const {product} = useSelector((state) => state.organization.product);

    useEffect(() => {
        getInsuranceProduct(props.match.params.id).then((response) => {
            if(!response.error) {
                setLoading(false);
                // product = response.payload.client;

                let findData = products.find((data) => {
                    return data.name == response.payload.client.name;
                });

                let findProduct = product.find((data) => {
                     return data.id == props.match.params.id;
                });

                setPerilsType(findData.type);

                //Populate product detail
                $("#productName").text(response.payload.client.name);
                $("#name").text(response.payload.client.name);
                $("#code").text(response.payload.client.code);
                $("#description").text(response.payload.client.description);
                $("#policyNumber").text(response.payload.client.policyNumberPrefix);
                $("#claimNumber").text(response.payload.client.claimNumberPrefix);
                $("#gariCode").text(response.payload.client.gariCode !== null ? response.payload.client.gariCode.code : "Not Set");
                $("#lastUpdate").text(moment(response.payload.client.updatedAt).format("Do MMM, YYYY"));
                $("#dateCreated").text(moment(response.payload.client.createdAt).format("Do MMM, YYYY"));

                //Populate premium rate detail
                $("#premium_rate").text(formatNumber(findProduct.premiumRates.premium));
                $("#levy_rate").text(findProduct.premiumRates.levy);
                $("#minimum_rate").text(findProduct.premiumRates.minimumLimit);
                $("#maximum_rate").text(findProduct.premiumRates.maximumLimit);

                let table = $(`#td-perils`).DataTable({
                    responsive: true,
                    language: {
                        processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Perils Data</p>'
                    },
                    "searching": false,
                    "lengthMenu": [[10], [10]],
                    ordering: false,
                    info: false,
                    bFilter: false,
                    processing: false,
                    pageLength: 50,
                    serverSide: true,
                    ajax: function(data, callback) {
                        // make a regular ajax request using data.start and data.length
                        $.ajax(`${GET_INSURANCE_PRODUCT_URL(props.match.params.id)}`, {
                            type: 'GET',
                            data: {
                                pageNumber: (data.length + data.start) / data.length,
                                pageSize: data.length
                            },
                            success : function(res) {
                                let result = [];

                                res.client.perils.map((data) => {
                                    result.push({
                                        raw: data
                                    });
                                });

                                callback({
                                    recordsTotal: res.client.perils.length,
                                    recordsFiltered: res.client.perils.length,
                                    data: result
                                });
                            }
                        });
                    },
                    "columns": [
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"}
                    ],
                    "columnDefs": [
                        {
                            "render": function (data) {
                                return data.name;
                            },
                            "targets": 0
                        },
                        {
                            "render": function (data) {
                                return data.type;
                            },
                            "targets": 1
                        },
                        {
                            "render": function (data) {
                                return data.description;
                            },
                            "targets": 2
                        },
                        {
                            "render": function () {
                                return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_list"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                            },
                            "targets": 3
                        }
                    ]
                });

                $(`#td-perils`).on('click', '.delete_list', function(e) {
                    e.preventDefault();
                    let extract_td = table.row($(this).closest('tr')).data();

                    let {hide} = cogoToast.loading('Please wait... Deleting Peril.', {position: "top-right", hideAfter: 0});

                    deleteProductPeril(extract_td.raw.id).then((res) => {
                        hide();
                        if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                        cogoToast.success("Peril successfully removed.", {position: "top-right"});
                        $('#td-perils').DataTable().ajax.reload();
                    });
                });

                $(`#td-perils`).on('click', '.edit_list', function(e) {
                    e.preventDefault();
                    let extract_td = table.row($(this).closest('tr')).data();

                    $("#perilsForm").parsley();
                    $("#perilsDescription").val(extract_td.raw.description);
                    $("#perilsType").val(extract_td.raw.type);
                    handleShowPerils(true, extract_td.raw.id);

                });

                let td_wordings = $(`#td-wordings`).DataTable({
                    responsive: true,
                    language: {
                        processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Wordings Data</p>'
                    },
                    "searching": false,
                    "lengthMenu": [[10], [10]],
                    ordering: false,
                    info: false,
                    bFilter: false,
                    processing: false,
                    pageLength: 10,
                    serverSide: true,
                    ajax: function(data, callback) {
                        // make a regular ajax request using data.start and data.length
                        $.ajax(`${GET_INSURANCE_PRODUCT_URL(props.match.params.id)}`, {
                            type: 'GET',
                            data: {
                                pageNumber: (data.length + data.start) / data.length,
                                pageSize: data.length
                            },
                            success : function(res) {
                                let result = [];

                                res.client.policyWordings.map((data) => {
                                    result.push({
                                        raw: data
                                    });
                                });

                                callback({
                                    recordsTotal: res.client.policyWordings.length,
                                    recordsFiltered: res.client.policyWordings.length,
                                    data: result
                                });
                            }
                        });
                    },
                    "columns": [
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"}
                    ],
                    "columnDefs": [
                        {
                            "render": function (data) {
                                return `<a href=${data.documentUrl} target="_blank">View Document</a>`;
                            },
                            "targets": 0
                        },
                        {
                            "render": function (data) {
                                return data.description;
                            },
                            "targets": 1
                        },
                        {
                            "render": function () {
                                return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_list"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                            },
                            "targets": 2
                        }
                    ]
                });

                $(`#td-wordings`).on('click', '.delete_list', function(e) {
                    e.preventDefault();
                    let extract_td = td_wordings.row($(this).closest('tr')).data();

                    let {hide} = cogoToast.loading('Please wait... Deleting Document.', {position: "top-right", hideAfter: 0});

                    deleteProductWordDocument(props.match.params.id, extract_td.raw.id).then((res) => {
                        hide();
                        if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                        cogoToast.success("Document successfully removed.", {position: "top-right"});
                        $('#td-wordings').DataTable().ajax.reload();
                    });
                });

                $(`#td-wordings`).on('click', '.edit_list', function(e) {
                    e.preventDefault();
                    let extract_td = td_wordings.row($(this).closest('tr')).data();

                    $("#documentForm").parsley();
                    $("#documentDescription").val(extract_td.raw.description);
                    handleShowDocument(true, extract_td.raw.id, "wordings");

                });

                let td_clauses = $(`#td-clauses`).DataTable({
                    responsive: true,
                    language: {
                        processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Clauses Data</p>'
                    },
                    "searching": false,
                    "lengthMenu": [[10], [10]],
                    ordering: false,
                    info: false,
                    bFilter: false,
                    processing: false,
                    pageLength: 10,
                    serverSide: true,
                    ajax: function(data, callback) {
                        // make a regular ajax request using data.start and data.length
                        $.ajax(`${GET_INSURANCE_PRODUCT_URL(props.match.params.id)}`, {
                            type: 'GET',
                            data: {
                                pageNumber: (data.length + data.start) / data.length,
                                pageSize: data.length
                            },
                            success : function(res) {
                                let result = [];

                                res.client.policyClauses.map((data) => {
                                    result.push({
                                        raw: data
                                    });
                                });

                                callback({
                                    recordsTotal: res.client.policyClauses.length,
                                    recordsFiltered: res.client.policyClauses.length,
                                    data: result
                                });
                            }
                        });
                    },
                    "columns": [
                        {"data": "raw"},
                        {"data": "raw"},
                        {"data": "raw"}
                    ],
                    "columnDefs": [
                        {
                            "render": function (data) {
                                return `<a href=${data.documentUrl} target="_blank">View Document</a>`;
                            },
                            "targets": 0
                        },
                        {
                            "render": function (data) {
                                return data.description;
                            },
                            "targets": 1
                        },
                        {
                            "render": function () {
                                return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_list"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                            },
                            "targets": 2
                        }
                    ]
                });

                $(`#td-clauses`).on('click', '.delete_list', function(e) {
                    e.preventDefault();
                    let extract_td = td_clauses.row($(this).closest('tr')).data();

                    let {hide} = cogoToast.loading('Please wait... Deleting Document.', {position: "top-right", hideAfter: 0});

                    deleteProductClauseDocument(props.match.params.id, extract_td.raw.id).then((res) => {
                        hide();
                        if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                        cogoToast.success("Document successfully removed.", {position: "top-right"});
                        $('#td-clauses').DataTable().ajax.reload();
                    });
                });

                $(`#td-clauses`).on('click', '.edit_list', function(e) {
                    e.preventDefault();
                    let extract_td = td_clauses.row($(this).closest('tr')).data();

                    $("#documentForm").parsley();
                    $("#documentDescription").val(extract_td.raw.description);
                    handleShowDocument(true, extract_td.raw.id, "clauses");

                });
            }
        });
    }, []);

    const handleShowPerils = (state, id) => {
        setEditForm(state);
        setEditId(id);
        $("#perilsForm").parsley();
        $("#modalAddPeril").modal({backdrop: 'static', keyboard: false}, "show");
    };

    const handleShowDocument = (state, id, type) => {
        setEditForm(state);
        setEditId(id);
        setDocumentType(type);
        $(`#documentForm`).parsley();
        $("#modalShowDocument").modal({backdrop: 'static', keyboard: false}, "show");
    }

    const onPerilsTypeChange = (e) => {
        e.preventDefault();
        let findData = perilsType.find((data) => {
            return data.name === e.target.value;
        });

        $('#perilsName').prop('disabled', false);

        setPerilsName(findData.data);
    };

    const handlePerilsSubmit = (e) => {
        e.preventDefault();

        setPerilsLoading(true);

        let raw = {
            "name": $("#perilsName").val(),
            "description": $("#perilsDescription").val(),
            "type": $("#perilsType").val()
        }

        if(editForm) {
            updateProductPeril(editId).then(async(res) => {
                setPerilsLoading(false);
                if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                onCloseModal("modalAddPeril", "perilsForm");
                $('#td-perils').DataTable().ajax.reload();
            });
            return false;
        }

        addProductPeril(props.match.params.id, raw).then(async(res) => {
            setPerilsLoading(false);
            if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
            onCloseModal("modalAddPeril", "perilsForm");
            $('#td-perils').DataTable().ajax.reload();
        });
    };

    const handleUploadDocumentSubmit = async(e) => {
        e.preventDefault();
        setDocumentLoading(true);

        if(documentType === "wordings") {
            if(editForm) {
                let raw = {
                    id: editId,
                    documentUrl: await uploadDocument("documentFile"),
                    description: $("#documentDescription").val()
                }
                updateProductWordDocument(props.match.params.id, raw).then((res) => {
                    setDocumentLoading(false);
                    if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                    cogoToast.success("Document successfully added.", {position: "top-right"});
                    onCloseModal("modalShowDocument", "documentForm");
                    $('#td-wordings').DataTable().ajax.reload();
                });
            } else {
                let raw = {
                    documentUrl: await uploadDocument("documentFile"),
                    description: $("#documentDescription").val()
                }
                addProductWordDocument(props.match.params.id, raw).then((res) => {
                    setDocumentLoading(false);
                    if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                    cogoToast.success("Document successfully added.", {position: "top-right"});
                    onCloseModal("modalShowDocument", "documentForm");
                    $('#td-wordings').DataTable().ajax.reload();
                });
            }
        } else {
            if(editForm) {
                let raw = {
                    id: editId,
                    documentUrl: await uploadDocument("documentFile"),
                    description: $("#documentDescription").val()
                }
                updateProductClauseDocument(props.match.params.id, raw).then((res) => {
                    setDocumentLoading(false);
                    if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                    cogoToast.success("Document successfully added.", {position: "top-right"});
                    onCloseModal("modalShowDocument", "documentForm");
                    $('#td-clauses').DataTable().ajax.reload();
                });
            } else {
                let raw = {
                    documentUrl: await uploadDocument("documentFile"),
                    description: $("#documentDescription").val()
                }
                addProductClauseDocument(props.match.params.id, raw).then((res) => {
                    setDocumentLoading(false);
                    if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});
                    cogoToast.success("Document successfully added.", {position: "top-right"});
                    onCloseModal("modalShowDocument", "documentForm");
                    $('#td-clauses').DataTable().ajax.reload();
                });
            }
        }
    };

    const uploadDocument = (id) => {
        let [files] = $(`#${id}`).prop('files');

        if(files === undefined) return null;

        const formData = new FormData();

        formData.append('File', files);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            encType: "multipart/form-data"
        };

        return uploadProductDocument(formData, config).then((result) => {
            if(!result.error) {
                return result.payload.url;
            }

            return null;
        });
    }

    const onCloseModal = (id, form) => {
        let parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    if(!loading) {
        return (
            <div className="content-body">
                <div className="container pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Product</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Product Details</li>
                                </ol>
                            </nav>
                            <h4 id="productName" className="mg-b-0 tx-spacing--1">---</h4>
                        </div>
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#product" className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> Product</a></li>
                                        <li className="nav-item"><a href="#premium" className="nav-link" data-toggle="tab"><i className="ion-md-analytics"/> Premium Rates</a></li>
                                        <li className="nav-item"><a href="#perils" className="nav-link" data-toggle="tab"><i className="ion-ios-checkmark-circle"/> Perils</a></li>
                                        <li className="nav-item"><a href="#wordings" className="nav-link" data-toggle="tab"><i className="ion-ios-checkmark-circle"/> Policy Wordings</a></li>
                                        <li className="nav-item"><a href="#clauses" className="nav-link" data-toggle="tab"><i className="ion-ios-checkmark-circle"/> Policy Clauses</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="product" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Name:</strong> <span id="name">Blank</span></p>
                                            <p><strong>Code:</strong> <span id="code">Blank</span></p>
                                            <p><strong>Description:</strong> <span id="description">Blank</span></p>
                                            <p><strong>policy Number Prefix:</strong> <span id="policyNumber">Blank</span></p>
                                            <p><strong>claim Number Prefix:</strong> <span id="claimNumber">Blank</span></p>
                                            <p><strong>Gari Code:</strong> <span id="gariCode">Blank</span></p>
                                            <p><strong>Last Update:</strong> <span id="lastUpdate">Blank</span></p>
                                            <p><strong>Date Created:</strong> <span id="dateCreated">Blank</span></p>
                                        </div>
                                    </div>
                                </div>

                                <div id="premium" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Premium Rate:</strong> <span id="premium_rate">Blank</span>%</p>
                                            <p><strong>Levy Rate:</strong> <span id="levy_rate">Blank</span>%</p>
                                            <p><strong>Minimum Limit Rate:</strong> <span id="minimum_rate">Blank</span>%</p>
                                            <p><strong>Maximum Limit Rate:</strong> <span id="maximum_rate">Blank</span>%</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="perils" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Perils</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0" onClick={handleShowPerils.bind(null, false, 0)}><i className="icon ion-md-add"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#td_perils').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <table id="td-perils" className="table" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p-f">Name</th>
                                                            <th className="wd-20p-f">Type</th>
                                                            <th className="wd-40p-f">Description</th>
                                                            <th className="wd-20p-f">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="modalAddPeril" tabIndex="-1" role="dialog" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </a>
                                                    <div className="media-body"><a href="javascript:void(0)" role="button" className="close pos-absolute t-15 r-15" onClick={onCloseModal.bind(null, "modalAddPeril", "perilsForm")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Update Peril" : "Create Peril"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Update" : "Create"} peril to be added to the system</p></div>
                                                </div>
                                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-20">
                                                    <form id="perilsForm" className="parsley-style-1 col-md-12" onSubmit={handlePerilsSubmit.bind()}>
                                                        <div className="row row-sm">
                                                            <div id="typeWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Type<span className="tx-danger">*</span></label>
                                                                <select className="custom-select" id="perilsType" onChange={onPerilsTypeChange.bind()} required>
                                                                    <option value="" disabled selected>Select</option>
                                                                    {perilsType.map((data, index) => <option key={index} value={data.name}>{data.name}</option>)}
                                                                </select>
                                                            </div>

                                                            <div id="perilsWrapper" className="form-group parsley-input col-sm-12">
                                                                <label>Name<span className="tx-danger">*</span></label>
                                                                <select className="custom-select" id="perilsName" required>
                                                                    <option value="" disabled selected>Select</option>
                                                                    {perilsName.map((data, index) => <option key={index} value={data}>{data}</option>)}
                                                                </select>
                                                            </div>

                                                            <div id="perilsDescriptionWrapper" className="form-group parsley-input col-sm-12">
                                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description<span className="tx-danger">*</span></label>
                                                                <textarea id="perilsDescription" rows="5" className="form-control" placeholder="Provide a description of the perils" data-parsley-class-handler="#perilsDescriptionWrapper" required/>
                                                            </div>

                                                            <div className="col-md-12 text-center">
                                                                {perilsLoading ? <button disabled className="btn btn-brand-02"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-01 btnNextFront" onClick={handlePerilsSubmit.bind()}>{editForm ? "Update Peril" : "Add Peril"}</button>}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="wordings" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Wordings</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0" onClick={handleShowDocument.bind(null, false, 0, "wordings")}><i className="icon ion-md-add"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#transaction_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <table id="td-wordings" className="table" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-30p">Document</th>
                                                            <th className="wd-50p-f">Description</th>
                                                            <th className="wd-20p-f">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="clauses" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Clauses</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0" onClick={handleShowDocument.bind(null, false, 0, "clauses")}><i className="icon ion-md-add"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#transaction_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <table id="td-clauses" className="table" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-30p">Document</th>
                                                            <th className="wd-50p-f">Description</th>
                                                            <th className="wd-20p-f">Actions</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="modalShowDocument" tabIndex="-1" role="dialog" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </a>
                                                <div className="media-body"><a href="javascript:void(0)" role="button" className="close pos-absolute t-15 r-15" onClick={onCloseModal.bind(null, "modalShowDocument", "documentForm")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Update" : "Create"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Update" : "Create"} document to be added to the system</p></div>
                                            </div>
                                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-20">
                                                <form id="documentForm" className="parsley-style-1 col-md-12" onSubmit={handleUploadDocumentSubmit.bind()}>
                                                    <div className="row row-sm">
                                                        <div id="fileWrapper" className="form-group parsley-input col-sm-12">
                                                            <label>Upload Document File<span className="tx-danger">*</span></label>
                                                            <input id="documentFile" className="form-control"
                                                                   placeholder="Select"
                                                                   type="file"
                                                                   accept="application/pdf"
                                                                   data-parsley-class-handler="#fileWrapper" required/>
                                                        </div>

                                                        <div id="wordingDescriptionWrapper" className="form-group parsley-input col-sm-12">
                                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description<span className="tx-danger">*</span></label>
                                                            <textarea id="documentDescription" rows="5" className="form-control" placeholder="Provide a description of the perils" data-parsley-class-handler="#wordingDescriptionWrapper" required/>
                                                        </div>

                                                        <div className="col-md-12 text-center">
                                                            {documentLoading ? <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-01 btnNextFront btn-block">{editForm ? "Update" : "Add"}</button>}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default ProductDetails;
