import React from 'react';

const A4 = (props) => {

    document.title = "Receipt";

    return (
        <div>
            <h5>Full Receipt</h5>
        </div>
    )
};

export default A4;
