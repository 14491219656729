import React, { useEffect, useState } from 'react';
import {GET_THIRD_PARTY_RATE} from '../../../api';
import {addRateToRiskCategory, updateRateToRiskCategory} from '../../../request';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import cogoToast from "cogo-toast";

const RiskCategoryDetails = (props) => {

    document.title = "Risk Category Lists";

    const {id} = useSelector((state) => state.auth.user.user);
    const {riskCategory} = useSelector((state) => state.organization.riskCategory);
    const [risk_category, set_risk_category] = useState({});
    const [premiumList, setPremiumList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(0);

    const {product} = useSelector((state) => state.organization.product);

    const dispatch = useDispatch();

    useEffect(() => {
        set_risk_category(riskCategory.find((data) => {return data.id == props.match.params.id}));

        var table = $('#risk_category').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Product Data</p>'
            },
            "searching": false,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_THIRD_PARTY_RATE(props.match.params.id), {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        let products = res.filter((data) => {
                            return data.riskCategoryId == props.match.params.id && data.product.insuranceCompanyId == id;
                        });

                        products.map((data) => {
                            result.push({
                                raw: {
                                    data: data,
                                    products: products
                                }
                            });
                        });

                        callback({
                            recordsTotal: products.length,
                            recordsFiltered: products.length,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${data.data.product.name} ${data.data.thirdPartyBasicPremium.quarter === 1 ? `<span class="badge badge-success tx-white">${data.data.thirdPartyBasicPremium.quarter} Quarter</span>` : `<span class="badge badge-success tx-white">${data.data.thirdPartyBasicPremium.quarter} Quarters</span>`}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `K${data.data.thirdPartyBasicPremium.premium}`
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return moment(data.data.product.updatedAt).format("Do MMM, YYYY");
                    },
                    "targets": 2
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_list"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_list"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 3
                }
            ]
        });

        $(`#risk_category`).on('click', '.edit_list', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $('#basicRate').parsley();

            setEdit(true);
            setEditId(extract_td.raw.data.id);
            $("#product").val(extract_td.raw.data.product.id).prop('disabled', true);
            $("#name").val(extract_td.raw.data.product.name).prop('disabled', true);
            $("#description").val(extract_td.raw.data.product.description).prop('disabled', true);
            $("#quarter").val(extract_td.raw.data.thirdPartyBasicPremium.quarter).prop('disabled', true);
            $("#premium").val(extract_td.raw.data.thirdPartyBasicPremium.premium);

            $("#modalRate").modal({backdrop: 'static', keyboard: false}, "show");
        });

        $(`#risk_category`).on('click', '.delete_list', function(e) {
            e.preventDefault();
            cogoToast.error("Sorry, delete option is disabled.", {position: "top-right"});
        });

        $(`#risk_category`).on('click', '.view_list', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            setPremiumList(extract_td.raw.data.product);
            $(".name").text(extract_td.raw.data.product.name);
            $(".code").text(extract_td.raw.data.product.code);
            $("#description").text(extract_td.raw.data.product.description);
            $("#policyNumberPrefix").text(extract_td.raw.data.product.policyNumberPrefix);
            $("#claimNumberPrefix").text(extract_td.raw.data.product.claimNumberPrefix);
            $("#modalViewRate").modal("show");

            $('.list-group-item').remove();

            extract_td.raw.products.filter((data) => data.product.id == extract_td.raw.data.product.id).map((data, index) => {
                $(".list-group").append("<li class=\"list-group-item d-flex pd-sm-x-20\">\n" +
                    "                                                    <div class=\"avatar d-none d-sm-block\"><span class=\"avatar-initial rounded-circle bg-success\"><i class=\"icon ion-md-checkmark\"></i></span></div>\n" +
                    "                                                    <div class=\"pd-sm-l-10\">\n" +
                    "                                                        <p class=\"tx-medium mg-b-0\">"+ (index+1) +" Quarter"+(index > 0 ? "s" : "")+"</p>\n" +
                    "                                                        <small class=\"tx-12 tx-color-03 mg-b-0\">"+moment(data.thirdPartyBasicPremium.updatedAt).format("Do MMM, YYYY")+"</small>\n" +
                    "                                                    </div>\n" +
                    "                                                    <div class=\"mg-l-auto text-right\">\n" +
                    "                                                        <p class=\"tx-medium mg-b-0\">ZMW" + data.thirdPartyBasicPremium.premium + "</p>\n" +
                    "                                                        <small class=\"tx-12 tx-success mg-b-0\">Active</small>\n" +
                    "                                                    </div>\n" +
                    "                                                </li>");
            });
        });
    }, []);

    const onPremiumRateSubmit = (e) => {
        e.preventDefault();

        let raw = {
            "productId": $("#product").val(),
            "riskCategoryId": props.match.params.id,
            "name": $("#name").val(),
            "description": $("#description").val(),
            "quarter": $("#quarter").val(),
            "premium": $("#premium").val()
        }

        setLoading(true);

        if(edit) {
            let edit_raw = {
                "id": editId,
                "quarter": $("#quarter").val(),
                "premium": $("#premium").val()
            }

            updateRateToRiskCategory(edit_raw).then(async(res) => {
                setLoading(false);
                if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});

                cogoToast.success("Premium Rate successfully created.", {position: "top-right"});
                onCloseModal("modalRate", "basicRate");
                $('#risk_category').DataTable().ajax.reload();
            });
            return false;
        }

        addRateToRiskCategory(raw).then(async(res) => {
            setLoading(false);
            if(res.error) return cogoToast.error("Something went wrong.", {position: "top-right"});

            cogoToast.success("Premium Rate successfully created.", {position: "top-right"});
            onCloseModal("modalRate", "basicRate");
            $('#risk_category').DataTable().ajax.reload();
        });
    };

    const onCloseModal = (id, form) => {
        let parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false)
            .prop('disabled', false)
        parsley.reset();
        parsley.destroy();
        $(`#${id}`).modal("hide");
        $(".modal-backdrop").remove();
    };

    const handleShowRate = (e) => {
        e.preventDefault();
        setEdit(false);
        $('#basicRate').parsley();
        $("#modalRate").modal({backdrop: 'static', keyboard: false}, "show");
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item">Risk Category</li>
                                <li className="breadcrumb-item active" aria-current="page">Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div className="media">
                        <div className="wd-100 ht-100 bg-ui-04 bg-white rounded d-flex align-items-center justify-content-center">
                            <a href="#modalShowCover" data-toggle="modal" data-animation="effect-scale">
                                <img src={risk_category.iconUrl} className="img-fluid rounded-5"/>
                            </a>
                        </div>
                        <div className="media-body pd-l-25 my-auto">
                            <h5 className="mg-b-5">Risk Category</h5>
                            <p className="mg-b-3"><span className="tx-medium tx-color-02">{risk_category.name}</span></p>
                            <span className="d-block tx-13 tx-color-03">{risk_category.description}</span>
                        </div>
                    </div>
                    <div className="d-none d-md-block my-auto">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowRate.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add Rate</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">{risk_category.name}</h6>
                                <div className="d-flex tx-18">
                                    <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="risk_category" className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Rate</th>
                                        <th className="wd-20p">Date</th>
                                        <th className="wd-20p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalRate" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="javascript:void(0)" role="button" className="close pos-absolute t-15 r-15" onClick={onCloseModal.bind(null, "modalRate", "basicRate")}><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Premium Rate</h4><p className="tx-13 tx-color-02 mg-b-0">Fill the information below.</p></div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="basicRate" className="parsley-style-1" onSubmit={onPremiumRateSubmit.bind()}>
                                    <div className="row row-sm">
                                        <div id="riskWrapper" className="form-group parsley-input col-sm-12">
                                            <label>Product<span className="tx-danger">*</span></label>
                                            <select className="custom-select" id="product" required>
                                                <option value="" disabled selected>Select</option>
                                                {product.map((data) => <option value={data.id}>{data.name}</option>)}
                                            </select>
                                        </div>

                                        <div id="nameWrapper" className="form-group parsley-input col-sm-12">
                                            <label>Name<span className="tx-danger">*</span></label>
                                            <input id="name" className="form-control"
                                                   placeholder="Enter name"
                                                   type="text"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="descriptionWrapper" className="form-group parsley-input col-sm-12">
                                            <label>Description<span className="tx-danger">*</span></label>
                                            <input id="description" className="form-control"
                                                   placeholder="Enter description"
                                                   type="text"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#descriptionWrapper" required/>
                                        </div>

                                        <div id="quarterWrapper" className="form-group parsley-input col-sm-12">
                                            <label>Quarter<span className="tx-danger">*</span></label>
                                            <select className="custom-select" id="quarter" required>
                                                <option value="" disabled selected>Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                        </div>

                                        <div id="premiumWrapper" className="form-group parsley-input col-sm-12">
                                            <label>Premium<span className="tx-danger">*</span></label>
                                            <input id="premium" className="form-control"
                                                   placeholder="Enter premium"
                                                   type="number"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#premiumWrapper" required/>
                                        </div>
                                    </div>
                                    {loading ? <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-02 btn-block mt-2"><i className="ion-md-add"/> {edit ? "Update" : "Add"}</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalViewRate" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2 name">Not Set</h4><p className="tx-13 tx-color-02 mg-b-0">Product Code: <span className="code">Not Set</span></p></div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="row row-sm">
                                    <div className="col-md-12">
                                        <p><strong>Name:</strong> <span className="name">Not Set</span></p>
                                        <p><strong>Code:</strong> <span className="code">Not Set</span></p>
                                        <p><strong>Description:</strong> <span id="description">Not Set</span></p>
                                        <p><strong>Policy Number Prefix:</strong> <span id="policyNumberPrefix">Not Set</span></p>
                                        <p><strong>claim Number Prefix:</strong> <span id="claimNumberPrefix">Not Set</span></p>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="card ht-100p">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <h6 className="mg-b-0">Premium Rate List</h6>
                                            </div>
                                            <ul className="list-group list-group-flush tx-13"></ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RiskCategoryDetails;
