import React, { useEffect, useState } from "react";
import { GET_ALL_INSURANCE_URL } from "../../../api";
import {
  getOrganization,
  uploadGeneralDocument,
  actionInsuranceCompany,
} from "../../../request";
import "datatables.net";
import "datatables.net-responsive";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { encodeQueryParameter } from "../../../utils/url";
import cogoToast from "cogo-toast";

const Insurance = (props) => {
  document.title = "Insurance";

  const [branch, setBranch] = useState([]);
  const [loading, setLoading] = useState(false);

  const { bank } = useSelector((state) => state.organization.bank);

  const { id } = useSelector((state) => state.auth.user.user);
  const { accountType } = useSelector(
    (state) => state.organization.accountType
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganization(id));

    var table = $("#insurance").DataTable({
      responsive: true,
      language: {
        processing:
          '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Insurance Data</p>',
      },
      searching: false,
      lengthMenu: [[10], [10]],
      ordering: false,
      info: true,
      bFilter: false,
      processing: true,
      pageLength: 10,
      serverSide: true,
      ajax: function (data, callback) {
        // make a regular ajax request using data.start and data.length
        $.ajax(`${GET_ALL_INSURANCE_URL}`, {
          type: "GET",
          data: {
            pageNumber: (data.length + data.start) / data.length,
            pageSize: data.length,
          },
          success: function (res) {
            let result = [];

            res.data.map((data) => {
              result.push({
                raw: data,
              });
            });

            callback({
              recordsTotal: res.totalRecords,
              recordsFiltered: res.totalRecords,
              data: result,
            });
          },
        });
      },
      columns: [
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
        { data: "raw" },
      ],
      columnDefs: [
        {
          render: function (data) {
            return data.name;
          },
          targets: 0,
        },
        {
          render: function (data) {
            return data.companyEmail;
          },
          targets: 1,
        },
        {
          render: function (data) {
            return data.registrationNumber;
          },
          targets: 2,
        },
        {
          render: function (data) {
            return data.tpin;
          },
          targets: 3,
        },
        {
          render: function (data) {
            return {
              Active: `<span class="badge badge-success tx-white">${data.status}</span>`,
              Suspended: `<span class="badge badge-warning tx-white">${data.status}</span>`,
              Deactivated: `<span class="badge badge-danger">${data.status}</span>`,
            }[data.status];
          },
          targets: 4,
        },
      ],
    });

    $("#insurance tbody").on("click", "tr", function () {
      var extract_data = table.row(this).data();
      props.history.push(
        `/insurance/${encodeQueryParameter(extract_data.raw)}`
      );
    });
  }, []);

  const handleAddInsuranceModal = () => {
    $("#insuranceForm").parsley();
    $("#modalInsurance").modal("show");
  };

  const onBankChange = (e) => {
    let findBank = bank.find((data) => {
      return e.target.value == data.id;
    });

    findBank.branches.length
      ? $("#bankBranch").prop("disabled", false)
      : $("#bankBranch").prop("disabled", true);
    setBranch(findBank.branches);
  };

  const onBranchChange = (e) => {
    let filteredBranch = branch.find((data) => {
      return data.id == e.target.value;
    });
    $("#branchCode").val(filteredBranch.code);
  };

  const handleInsuranceSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let payload = {
      name: $("#name").val(),
      companyEmail: $("#email").val(),
      companyAddress: $("#address").val(),
      registrationNumber: $("#regNumber").val(),
      tpin: $("#tpin").val(),
      logoUrl: await uploadDocument("Logo"),
      account: {
        accountName: $("#accountName").val(),
        accountNumber: $("#accountNumber").val(),
        accountTypeId: $("#accountType").val(),
        branchId: $("#bankBranch").val(),
      },
    };

    actionInsuranceCompany(payload, "post").then((result) => {
      setLoading(false);
      if (result.error) {
        return cogoToast.error("Something went wrong");
      }

      handleResetForm("modalInsurance", "insuranceForm");
      $("#insurance").DataTable().ajax.reload();
    });
  };

  const uploadDocument = (type) => {
    let [files] = $(`#logo`).prop("files");

    if (files === undefined) return null;

    const formData = new FormData();

    formData.append("Description", `${type}`);
    formData.append("File", files);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      encType: "multipart/form-data",
    };

    return uploadGeneralDocument(formData, config).then((result) => {
      if (!result.error) {
        return result.payload.documentUrl;
      }

      return null;
    });
  };

  const handleResetForm = (id, form) => {
    let parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    parsley.reset();
    parsley.destroy();
    $(`#${id}`).modal("hide");
  };

  return (
    <div className="content-body">
      <div className="container pd-x-0">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                <li className="breadcrumb-item">
                  <a href="#">Menu</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Insurance
                </li>
              </ol>
            </nav>
            <h4 className="mg-b-0 tx-spacing--1">Insurance</h4>
          </div>
          <div className="d-none d-md-block">
            <button
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
              onClick={handleAddInsuranceModal.bind()}
            >
              <i className="wd-10 mg-r-5 fa fa-plus"></i> Add New
            </button>
          </div>
        </div>

        <div className="row row-xs">
          <div className="col-md-12 mg-t-10">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="mg-b-0">Insurance</h6>
                <div className="d-flex tx-18">
                  <a href="" className="link-03 lh-0">
                    <i className="icon ion-md-refresh"></i>
                  </a>
                </div>
              </div>
              <div className="card-body table-responsive">
                <table id="insurance" className="table table-hover">
                  <thead>
                    <tr>
                      <th className="wd-20p">Name</th>
                      <th className="wd-20p">Email</th>
                      <th className="wd-20p">Registration</th>
                      <th className="wd-20p">TPIN</th>
                      <th className="wd-20p">Status</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalInsurance"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href="javascript:void(0)"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    onClick={handleResetForm.bind(
                      null,
                      "modalInsurance",
                      "insuranceForm"
                    )}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">Insurance Form</h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Fill all the information below.
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="insuranceForm"
                  className="row parsley-style-1"
                  onSubmit={handleInsuranceSubmit.bind()}
                >
                  <div
                    id="companyNameWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Company Name<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="name"
                      className="form-control"
                      placeholder="Enter company name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#companyNameWrapper"
                      required
                    />
                  </div>

                  <div
                    id="companyEmailWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>Company Email</label>
                    <input
                      id="email"
                      className="form-control"
                      placeholder="Enter company email"
                      type="email"
                      autoComplete="off"
                      data-parsley-class-handler="#companyEmailWrapper"
                      required
                    />
                  </div>

                  <div
                    id="companyAddressWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>Company Address</label>
                    <input
                      id="address"
                      className="form-control"
                      placeholder="Enter company address"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#companyAddressWrapper"
                      required
                    />
                  </div>

                  <div
                    id="registrationNumberWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      Registration Number<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="regNumber"
                      className="form-control"
                      placeholder="Enter last name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#registrationNumberWrapper"
                      required
                    />
                  </div>

                  <div
                    id="tpinWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      TPIN<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="tpin"
                      className="form-control"
                      placeholder="Enter tpin number"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#tpinWrapper"
                      required
                    />
                  </div>

                  <div
                    id="logoWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>Upload Logo</label>
                    <input
                      id="logo"
                      className="form-control"
                      placeholder="Select"
                      type="file"
                      autoComplete="off"
                      data-parsley-class-handler="#logoWrapper"
                      required
                    />
                  </div>

                  <hr />

                  <div className="col-md-12 mg-t-30">
                    <h5>Bank Account</h5>
                  </div>

                  <div
                    id="accountTypeWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Account Type<span className="tx-danger">*</span>
                    </label>
                    <select className="custom-select" id="accountType" required>
                      <option value="" disabled selected>
                        Select
                      </option>
                      {accountType.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    id="bankNameWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      Bank Name<span className="tx-danger">*</span>
                    </label>
                    <select
                      className="custom-select"
                      id="bankName"
                      onChange={onBankChange.bind()}
                      required
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      {bank.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    id="bankBranchWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      Bank Branch<span className="tx-danger">*</span>
                    </label>
                    <select
                      className="custom-select"
                      id="bankBranch"
                      onChange={onBranchChange.bind()}
                      required
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      {branch.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    id="accountNameWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Account Name<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="accountName"
                      className="form-control"
                      placeholder="Enter account name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#accountNameWrapper"
                      required
                    />
                  </div>

                  <div
                    id="accountNumberWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>
                      Account Number<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="accountNumber"
                      className="form-control"
                      placeholder="Enter account number"
                      type="number"
                      autoComplete="off"
                      data-parsley-class-handler="#accountNumberWrapper"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    {loading ? (
                      <button disabled className="btn btn-brand-02 float-right">
                        <span
                          className="spinner-border spinner-border-sm mg-r-10"
                          role="status"
                          aria-hidden="true"
                        />
                        Please Wait
                      </button>
                    ) : (
                      <button className="btn btn-brand-01 btnNextFront btn-block">
                        Add Insurance
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
