import React, { useEffect } from 'react';
import {GET_CLAIMS_URL} from 'api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import moment from "moment";
import {encodeQueryParameter} from "utils/url";

const Claim = (props) => {

    document.title = "Claims";

    useEffect(() => {
        let table = $('#claims').DataTable({
            responsive: true,
            language: {
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Claim Data</p>'
            },
            searching: false,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(`${GET_CLAIMS_URL}`, {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        res.data.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.totalRecords,
                            recordsFiltered: res.totalRecords,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.claimNumber;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.lossEstimate;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.claimType;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.createdAt).add(1,'days').format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return {
                            "Approved" : `<span class="badge badge-success tx-white">${data.claimStatus}</span>`,
                            "Resolved" : `<span class="badge badge-success tx-white">${data.claimStatus}</span>`,
                            "Pending" : `<span class="badge badge-warning tx-white">${data.claimStatus}</span>`,
                            "Rejected" : `<span class="badge badge-danger">${data.claimStatus}</span>`,
                            "Cancelled" : `<span class="badge badge-danger">${data.claimStatus}</span>`
                        }[data.claimStatus];
                    },
                    "targets": 4
                }
            ]
        });

        $('#claims tbody').on('click', 'tr', function () {
            var extract_data = table.row(this).data();
            props.history.push(`/claim-details/${encodeQueryParameter(extract_data.raw)}`);
        } );

    }, []);

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Claims</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Claims</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Policies</h6>
                                <div className="d-flex tx-18">
                                    <a href="javascript:void(0)" onClick={() => $('#claims').DataTable().ajax.reload()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="claims" className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Claim #</th>
                                        <th className="wd-20p">Estimate Loss</th>
                                        <th className="wd-20p">Type</th>
                                        <th className="wd-20p">Date</th>
                                        <th className="wd-20p">Status</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Claim;
