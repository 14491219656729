import React, { useEffect, useState } from "react";
import { LEADS_URL } from "api";

import "datatables.net";
import "datatables.net-responsive";
import $ from "jquery";
import cogoToast from "cogo-toast";
import { gariCodes } from "data/gariCodes";
import { createLead, createLeadActivity } from "request/leads";
import { getClientStats } from "request/client";

import { useHistory } from "react-router-dom";
const Leads = () => {
  document.title = "Leads";
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [leadActivityLoading, setLeadActivityLoading] = useState(false);

  const [editLead, setEditLead] = useState(false);
  const [leadId, setLeadId] = useState(null);

  const selectedLead = (id) => {
    setLeadId(id);

    console.log("selected lead", id);
  };
  useEffect(() => {
    getClientStats("leads").then((stats) => {
      $("#totalLeads").text(stats.payload.total);
      $("#dailyTotal").text(stats.payload.dailyTotal);
      $("#monthlyTotal").text(stats.payload.monthlyTotal);
      $("#yearlyTotal").text(stats.payload.yearlyTotal);
    });
    let table = $("#leads").DataTable({
      responsive: true,
      destroy: true,

      searching: false,
      lengthMenu: [[10], [10]],
      ordering: false,
      info: true,
      bFilter: false,
      processing: true,
      pageLength: 10,
      serverSide: true,
      ajax: function (data, callback) {
        // make a regular ajax request using data.start and data.length
        $.ajax(`${LEADS_URL}`, {
          type: "GET",
          data: {
            pageNumber: (data.length + data.start) / data.length,
            pageSize: data.length,
          },
          success: function (res) {
            let result = [];

            res.data.map((data) => {
              result.push({
                raw: data,
              });
            });

            callback({
              recordsTotal: res.totalRecords,
              recordsFiltered: res.totalRecords,
              data: result,
            });
          },
        });
      },
      columns: [{ data: "raw" }, { data: "raw" }, { data: "raw" }],
      columnDefs: [
        {
          render: function (data) {
            return `${data.firstName} ${data.lastName}`;
          },
          targets: 0,
        },

        {
          render: function (data) {
            return data.phoneNumber;
          },
          targets: 1,
        },
        {
          render: function (data) {
            return data.email;
          },
          targets: 2,
        },
      ],
    });
    $("#leads tbody").on("click", ".lead_activity", function () {
      let extract_td = table.row($(this).closest("tr")).data();
      setLeadId(extract_td.raw.id);
    });
    $("#leads tbody").on("click", "tr", function () {
      let extract_td = table.row($(this).closest("tr")).data();
      console.log("table row data", extract_td.raw.id);

      history.push(`/leads-details/${extract_td.raw.id}`);
    });

    return () => {
      $("#endData").datepicker("destroy");
    };
  }, []);

  $(function () {
    $("#date_from").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: new Date().getDate(),
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });

  $(function () {
    $("#date_to_activity").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: new Date().getDate(),
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });
  $(function () {
    $("#date_from_activity").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: new Date().getDate(),
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });
  $(function () {
    $("#follow_up_date_from_activity").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: new Date().getDate(),
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });
  const onSubmitNewLead = (e) => {
    e.preventDefault();
    let newLead = {
      firstName: $("#firstName").val(),
      lastName: $("#lastName").val(),
      email: $("#email").val(),
      phoneNumber: $("#phoneNumber").val(),
      leadInsuranceCompanyDetails: {
        companyName: $("#insuranceName").val(),
        premiumPaid: $("#premium").val() ? $("#premium").val() : 0,
        policyEndDate: new Date($("#date_from").val()).toISOString,
        gariCodeId: $("#product").val(),
      },
      leadVehicleDetails: {
        make: $("#make").val(),
        model: $("#model").val(),
        value: $("#estimate_value").val() ? $("#estimate_value").val() : 0,
        registrationNumber: $("#registrationNumber").val(),
      },
    };

    setLoading(true);

    createLead(newLead).then((res) => {
      const options = {
        position: "top-right",
      };

      setLoading(false);

      if (res.error) return cogoToast.error(res.payload, options);

      onCloseModal("addLeadsModal", "leadsForm");
      $("#leads").DataTable().ajax.reload();

      cogoToast.success("lead successfully Added.", options);
    });
  };

  const onSubmitNewLeadActivity = (e) => {
    e.preventDefault();
    let leadActivity = {
      name: $("#ActivityName").val(),
      description: $("#description").val(),
      activityType: $("#activityType").val(),
      fromDate: new Date($("#date_from_activity").val()).toISOString,
      toDate: new Date($("#date_to_activity").val()).toISOString,
      location: $("#location").val(),
      followUpActivityDto: {
        activityType: $("#followUpActivityType").val(),
        fromDate: new Date($("#follow_up_date_from_activity").val())
          .toISOString,
      },
    };
    setLeadActivityLoading(true);
    createLeadActivity(leadActivity, leadId).then((res) => {
      const options = {
        position: "top-right",
      };

      setLeadActivityLoading(false);

      if (res.error) return cogoToast.error(res.payload, options);

      onCloseModal("addLeadActivityModal", "leadActivityForm");
      $("#leads").DataTable().ajax.reload();

      cogoToast.success("lead activity successfully Added.", options);
    });
  };

  const onCloseModal = (id, form) => {
    let parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    $(`#${id}`).modal("hide");
    parsley.reset();
    parsley.destroy();
    $(".modal-backdrop").remove();
    $("#nav_basic a:first").tab("show");
  };
  return (
    <div className="content-body">
      <div className="container pd-x-0">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                <li className="breadcrumb-item">
                  <a href="#">Menu</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Leads
                </li>
              </ol>
            </nav>
            <h4 className="mg-b-0 tx-spacing--1">Leads</h4>
          </div>

          <div className="d-none d-md-block">
            <button
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
              data-toggle="modal"
              data-target="#addLeadsModal"
            >
              <i className="wd-10 mg-r-5 fa fa-plus"></i> Add Lead
            </button>
          </div>
        </div>
        <div className="row row-xs">
          <div className="col-sm-6 col-lg-3">
            <div className="card card-body">
              <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                Total Leads
              </h6>
              <div className="d-flex d-lg-block d-xl-flex align-items-end">
                <h3
                  id="totalLeads"
                  className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                >
                  -
                </h3>
              </div>

              <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">
                {" "}
                <br />{" "}
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
            <div className="card card-body">
              <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                Today
              </h6>
              <div className="d-flex d-lg-block d-xl-flex align-items-end">
                <h3
                  id="dailyTotal"
                  className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                >
                  -
                </h3>
              </div>
              <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">
                <br />
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
            <div className="card card-body">
              <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                Monthly
              </h6>
              <div className="d-flex d-lg-block d-xl-flex align-items-end">
                <h3
                  id="monthlyTotal"
                  className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                >
                  -
                </h3>
              </div>

              <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">
                <br />
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
            <div className="card card-body">
              <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">
                yearly
              </h6>
              <div className="d-flex d-lg-block d-xl-flex align-items-end">
                <h3
                  id="yearlyTotal"
                  className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"
                ></h3>
              </div>

              <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">
                <br />
              </span>
            </div>
          </div>
          <div className="col-md-12 mg-t-10">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="mg-b-0">Leads</h6>
                <div className="d-flex tx-18">
                  <a
                    href="javascript:void(0)"
                    onClick={() => $("#leads").DataTable().ajax.reload()}
                    className="link-03 lh-0"
                  >
                    <i className="icon ion-md-refresh"></i>
                  </a>
                </div>
              </div>
              <div className="card-body table-responsive">
                <table id="leads" className="table table-hover">
                  <thead>
                    <tr>
                      <th className="wd-20p">Names</th>
                      <th className="wd-20p">Phone Number</th>
                      <th className="wd-20p">Email Address</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================lead Activity modal========================= */}
      <div
        className="modal fade"
        id="addLeadActivityModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href="javascript:void(0)"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  onClick={onCloseModal.bind(
                    null,
                    "addLeadActivityModal",
                    "leadsActivityForm"
                  )}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Lead Activity Form</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Fill all the information below.
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <form
                id="leadsActivityForm"
                className="row parsley-style-1"
                onSubmit={onSubmitNewLeadActivity.bind()}
              >
                <div
                  id="NameWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>Name</label>
                  <input
                    id="ActivityName"
                    className="form-control"
                    placeholder="Name"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#NameWrapper"
                    required
                  />
                </div>

                <div
                  id="activityTypeWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Activity Type<span className="tx-danger">*</span>
                  </label>
                  <select className="custom-select" id="activityType" required>
                    <option value="" disabled selected>
                      Select
                    </option>

                    <option value={"Call"}>Call</option>
                    <option value={"Message"}>Message</option>
                    <option value={"Email"}>Email</option>
                    <option value={"Meeting"}>Meeting</option>
                  </select>
                </div>
                <div
                  id="dateWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Activity Start Date<span className="tx-danger">*</span>
                  </label>

                  <input
                    type="text"
                    id="date_from_activity"
                    className="form-control"
                    placeholder="Select start  Date"
                    autoComplete="off"
                    data-parsley-class-handler="#dateWrapper"
                    readOnly
                  />
                </div>
                <div
                  id="dateWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Activity end Date<span className="tx-danger">*</span>
                  </label>

                  <input
                    type="text"
                    id="date_to_activity"
                    className="form-control"
                    placeholder="Select End  Date"
                    autoComplete="off"
                    data-parsley-class-handler="#dateWrapper"
                    required
                    readOnly
                  />
                </div>
                <div
                  id="descriptionWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>Description</label>
                  <textarea
                    id="description"
                    className="form-control"
                    type="textArea"
                    autoComplete="off"
                    data-parsley-class-handler="#descriptionWrapper"
                  />
                </div>
                <div
                  id="locationWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>Location</label>
                  <input
                    id="location"
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#locationWrapper"
                  />
                </div>
                <hr />

                <div className="col-md-12 mg-t-30">
                  <h5>Follow Up Engagement</h5>
                </div>
                <div
                  id="activityTypeWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Activity Type<span className="tx-danger"></span>
                  </label>
                  <select
                    className="custom-select"
                    id="followUpActivityType"
                    require
                    d
                  >
                    <option value="" disabled selected>
                      Select
                    </option>

                    <option value={"Call"}>Call</option>
                    <option value={"Message"}>Message</option>
                    <option value={"Email"}>Email</option>
                    <option value={"Meeting"}>Meeting</option>
                  </select>
                </div>
                <div
                  id="dateWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Follow up Date<span className="tx-danger">*</span>
                  </label>

                  <input
                    type="text"
                    id="follow_up_date_from_activity"
                    className="form-control"
                    placeholder="Select End  Date"
                    autoComplete="off"
                    data-parsley-class-handler="#dateWrapper"
                    required
                    readOnly
                  />
                </div>

                <div className="col-md-12">
                  {leadActivityLoading ? (
                    <button disabled className="btn btn-brand-02 float-right">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button className="btn btn-brand-01 btnNextFront btn-block">
                      Add Activity
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ==================modal for adding a lead===================== */}

      <div
        className="modal fade"
        id="addLeadsModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
              <a
                href="#"
                role="button"
                className="close pos-absolute t-15 r-15"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="media-body">
                <a
                  href="javascript:void(0)"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  onClick={onCloseModal.bind(
                    null,
                    "addLeadsModal",
                    "leadsForm"
                  )}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </a>
                <h4 className="tx-18 tx-sm-20 mg-b-2">Leads Form</h4>
                <p className="tx-13 tx-color-02 mg-b-0">
                  Fill all the information below.
                </p>
              </div>
            </div>
            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
              <form
                id="leadsForm"
                className="row parsley-style-1"
                onSubmit={onSubmitNewLead.bind()}
              >
                <div
                  id="firstNameWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>First Name</label>
                  <input
                    id="firstName"
                    className="form-control"
                    placeholder="Enter your first name"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#firstNameWrapper"
                    required
                  />
                </div>

                <div
                  id="lastNameWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>Last Name</label>
                  <input
                    id="lastName"
                    className="form-control"
                    placeholder="Enter last name"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#lastNameWrapper"
                    required
                  />
                </div>

                <div
                  id="emailWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Email<span className="tx-danger">*</span>
                  </label>
                  <input
                    id="email"
                    className="form-control"
                    placeholder="Enter email Address"
                    type="email"
                    autoComplete="off"
                    data-parsley-class-handler="#emailWrapper"
                    required
                  />
                </div>

                <div
                  id="phoneNumberWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Phone Number<span className="tx-danger">*</span>
                  </label>
                  <input
                    id="phoneNumber"
                    className="form-control"
                    placeholder="Enter phone number"
                    type="tel"
                    autoComplete="off"
                    data-parsley-class-handler="#phoneNumberWrapper"
                    required
                  />
                </div>
                <hr />

                <div className="col-md-12 mg-t-30">
                  <h5>Vehicle Details</h5>
                </div>
                <div
                  id="makeWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>Make</label>
                  <input
                    id="make"
                    className="form-control"
                    placeholder="Enter vehicle make  "
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#makeNameWrapper"
                  />
                </div>

                <div
                  id="modelWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>Model</label>
                  <input
                    id="model"
                    className="form-control"
                    placeholder="Enter make"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#lastNameWrapper"
                  />
                </div>
                <div
                  id="numPlateWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>Number Plate</label>
                  <input
                    id="registrationNumber"
                    className="form-control"
                    placeholder="Enter Vehicle Number Plate"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#numPlateWrapper"
                  />
                </div>
                <div
                  id="valueWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Estimated value<span className="tx-danger"></span>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        K
                      </span>
                    </div>
                    <input
                      type="number"
                      id="estimate_value"
                      class="form-control"
                      placeholder="Estimated Vehicle value"
                      aria-label="Estimated value"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <hr />

                <div className="col-md-12 mg-t-30">
                  <h5>Insurance Company Details</h5>
                </div>
                <div
                  id="insuranceCompanyWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>Insurance Company</label>
                  <input
                    id="insuranceName"
                    className="form-control"
                    placeholder="Enter Insurance Company name"
                    type="text"
                    autoComplete="off"
                    data-parsley-class-handler="#insuranceCompanyWrapper"
                  />
                </div>
                <div
                  id="productTypeWrapper"
                  className="form-group parsley-input col-sm-12"
                >
                  <label>
                    Product<span className="tx-danger"></span>
                  </label>
                  <select className="custom-select" id="product">
                    <option value="" disabled selected>
                      Select
                    </option>
                    {gariCodes.map((data) => (
                      <option value={data.id}>
                        {data.description} - {data.code}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  id="premiumWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Premium Paid<span className="tx-danger"></span>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        K
                      </span>
                    </div>
                    <input
                      type="number"
                      id="premium"
                      class="form-control"
                      placeholder="Premium Paid"
                      aria-label="Premium Paid"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div
                  id="dateWrapper"
                  className="form-group parsley-input col-sm-6"
                >
                  <label>
                    Policy End date<span className="tx-danger">*</span>
                  </label>

                  <input
                    type="text"
                    id="date_from"
                    className="form-control"
                    placeholder="Select End  Date"
                    autoComplete="off"
                    data-parsley-class-handler="#dateWrapper"
                    required
                    readOnly
                  />
                </div>

                <div className="col-md-12">
                  {loading ? (
                    <button disabled className="btn btn-brand-02 float-right">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button className="btn btn-brand-01 btnNextFront btn-block">
                      Add Lead
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leads;
