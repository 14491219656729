import React, {useEffect} from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import {searchClient} from "../../request";
import cogoToast from "cogo-toast";
import "typeahead.js";
import {SEARCH_CLIENT_URL} from "../../api";

const Search = (props) => {

    // props.match.params.id
    useEffect(() => {
        $('.typeahead').typeahead({
                minLength: 3,
                hint: false,
                highlight: true,
            },
            {
                name: 'my-dataset',
                source: function (query, processSync, processAsync) {
                    return $.ajax({
                        url: SEARCH_CLIENT_URL(query),
                        type: 'GET',
                        dataType: 'json',
                        success: function (json) {
                            let result = json.data.map((data) => `${data.firstName} ${data.middleName ? data.middleName : ""} ${data.lastName} - ${data.phoneNumber}`);
                            // in this example, json is simply an array of strings
                            return processAsync(result);
                        }
                    });
                },
                templates: {
                    empty: function(){
                        $(".tt-dataset").text('No Results Found');
                    }
                }
            }
        );

        $("#query_value").keyup(function(event) {
            const error = {
                position: "top-right",
                hideAfter: 3
            };

            if (event.keyCode === 13) {
                let query = $('#query_value').val();
                if(!query) {
                    cogoToast.error("Enter client name, email or phone number.", error);
                    return false;
                } else {
                    querySearch();
                }
            }
        });
    }, []);

    const querySearch = () => {
        const error = {
            position: "top-right",
            hideAfter: 3
        };

        let query = $("#query_value").val().split('-')[1];
        if(!query) {
            cogoToast.error("Enter client name, email or phone number.", error);
            return false;
        }

        const options = {
            position: "top-right",
            hideAfter: 0
        };

        let {hide} = cogoToast.loading('Please wait... Searching Client.', options);

        searchClient(query.trim()).then((result) => {
            hide();
            if(result.error !== true) {
                $("#query_value").val("");
                if(props.match.path === "/client-details/:id") {
                    props.history.replace(`/client-details/${result.payload.data[0].id}`);
                    window.location.reload();
                } else {
                    props.history.push(`/client-details/${result.payload.data[0].id}`);
                }

            }

            return false;
        });
    };

    return (
        <div className="content-header">
            <div className="content-search">
                <i data-feather="search"></i>
                <input type="search" id="query_value" className="form-control typeahead" placeholder="Search Client." autoComplete="off"/>
            </div>
            <nav className="nav">
                <Link to="#" onClick={querySearch.bind()} className="nav-link"><i data-feather="arrow-right-circle"></i></Link>
            </nav>
        </div>
    );
};

export default Search;
