import axios from "axios";
import {GET_SALVAGES_URL, ADD_INSURANCE_SALVAGE_URL, DELETE_SALVAGE_URL} from "../api";
import errorHandler from "../utils/errorHandler";

export const getSalvages = () => {
    return axios.get(GET_SALVAGES_URL).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true,
            payload: error
        }
    });
};

export const deleteSalvage = (id) => {
    return axios.delete(DELETE_SALVAGE_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
}

export const addSalvage = (raw, insuranceId) => {
    return axios.post(ADD_INSURANCE_SALVAGE_URL(insuranceId), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        console.log(error.response.data);
        return {
            error: true,
            payload: error.response.data
        }
    });
}

