import React, { useState, useEffect } from "react";

import { FormGroup, Input, Label } from "reactstrap";
import {
  useGetReports,
  useGetInsuranceCompanies,
  useDownloadReports,
} from "request/reports";

export const Reports = () => {
  const [insuranceCompanyId, setInsuranceCompanyId] = useState(null);
  const [companies, setCompanies] = useState([]);
  // let [dateRange, setDateRange] = React.useState();
  let [startDate, setStartDate] = React.useState();
  let [endDate, setEndDate] = React.useState();

  const {
    data: reports,
    isLoading,
    error,
    isFetching: reportFetching,
    refetch,
  } = useGetReports(startDate, endDate, insuranceCompanyId);
  const {
    isLoading: reportsDownloadLoading,
    error: reportsDownloadError,
    isFetching: reportsDownloadFetching,
    refetch: reportsDownloadRefetch,
  } = useDownloadReports(startDate, endDate, insuranceCompanyId);

  const {
    data: insuranceCompanies,
    isLoading: insuranceLoading,
    error: insuranceError,
    isFetching: insuranceFetching,
    refetch: insuranceRefetch,
  } = useGetInsuranceCompanies();

  useEffect(() => {
    if (insuranceCompanies) {
      setCompanies(insuranceCompanies);
    }
  }, [insuranceCompanies]);

  return (
    <div className="content-body">
      <div className=" d-flex align-items-center mb-4 justify-content-between">
        <div className="d-flex  ">
          <FormGroup className="mr-2">
            <Label for="start">From </Label>
            <Input
              id="start"
              name="date"
              placeholder="date placeholder"
              type="date"
              onChange={(val) => {
                console.log("start date", val.target.value);
                setStartDate(val.target.value);
              }}
            />
          </FormGroup>
          <> </>
          <FormGroup className="mr-2">
            <Label for="end">To </Label>
            <Input
              id="end"
              name="date"
              onChange={(val) => {
                console.log("end date", val.target.value);
                setEndDate(val.target.value);
              }}
              placeholder="date placeholder"
              type="date"
            />
          </FormGroup>
          <FormGroup>
            <Label for="companySelect">Select Company</Label>
            <Input
              id="companySelect"
              name="select"
              type="select"
              defaultValue={null}
              onChange={(selected) => {
                console.log("selected value", selected.target.value);
                setInsuranceCompanyId(selected.target.value);
              }}
            >
              <option>All</option>

              {companies.length < 1
                ? " Loading..."
                : companies.map((insurance, index) => (
                    <option key={insurance.id} value={insurance.id}>
                      {insurance.name}
                    </option>
                  ))}
            </Input>
          </FormGroup>
        </div>
        <div className="d-flex  ">
          <button
            class="btn btn-primary mr-2"
            onClick={() => refetch()}
            disabled={isLoading || reportFetching}
          >
            {isLoading || reportFetching ? (
              "Loading..."
            ) : (
              <>
                <i className="icon ion-md-refresh mr-2"></i>
                Generate
              </>
            )}
          </button>
          <button
            class="btn btn-primary mr-2"
            onClick={() => reportsDownloadRefetch()}
            disabled={reportsDownloadFetching}
          >
            {reportsDownloadFetching ? (
              "Loading..."
            ) : (
              <>
                <i className="icon ion-md-download mr-2"></i>
                Download
              </>
            )}
          </button>
        </div>
      </div>
      <div className="card rounded">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h6 className="mg-b-0">Reports</h6>
          <div className="d-flex tx-18">
            <a
              href="javascript:void(0)"
              onClick={() => refetch()}
              className="link-03 lh-0"
            >
              <i className="icon ion-md-refresh"></i>
            </a>
          </div>
        </div>
        <div className="card-body table-responsive">
          <table id="leads" className="table table-hover  border rounded  ">
            <thead>
              <tr>
                <th>S.No</th>
                <th className="wd-10p">Policy No</th>
                <th>Insurance Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Transaction Date</th>
                <th>Customer Name</th>
                <th>Insurance Partner</th>
                <th>Basic Premium</th>
                <th>Levy (5%)</th>
                <th>Gross Premium</th>
                <th>Transaction Fee</th>
                <th>Net Payable</th>
              </tr>
            </thead>
            {isLoading || reportFetching ? (
              <>Loading</>
            ) : (
              <tbody>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{report.policyNumber}</td>
                    <td>{report.insuranceType}</td>
                    <td>{report.policyStartDate}</td>
                    <td>{report.policyEndDate}</td>
                    <td>{report.transactionDate}</td>{" "}
                    <td>{report.customerName}</td>
                    <td>{report.insurancePartner}</td>
                    <td>{report.basicPremium}</td>
                    <td>{report.levy}</td>
                    <td>{report.netPremium}</td>
                    <td>{report.commission}</td>
                    <td>
                      {(report.netPremium - report.commission).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
