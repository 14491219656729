import axios from "axios";

import {
  LEADS_URL,
  STATS_LEADS_URL,
  LEAD_BY_ID_URL,
  LEAD_ACTIVITY_BY_ID_URL,
  LEAD_TO_Client,
} from "../api";

export const getLeadDetail = (id) => {
  return axios
    .get(LEAD_BY_ID_URL(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const createLead = (raw) => {
  console.log("lead data", JSON.stringify(raw));
  return axios
    .post(LEADS_URL, raw)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data["message"],
      };
    });
};

export const convertLeadToClient = (raw, id) => {
  console.log("request data", JSON.stringify(raw));
  console.log("lead id", id);
  return axios
    .post(LEAD_TO_Client(id), raw)
    .then((response) => {
      console.log("response data", JSON.stringify(response.data));
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      console.log("error data", JSON.stringify(error.response.data));
      return {
        error: true,
        payload: error.response.data,
      };
    });
};
export const createLeadActivity = (raw, id) => {
  console.log("activity data", raw.fromDate);
  return axios
    .post(LEAD_ACTIVITY_BY_ID_URL(id), raw)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data["message"],
      };
    });
};
