import React, { useEffect, useState } from "react";
import "parsleyjs";
import "datatables.net";
import "datatables.net-responsive";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";
import cogoToast from "cogo-toast";
import {
  addSalvage,
  deleteSalvage,
  getSalvages,
  getInsuranceCompanies,
  uploadGeneralDocument,
} from "../../../request";
import formatNumber from "../../../utils/formatNumber";
import "parsleyjs";
import moment from "moment";
import { useSelector } from "react-redux";
import car_make from "../../../data/car-make.json";
import Resizer from "react-image-file-resizer";
const Salvages = () => {
  document.title = "Salvages";

  const { id } = useSelector((state) => state.auth.user.user);

  const [search, _search] = useState("");

  const [insuranceCompany, setInsuranceCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [secLoading, setSecLoading] = useState(false);
  const [salvageLoading, setSalvageLoading] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [stateSearch, setStateSearch] = useState(false);
  const [stateReady, setStateReady] = useState(false);
  let [salvageList, setSalvageList] = useState([]);

  useEffect(() => {
    getInsuranceCompanies().then((res) => {
      console.log("insurance companies response", res.payload.data);
      setInsuranceCompany(res.payload.data);
    });
    getAllSalvages();
  }, []);

  $(function () {
    $("#date_from").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      minDate: 0,
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });

    $("#date_to").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      yearRange: "2022:3000",
      changeMonth: true,
      changeYear: true,
      minDate: 0,
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });

  const getAllSalvages = () => {
    getSalvages().then((result) => {
      if (result.error !== true) {
        setSalvageList(result.payload.data);
        setTotalPage(result.payload.totalRecords);
        setLoading(false);
        if (result.total > 51) {
          setLoadMore(true);
        }
      }
    });
  };

  const handleLoadMore = () => {
    setSecLoading(true);
  };

  const handleShowModal = () => {
    $("#basicSalvage").parsley();
    $("#modalAddSalvage").modal(
      { backdrop: "static", keyboard: false },
      "show"
    );
  };

  const generateId = (length) => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  const removeSalvage = (id) => {
    let { hide } = cogoToast.loading("Please wait... Deleting Salvage.", {
      position: "top-right",
      hideAfter: 0,
    });

    deleteSalvage(id).then((res) => {
      hide();
      if (res.error)
        return cogoToast.error("Something went wrong.", {
          position: "top-right",
        });
      cogoToast.success("Salvage successfully removed.", {
        position: "top-right",
      });
      getAllSalvages();
    });
  };
  const resizeFile = (file) => {
    var nameLen = file.name.length;
    var lastDotPos = file.name.lastIndexOf(".");
    var fileNameSub = false;
    if (lastDotPos === -1) {
      fileNameSub = false;
    } else {
      //Remove +1 if you want the "." left too
      fileNameSub = file.name.substr(lastDotPos + 1, nameLen);

      console.log("file sub", fileNameSub);
    }
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        400,
        fileNameSub,
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  };

  function dataURItoBlob(dataURI) {
    var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  }
  const onAddSalvage = async (e) => {
    e.preventDefault();

    let files = $(`#image`).prop("files");

    if (files.length < 6)
      return cogoToast.error("Minimum upload is 6 or more images.", {
        position: "top-right",
      });

    setStateReady(true);

    let uploads = [];

    for (let index = 0; index < files.length; index++) {
      const image = await resizeFile(files[index]);

      // console.log("before resize", files[inxex]);
      // let newFile = await fetch(image)
      //   .then((res) => res.blob())
      //   .then((blob) => {
      //     var fd = new FormData();
      //     fd.append("image", blob, "filename");
      //     console.log(blob);
      //     console.log("after resize", fd);
      //     return fd;

      //     // Upload
      //     // fetch('upload', {method: 'POST', body: fd})
      //   });

      var newFile = dataURItoBlob(image);

      console.log("after resize", newFile);
      uploads.push(await uploadDocument(newFile));
    }

    let data = {
      make: $("#make").val(),
      model: $("#model").val(),
      chassisNumber: $("#chassisNumber").val(),
      color: $("#color").val(),
      regNumber: $("#regNumber").val(),
      yearOfManufacture: $("#yearOfManufacture").val(),
      mileage: $("#mileage").val(),
      salvageType: $("#type").val(),
      startingPrice: $("#startingPrice").val(),
      minIncrease: $("#minIncrease").val(),
      location: $("#location").val(),
      damageDescription: $("#damageDescription").val(),
      startTime: new Date($("#date_from").val()),
      endTime: new Date($("#date_to").val()),
      imageUrls: uploads,
    };

    addSalvage(data, $("#insuranceId").val()).then((res) => {
      const options = {
        position: "top-right",
        hideAfter: 2,
      };

      setStateReady(false);

      if (res.error) return cogoToast.error("Something went wrong.", options);

      getAllSalvages();
      onCloseModal("modalAddSalvage", "basicSalvage");

      cogoToast.success("Salvage successfully Added.", options);
    });
  };

  const uploadDocument = (file) => {
    if (file === undefined) return null;

    const formData = new FormData();

    formData.append("Description", generateId(20));
    formData.append("File", file, `${Math.random()}${file.name}`);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      encType: "multipart/form-data",
    };

    return uploadGeneralDocument(formData, config).then((result) => {
      if (!result.error) {
        return result.payload.documentUrl;
      }
      setStateReady(false);
      return null;
    });
  };

  const onCloseModal = (id, form) => {
    let parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    parsley.reset();
    parsley.destroy();
    $(`#${id}`).modal("hide");
    $(".modal-backdrop").remove();
  };

  if (salvageList === null || loading) {
    return (
      <div className="container d-flex justify-content-center ht-100p">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mg-t-10">Loading Salvages</p>
        </div>
      </div>
    );
  } else {
    let renderLoadMore = () => {
      if (loadMore && totalPage > salvageList.length) {
        if (secLoading === true) {
          return (
            <div className="text-center mg-t-10">
              <button className="btn btn-brand-02" disabled>
                <span
                  className="spinner-border spinner-border-sm mg-r-10"
                  aria-hidden="true"
                />
                Please Wait
              </button>
            </div>
          );
        } else {
          return (
            <div className="text-center mg-t-10">
              <button
                className="btn btn-brand-02"
                onClick={handleLoadMore.bind()}
              >
                Load more
              </button>
            </div>
          );
        }
      }

      if (loadMore) {
        return (
          <div className="text-center mg-t-10">
            <p className="tx-color-02">
              --- End of Products - Total({salvageList.length}) ---
            </p>
          </div>
        );
      }

      return false;
    };

    return (
      <div className="content-body">
        <div className="container pd-x-0">
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Salvages
                  </li>
                </ol>
              </nav>
              <h4 className="mg-b-0 tx-spacing--1">Salvages</h4>
            </div>
            <div className="d-none d-md-block">
              <button
                className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
                onClick={handleShowModal.bind()}
              >
                <i className="wd-10 mg-r-5 fa fa-plus"></i> Add New
              </button>
            </div>
          </div>

          <div className="row row-xs">
            {salvageList.length ? (
              salvageList.map((result) => {
                return (
                  <div
                    key={result.id}
                    className="col-sm-4 col-md-3 col-lg-4 col-xl-3"
                  >
                    <div className="card card-profile">
                      <img
                        src={result.imageUrls[0]}
                        alt=""
                        className="img-fluid ht-170"
                      />
                      <li className="list-group-item list-group-flush tx-13 d-flex">
                        <div>
                          <p className="tx-medium mg-b-0">
                            {result.make + " "}
                            {result.model}
                          </p>
                          <small className="tx-12 tx-color-03 mg-b-0">
                            {moment(result.endTime).format("Do MMM, YYYY")}
                          </small>
                          <NavLink
                            to={"salvage-details/" + result.id}
                            className="tx-medium mg-b-0 nav-link pd-0 pd-t-2"
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                            View
                          </NavLink>
                        </div>
                        <div className="mg-l-auto text-right">
                          <p className="tx-medium mg-b-0">
                            ZMW {formatNumber(result.startingPrice)}
                          </p>
                          <small className="tx-12 tx-success mg-b-0">
                            {
                              {
                                Available: (
                                  <small className="tx-12 tx-success mg-b-0">
                                    {result.salvageStatus}
                                  </small>
                                ),
                                Unavailable: (
                                  <small className="tx-12 tx-danger mg-b-0">
                                    {result.salvageStatus}
                                  </small>
                                ),
                              }[result.salvageStatus]
                            }
                          </small>
                          <a
                            href="javascript:void(0)"
                            onClick={removeSalvage.bind(null, result.id)}
                            className="tx-medium mg-b-0 nav-link pd-0 pd-t-2"
                          >
                            <i
                              className="fa fa-trash-alt"
                              aria-hidden="true"
                            ></i>{" "}
                            Delete
                          </a>
                        </div>
                      </li>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="container d-flex justify-content-center ht-100p mg-50">
                <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                  <div className="wd-200p wd-sm-300 mg-b-15 tx-center">
                    <i className="fa fa-ban fa-3x" aria-hidden="true"></i>
                  </div>
                  <p className="mg-t-10">No Data available to show.</p>
                  <a
                    href="javascript:void(0)"
                    onClick={handleShowModal.bind()}
                    className="tx-medium mg-b-0 nav-link pd-0 pd-t-2"
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> Add
                    Salvage
                  </a>
                </div>
              </div>
            )}
          </div>

          {renderLoadMore()}
        </div>

        <div
          className="modal fade"
          id="modalAddSalvage"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <div className="media align-items-center">
                  <div className="media-body">
                    <a
                      href=""
                      role="button"
                      className="close pos-absolute t-15 r-15"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={onCloseModal.bind(
                        null,
                        "modalAddSalvage",
                        "basicSalvage"
                      )}
                    >
                      <span aria-hidden="true">&times;</span>
                    </a>
                    <h4 className="tx-18 tx-sm-20 mg-b-2">Add Salvages</h4>
                  </div>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="basicSalvage"
                  className="parsley-style-1"
                  onSubmit={onAddSalvage.bind(null)}
                  encType="multipart/form-data"
                >
                  <div id="typeWrapper" className="form-group parsley-input">
                    <label>
                      Salvage Type<span className="tx-danger">*</span>
                    </label>
                    <select className="custom-select" id="type" required>
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="Offer">Sealed Bid</option>
                      <option value="Bid">Auction</option>
                    </select>
                  </div>
                  <div
                    id="insuranceCompanyWrapper"
                    className="form-group parsley-input"
                  >
                    <label>
                      insurance Company<span className="tx-danger">*</span>
                    </label>
                    <select className="custom-select" id="insuranceId" required>
                      <option value="" disabled selected>
                        Select
                      </option>
                      {insuranceCompany.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name.toUpperCase()}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div id="regWrapper" className="form-group parsley-input">
                    <label>
                      Registration #<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="regNumber"
                      className="form-control"
                      placeholder="Enter registration number"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#regWrapper"
                      required
                    />
                  </div>

                  <div id="makeWrapper" className="form-group parsley-input">
                    <label>
                      Make<span className="tx-danger">*</span>
                    </label>
                    <select className="custom-select" id="make" required>
                      <option value="" disabled selected>
                        Select
                      </option>
                      {car_make.map((item) => {
                        return (
                          <option key={item.id} value={item.name.toUpperCase()}>
                            {item.name.toUpperCase()}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div id="modelWrapper" className="form-group parsley-input">
                    <label>
                      Model<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="model"
                      className="form-control"
                      placeholder="Enter model"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#modelWrapper"
                      required
                    />
                  </div>

                  <div id="chassiWrapper" className="form-group parsley-input">
                    <label>
                      Chassis Number<span className="tx-danger">*</span>
                    </label>
                    <input
                      id="chassisNumber"
                      className="form-control"
                      placeholder="Enter chassis number"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#chassiWrapper"
                      required
                    />
                  </div>

                  <div id="colorWrapper" className="form-group parsley-input">
                    <label>
                      Color<span className="tx-danger">*</span>
                    </label>
                    <select className="custom-select" id="color" required>
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="RED">RED</option>
                      <option value="BLACK">BLACK</option>
                      <option value="WHITE">WHITE</option>
                      <option value="GRAY">GRAY</option>
                      <option value="SILVER">SILVER</option>
                      <option value="BLUE">BLUE</option>
                    </select>
                  </div>

                  <div className="row">
                    <div
                      id="yearWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Year Of Manufacture<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="yearOfManufacture"
                        className="form-control"
                        placeholder="Enter year of manufacture"
                        type="number"
                        autoComplete="off"
                        data-parsley-class-handler="#yearWrapper"
                        required
                      />
                    </div>

                    <div
                      id="mileageWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Mileage<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="mileage"
                        className="form-control"
                        placeholder="Enter mileage"
                        type="number"
                        autoComplete="off"
                        data-parsley-class-handler="#mileageWrapper"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div
                      id="startingPriceWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Starting Price<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="startingPrice"
                        className="form-control"
                        placeholder="Enter starting price"
                        type="number"
                        autoComplete="off"
                        data-parsley-class-handler="#startingPriceWrapper"
                        required
                      />
                    </div>

                    <div
                      id="minWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Min Increase<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="minIncrease"
                        className="form-control"
                        placeholder="Enter min increase"
                        type="number"
                        autoComplete="off"
                        data-parsley-class-handler="#minWrapper"
                        required
                      />
                    </div>
                  </div>

                  <div
                    id="locationWrapper"
                    className="form-group parsley-input"
                  >
                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                      Location
                    </label>
                    <input
                      id="location"
                      type="text"
                      className="form-control"
                      placeholder="Enter location address"
                      autoComplete="off"
                      data-parsley-class-handler="#locationWrapper"
                      required
                    />
                  </div>

                  <div
                    id="descriptionWrapper"
                    className="form-group parsley-input"
                  >
                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                      Description
                    </label>
                    <textarea
                      id="damageDescription"
                      type="text"
                      rows="5"
                      className="form-control"
                      placeholder="Provide a description"
                      data-parsley-class-handler="#descriptionWrapper"
                      required
                    ></textarea>
                  </div>

                  <div className="row">
                    <div
                      id="startDateWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Date From<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="date_from"
                        className="form-control"
                        placeholder="Select Start Date"
                        autoComplete="off"
                        data-parsley-class-handler="#startDateWrapper"
                        required
                        readOnly
                      />
                    </div>

                    <div
                      id="endDateWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Date To<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="date_to"
                        className="form-control"
                        placeholder="Select End Date"
                        autoComplete="off"
                        data-parsley-class-handler="#endDateWrapper"
                        required
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">
                      Upload 6 or more Images
                    </label>
                    <input
                      id="image"
                      type="file"
                      className="form-control"
                      data-parsley-filemaxmegabytes="1"
                      data-parsley-trigger="change"
                      data-parsley-filemimetypes="image/jpeg, image/png, image/gif, image/jpg"
                      required
                      multiple
                    />
                  </div>

                  {stateReady ? (
                    <button className="btn btn-brand-02 btn-block" disabled>
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button className="btn btn-success btn-block">
                      <i className="fa fa-plus" /> Submit Salvage
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Salvages;
