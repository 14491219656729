import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getLeadDetail } from "request/leads";
import $ from "jquery";
import "components-jqueryui";
import "parsleyjs";
import "select2";
import "datatables.net";
import "datatables.net-responsive";
import moment from "moment";
import cogoToast from "cogo-toast";
import { uploadClientDocument } from "../../../request";
import { createLeadActivity, convertLeadToClient } from "request/leads";
import { useDispatch, useSelector } from "react-redux";

const LeadsDetails = () => {
  const [loading, setLoading] = useState(true);

  const [leadActivityLoading, setLeadActivityLoading] = useState(false);
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [leadData, setLeadData] = useState([]);
  let lead = useParams();
  const { id } = useSelector((state) => state.auth.user.user);
  const { products } = useSelector((state) => state.organization.products);
  const [editLoading, setEditLoading] = useState(false);
  const [labelIdType, setLabelIdType] = useState("NRC");
  const [createLoading, setCreateLoading] = useState(false);
  const [type, setType] = useState("Amount");
  const [accountType, setAccountType] = useState("Individual");
  const [vehicleData, setVehicleData] = useState({});
  const [create, setCreate] = useState(true);
  //   const [createEngagement, setCreateEngagement] = useState(true);
  const { bodyType } = useSelector((state) => state.organization.bodyType);
  const { riskCategory } = useSelector(
    (state) => state.organization.riskCategory
  );

  const history = useHistory();
  useEffect(() => {
    getLeadDetail(lead.id)
      .then((res) => {
        console.log("lead data", res);

        setLoading(false);
        setLeadData([...res.payload.leadActivities]);
        $("#DOB").text(moment(res.payload.dateOfBirth).format("Do MMM, YYYY"));
        $("#name").text(`${res.payload.firstName} ${res.payload.lastName}`);
        $("#fullName").text(`${res.payload.firstName} ${res.payload.lastName}`);
        $("#email").text(res.payload.email);
        $("#status").text(res.payload.leadStatus);
        $("#phoneNumber").text(res.payload.phoneNumber);
        $("#email").text(res.payload.email);
        $("#address").text(res.payload.address || "Not Set");
        $("#accountCreated").text(
          moment(res.payload.createdAt).format("Do MMM, YYYY")
        );
        // vehicle Data
        $("#companyName").text(
          res.payload.leadInsuranceCompanyDetails.companyName
        );
        $("#premiumPaid").text(
          res.payload.leadInsuranceCompanyDetails.premiumPaid
        );
        $("#policyEndDate").text(
          moment(res.payload.leadInsuranceCompanyDetails.policyEndDate).format(
            "Do MMM, YYYY"
          )
        );
        $("#vehicleName").text(
          `${res.payload.leadVehicleDetails.make} ${res.payload.leadVehicleDetails.model}`
        );

        $("#estimatedValue").text(`K ${res.payload.leadVehicleDetails.value}`);
        $("#registrationNumber").text(
          res.payload.leadVehicleDetails.registrationNumber
        );
        setVehicleData(res.payload);
      })
      .catch((error) => {
        setLoading(false);
      });
    return () => {
      $("#endData").datepicker("destroy");
    };
  }, [loading]);
  const showModalVehicle = () => {
    setCreate(false);

    // $("#RiskCategory").val(vehicleData.category.id);
    // $("#BodyType").val(vehicleData.bodyType.id);
    $("#RegNumber").val(vehicleData.leadVehicleDetails.registrationNumber);
    $("#Make").val(vehicleData.leadVehicleDetails.make);
    $("#Model").val(vehicleData.leadVehicleDetails.model);
    // $("#EngineNumber").val(vehicleData.engineNumber);
    $("#YearOfManufacture").val(vehicleData.yearOfManufacture);
    // $("#ChassisNumber").val(vehicleData.chassisNumber);
    // $("#CubicCapacity").val(vehicleData.cubicCapacity);
    // $("#Color").val(vehicleData.color);

    $("#basicVehicle").parsley();
    $("#modalAddVehicle").modal("show");
  };

  $(function () {
    $("#date_from").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: new Date().getDate(),
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });
  $(function () {
    $("#date_to_activity").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: new Date().getDate(),
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });
  $(function () {
    $("#date_from_activity").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: new Date().getDate(),
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });
  $(function () {
    $("#follow_up_date_from_activity").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: new Date().getDate(),
      yearRange: "1800:+nn",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });

  const onAddVehicleSubmit = async (e) => {
    e.preventDefault();
    setVehicleLoading(true);

    let raw = {
      riskCategoryId: $("#RiskCategory").val(),
      make: $("#Make").val(),
      tonnage: "123",
      model: $("#Model").val(),
      regNumber: $("#RegNumber").val(),
      engineNumber: $("#EngineNumber").val(),
      chassisNumber: $("#ChassisNumber").val(),
      cubicCapacity: $("#CubicCapacity").val(),
      yearOfManufacture: $("#YearOfManufacture").val(),
      whiteBookUrl: await uploadDocument("Whitebook"),
      licenseUrl: await uploadDocument("DriversLicense"),
      color: $("#Color").val(),
      bodyTypeId: $("#BodyType").val(),
    };

    convertLeadToClient(raw, lead.id).then((res) => {
      const options = {
        position: "top-right",
        hideAfter: 2,
      };

      setVehicleLoading(false);

      if (res.error) return cogoToast.error("Something went wrong.", options);

      onCloseModal("modalAddVehicle", "basicVehicle");
      history.push(`/client-details/${res.payload.id}`);

      cogoToast.success("Vehicle successfully Added.", options);
    });
  };
  const uploadDocument = (id) => {
    let [files] = $(`#${id}`).prop("files");

    if (files === undefined) return null;

    const formData = new FormData();

    formData.append("ClientId", lead.id);
    formData.append("DocumentType", `${id}`);
    formData.append("Description", `${id}`);
    formData.append("File", files);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      encType: "multipart/form-data",
      onUploadProgress: (progress) => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
        // uploadProgress = FileLoaded * uploadPercentage;
      },
    };

    return uploadClientDocument(formData, config).then((result) => {
      if (!result.error) {
        return result.payload.documentUrl;
      }

      return null;
    });
  };
  const onSubmitNewLeadActivity = (e) => {
    e.preventDefault();
    let leadActivity = {
      name: $("#ActivityName").val(),
      description: $("#description").val(),
      activityType: $("#activityType").val(),
      fromDate: new Date($("#date_from_activity").val()).toISOString(),
      toDate: new Date($("#date_to_activity").val()).toISOString(),
      location: $("#location").val(),
      followUpActivityDto: {
        activityType: $("#followUpActivityType").val(),
        fromDate: new Date(
          $("#follow_up_date_from_activity").val()
        ).toISOString(),
      },
    };
    setLeadActivityLoading(true);
    createLeadActivity(leadActivity, lead.id).then((res) => {
      const options = {
        position: "top-right",
      };

      setLeadActivityLoading(false);

      if (res.error) return cogoToast.error(res.payload, options);

      onCloseModal("addLeadActivityModal", "leadActivityForm");
      //   $("#leads").DataTable().ajax.reload();
      setLoading(true);
      cogoToast.success("lead activity successfully Added.", options);
    });
  };
  const onCloseModal = (id, form) => {
    let parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    $(`#${id}`).modal("hide");
    // parsley.reset();
    // parsley.destroy();
    $(".modal-backdrop").remove();
    // $("#nav_basic a:first").tab("show");
  };
  if (!loading) {
    return (
      <div className="content-body">
        <div className="container pd-x-0">
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <a href="#">Menu</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Leads</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Leads Details
                  </li>
                </ol>
              </nav>
              <div></div>
              <h4 id="name" className="mg-b-0 tx-spacing--1">
                -----
              </h4>
            </div>
            <button
              className="btn btn-secondary "
              onClick={showModalVehicle.bind()}
            >
              <i className="fa fa-pencil"></i> convert to Client
            </button>
          </div>

          <div className="row row-xs">
            <div className="col-md-12 mg-t-10">
              <div className="nav-wrapper mg-b-20 tx-13">
                <div>
                  <ul
                    id="nav_basic"
                    className="nav nav-line nav-fill tx-medium"
                  >
                    <li className="nav-item">
                      <a
                        href="#leadDetails"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-person" /> Leads Details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#vehicle" className="nav-link" data-toggle="tab">
                        <i className="ion-ios-car" /> Vehicles{" "}
                        <span
                          id="total_vehicles"
                          className="badge badge-light mg-l-2"
                        ></span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#activities"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="ion-ios-document" /> Activities{" "}
                        <span
                          id="total_clients"
                          className="badge badge-light mg-l-5"
                        ></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tab-content">
                <div id="leadDetails" className="tab-pane fade active show">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <p>
                        <strong>Full Name:</strong>{" "}
                        <span id="fullName">Blank</span>
                      </p>
                      <p>
                        <strong>Phone Number:</strong>{" "}
                        <span id="phoneNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Date of Birth:</strong>{" "}
                        <span id="DOB">Blank</span>
                      </p>

                      <p>
                        <strong>Email:</strong> <span id="email">Blank</span>
                      </p>
                      <p>
                        <strong>Physical Address:</strong>{" "}
                        <span id="address">Blank</span>
                      </p>
                      <p>
                        <strong>Lead Status:</strong>{" "}
                        <span id="status">Blank</span>
                      </p>
                      <p>
                        <strong>Account Created:</strong>{" "}
                        <span id="accountCreated">Blank</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div id="vehicle" className="tab-pane fade">
                  <div class="row  d-flex justify-content-between">
                    <div class=""></div>

                    <div class="">
                      {" "}
                      {/* <button
                        className="btn btn-secondary "
                        onClick={showModalVehicle.bind()}
                      >
                        <i className="fa fa-pencil"></i> Update Vehicle
                      </button> */}
                    </div>
                  </div>

                  <div className="row row-sm">
                    <div className="col-md-12">
                      <p>
                        <strong>Vehicle Name:</strong>{" "}
                        <span id="vehicleName">Blank</span>
                      </p>
                      <p>
                        <strong>Registration Number:</strong>{" "}
                        <span id="registrationNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Estimated value:</strong>{" "}
                        <span id="estimatedValue">Blank</span>
                      </p>

                      <p>
                        <strong>Company Name:</strong>{" "}
                        <span id="companyName">Blank</span>
                      </p>
                      <p>
                        <strong>Policy End Date:</strong>{" "}
                        <span id="policyEndDate">Blank</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div id="activities" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Activities</h6>
                          <div className="d-flex tx-18">
                            <button
                              role={"button"}
                              className="link-03 lh-0 btn btn-link"
                              data-toggle="modal"
                              data-target="#addLeadActivityModal"
                            >
                              <i className="icon ion-md-add"></i>
                            </button>
                            {/* <button
                              role={"button"}
                              className="link-03 lh-0 btn btn-link mg-l-10"
                              onClick={() =>
                                $("#activitiesTable").DataTable().ajax.reload()
                              }
                            >
                              <i className="icon ion-md-refresh"></i>
                            </button> */}
                          </div>
                        </div>
                        <div className="card-body table-responsive">
                          <table
                            id="activitiesTable"
                            className="table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th className="wd-20p">Activity Name</th>
                                <th className="wd-20p">Activity Type</th>
                                <th className="wd-20p">Description</th>
                                <th className="wd-20p">Start Date</th>
                                <th className="wd-20p">End Date</th>
                                <th className="wd-20p">Location</th>
                                <th className="wd-20p"> Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {leadData.map((activity, index) => (
                                <tr key={index}>
                                  {" "}
                                  <td>{activity?.name}</td>
                                  <td>{activity?.activityType}</td>
                                  <td>{activity?.description}</td>
                                  <td>
                                    {moment(activity?.fromDate).format(
                                      "Do MMM, YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(activity?.toDate).format(
                                      "Do MMM, YYYY"
                                    )}
                                  </td>
                                  <td>{activity?.location}</td>
                                  <td>{activity?.activityStatus}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalAddVehicle"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href="javascript:void(0)"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    onClick={onCloseModal.bind(
                      null,
                      "modalAddVehicle",
                      "basicVehicle"
                    )}
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">{"Update Vehicle"}</h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Fill the information below.
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="basicVehicle"
                  className="parsley-style-1"
                  onSubmit={onAddVehicleSubmit.bind()}
                >
                  <div className="row row-sm">
                    <div
                      id="riskWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Category<span className="tx-danger">*</span>
                      </label>
                      <select
                        className="custom-select"
                        id="RiskCategory"
                        required
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {riskCategory.map((data) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                      </select>
                    </div>

                    <div
                      id="bodyTypeWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Body Type<span className="tx-danger">*</span>
                      </label>
                      <select className="custom-select" id="BodyType" required>
                        <option value="" disabled selected>
                          Select
                        </option>
                        {bodyType.map((data) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                      </select>
                    </div>

                    <div
                      id="regWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Registration #<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="RegNumber"
                        className="form-control"
                        placeholder="Enter registration number"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#regWrapper"
                        required
                      />
                    </div>

                    <div
                      id="makeWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Make<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="Make"
                        className="form-control"
                        placeholder="Enter make"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#makeWrapper"
                        required
                      />
                    </div>

                    <div
                      id="modelWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Model<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="Model"
                        className="form-control"
                        placeholder="Enter model"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#modelWrapper"
                        required
                      />
                    </div>

                    <div
                      id="engineWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Engine Number<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="EngineNumber"
                        className="form-control"
                        placeholder="Enter engine number"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#engineWrapper"
                        required
                      />
                    </div>

                    <div
                      id="yearWrapper"
                      className="form-group parsley-input col-sm-6"
                    >
                      <label>
                        Year Of Manufacture<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="YearOfManufacture"
                        className="form-control"
                        placeholder="Enter year of manufacture"
                        type="number"
                        autoComplete="off"
                        data-parsley-class-handler="#yearWrapper"
                        required
                      />
                    </div>

                    <div
                      id="chassisWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Chassis Number<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="ChassisNumber"
                        className="form-control"
                        placeholder="Enter chassis number"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#chassisWrapper"
                        required
                      />
                    </div>

                    <div
                      id="cubicWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Cubic Capacity<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="CubicCapacity"
                        className="form-control"
                        placeholder="Enter cubic capacity"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#cubicWrapper"
                        required
                      />
                    </div>

                    <div
                      id="colorWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>
                        Color<span className="tx-danger">*</span>
                      </label>
                      <input
                        id="Color"
                        className="form-control"
                        placeholder="Select Color"
                        type="text"
                        autoComplete="off"
                        data-parsley-class-handler="#colorWrapper"
                        required
                      />
                    </div>

                    <div
                      id="LicenseWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>License(Optional)</label>
                      <input
                        id="DriversLicense"
                        className="form-control"
                        placeholder="Select"
                        type="file"
                        autoComplete="off"
                        data-parsley-class-handler="#licenseWrapper"
                      />
                    </div>

                    <div
                      id="WhitebookWrapper"
                      className="form-group parsley-input col-sm-12"
                    >
                      <label>WhiteBook(Optional)</label>
                      <input
                        id="Whitebook"
                        className="form-control"
                        placeholder="Select"
                        type="file"
                        data-parsley-class-handler="#whitebookWrapper"
                      />
                    </div>
                  </div>
                  {vehicleLoading ? (
                    <button disabled className="btn btn-brand-02 btn-block">
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      />
                      Please Wait
                    </button>
                  ) : (
                    <button className="btn btn-brand-02 btn-block mt-2">
                      <i className="ion-md-save" /> Update vehicle
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="addLeadActivityModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                <a
                  href="#"
                  role="button"
                  className="close pos-absolute t-15 r-15"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </a>
                <div className="media-body">
                  <a
                    href="javascript:void(0)"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                    onClick={onCloseModal.bind(
                      null,
                      "addLeadActivityModal",
                      "leadsActivityForm"
                    )}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <h4 className="tx-18 tx-sm-20 mg-b-2">Lead Activity Form</h4>
                  <p className="tx-13 tx-color-02 mg-b-0">
                    Fill all the information below.
                  </p>
                </div>
              </div>
              <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                <form
                  id="leadActivityForm"
                  className="row parsley-style-1"
                  onSubmit={onSubmitNewLeadActivity.bind()}
                >
                  <div
                    id="NameWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>Name</label>
                    <input
                      id="ActivityName"
                      className="form-control"
                      placeholder="Name"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#NameWrapper"
                      required
                    />
                  </div>

                  <div
                    id="activityTypeWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      Activity Type<span className="tx-danger">*</span>
                    </label>
                    <select
                      className="custom-select"
                      id="activityType"
                      required
                    >
                      <option value="" disabled selected>
                        Select
                      </option>

                      <option value={"Call"}>Call</option>
                      <option value={"Message"}>Message</option>
                      <option value={"Email"}>Email</option>
                      <option value={"Meeting"}>Meeting</option>
                    </select>
                  </div>
                  <div
                    id="dateWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      Activity Start Date<span className="tx-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="date_from_activity"
                      className="form-control"
                      placeholder="Select start  Date"
                      autoComplete="off"
                      data-parsley-class-handler="#dateWrapper"
                      readOnly
                    />
                  </div>
                  <div
                    id="dateWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      Activity end Date<span className="tx-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="date_to_activity"
                      className="form-control"
                      placeholder="Select End  Date"
                      autoComplete="off"
                      data-parsley-class-handler="#dateWrapper"
                      required
                      readOnly
                    />
                  </div>
                  <div
                    id="descriptionWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>Description</label>
                    <textarea
                      id="description"
                      className="form-control"
                      type="textArea"
                      autoComplete="off"
                      data-parsley-class-handler="#descriptionWrapper"
                    />
                  </div>
                  <div
                    id="locationWrapper"
                    className="form-group parsley-input col-sm-12"
                  >
                    <label>Location</label>
                    <input
                      id="location"
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      data-parsley-class-handler="#locationWrapper"
                    />
                  </div>
                  <hr />

                  <div className="col-md-12 mg-t-30">
                    <h5>Follow Up Engagement</h5>
                  </div>
                  <div
                    id="activityTypeWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      Activity Type<span className="tx-danger"></span>
                    </label>
                    <select
                      className="custom-select"
                      id="followUpActivityType"
                      require
                      d
                    >
                      <option value="" disabled selected>
                        Select
                      </option>

                      <option value={"Call"}>Call</option>
                      <option value={"Message"}>Message</option>
                      <option value={"Email"}>Email</option>
                      <option value={"Meeting"}>Meeting</option>
                    </select>
                  </div>
                  <div
                    id="dateWrapper"
                    className="form-group parsley-input col-sm-6"
                  >
                    <label>
                      Follow up Date<span className="tx-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="follow_up_date_from_activity"
                      className="form-control"
                      placeholder="Select End  Date"
                      autoComplete="off"
                      data-parsley-class-handler="#dateWrapper"
                      required
                      readOnly
                    />
                  </div>

                  <div className="col-md-12">
                    {leadActivityLoading ? (
                      <button disabled className="btn btn-brand-02 float-right">
                        <span
                          className="spinner-border spinner-border-sm mg-r-10"
                          role="status"
                          aria-hidden="true"
                        />
                        Please Wait
                      </button>
                    ) : (
                      <button className="btn btn-brand-01 btnNextFront btn-block">
                        Add Activity
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="content-body">
      <div className="container d-flex justify-content-center ht-100p">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mg-t-10">Collecting Data</p>
        </div>
      </div>
    </div>
  );
};
export default LeadsDetails;
