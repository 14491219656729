import axios from "axios";
import {GET_ALL_CLIENTS_URL, GET_VEHICLES_URL, GET_GARI_CODE_URL, GET_ALL_POLICIES_URL, GET_CLAIMS_URL} from "../api";
import errorHandler from "../utils/errorHandler";

export const getStatistics = () => {
    return axios.all([
        axios.get(GET_ALL_CLIENTS_URL),
        axios.get(GET_VEHICLES_URL),
        axios.get(GET_ALL_POLICIES_URL),
        axios.get(GET_CLAIMS_URL),
        axios.get(GET_GARI_CODE_URL)
    ]).then(axios.spread((response1, response2, response3, response4, response5) => {
        // Multiple requests are now complete
        return {
            clients: response1.data.totalRecords,
            vehicles: response2.data.totalRecords,
            policies: response3.data.totalRecords,
            claims: response4.data.totalRecords,
            gariCode: response5.data.totalRecords
        }
    })).catch((error) => {
        errorHandler(error, "top-right");
        console.log(error.response.data)
        return {
            error: true
        }
    });
};

