import axios from "axios";
import {
  GET_CLIENTS_URL,
  VERIFY_MOBILE_NUMBER_URL,
  VERIFY_EMAIL_ADDRESS_URL,
  GET_ALL_BANKS_URL,
  GET_ACCOUNT_TYPE_URL,
  GET_GARI_CODE_URL,
  GET_BODY_TYPE_URL,
  GET_RISK_CATEGORY_URL,
  CLIENT_VEHICLE_URL,
  CLIENTS_ENGAGEMENT_URL,
  UPLOAD_CLIENT_DOCUMENT_URL,
  UPLOAD_INSURANCE_DOCUMENT_URL,
  UPLOAD_GENERAL_DOCUMENT_URL,
  ADD_INSURANCE_COMPANY_URL,
    ADD_GARAGE_COMPANY_URL,
  GET_VEHICLE_INFO_URL,
  DELETE_VEHICLE_URL,
  UPDATE_CLIENT_URL,
  GET_CLIENT_ALL_POLICIES_URL,
  GET_TP_ACT_RATES_POLICY_EXTENSION_URL,
  EXTEND_THIRD_PARTY_ACT_ONLY_POLICY_URL,
  UPDATE_CLIENT_VEHICLE_URL,
  GET_CLIENT_POLICIES_URL,
  GET_CLIENT_DETAIL_URL,
  GET_CLIENT_DOCUMENT_URL,
  GET_INSURANCE_ALL_PRODUCT,
  GET_INSURANCE_DISCOUNT,
  GET_INSURANCE_EXTENSION,
  SEARCH_CLIENT_URL,
  GET_ALL_PRODUCTS,
  CLIENT_REGISTRATION,
  CLIENTS_STATS__URL,
} from "../api";
import errorHandler from "../utils/errorHandler";
import {
  BANK,
  ACCOUNT_TYPE,
  BODY_TYPE,
  RISK_CATEGORY,
  GARI_CODE,
  PRODUCT,
  ALL_PRODUCTS,
  DISCOUNT,
  EXTENSION,
} from "../stores/organization";

export const getClientStatistics = (id) => {
  return axios
    .get(GET_CLIENTS_URL(id))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error, "top-center");
    });
};

export const getOrganization = (id) => {
  return function (dispatch) {
    return axios
      .all([
        axios.get(GET_INSURANCE_ALL_PRODUCT(id)),
        axios.get(GET_INSURANCE_DISCOUNT(id)),
        axios.get(GET_INSURANCE_EXTENSION(id)),
      ])
      .then(
        axios.spread((response1, response2, response3) => {
          // Multiple requests are now complete
          dispatch(PRODUCT({ product: response1.data.data }));
          dispatch(DISCOUNT({ discount: response2.data }));
          dispatch(EXTENSION({ extension: response3.data }));
        })
      )
      .catch(() => {
        return null;
      });
  };
};

export const getSettings = () => {
  return function (dispatch) {
    axios
      .all([
        axios.get(GET_ALL_BANKS_URL),
        axios.get(GET_ACCOUNT_TYPE_URL),
        axios.get(GET_BODY_TYPE_URL),
        axios.get(GET_RISK_CATEGORY_URL),
        axios.get(GET_GARI_CODE_URL),
        axios.get(GET_ALL_PRODUCTS),
      ])
      .then(
        axios.spread(
          (
            response1,
            response2,
            response3,
            response4,
            response5,
            response6
          ) => {
            // Multiple requests are now complete
            dispatch(BANK({ bank: response1.data.data }));
            dispatch(ACCOUNT_TYPE({ accountType: response2.data.data }));
            dispatch(BODY_TYPE({ bodyType: response3.data.data }));
            dispatch(RISK_CATEGORY({ riskCategory: response4.data }));
            dispatch(GARI_CODE({ gariCode: response5.data.data }));
            dispatch(ALL_PRODUCTS({ products: response6.data }));
          }
        )
      )
      .catch(() => {
        return null;
      });
  };
};

export const searchClient = (query) => {
  return axios
    .get(SEARCH_CLIENT_URL(query))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch(() => {
      return {
        error: true,
        payload: "Something went wrong.",
      };
    });
};

export const verifyPhoneNumber = (phoneNumber) => {
  return axios
    .post(VERIFY_MOBILE_NUMBER_URL, { phoneNumber })
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const verifyEmailAddress = (email) => {
  return axios
    .post(VERIFY_EMAIL_ADDRESS_URL, { email })
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const addVehicle = (raw) => {
  return axios
    .post(CLIENT_VEHICLE_URL, raw)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const getClientStats = (path) => {
  return axios
    .get(CLIENTS_STATS__URL(path))
    .then((response) => {
      console.log("lead stats data", response.data);
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data["message"],
      };
    });
};
export const addEngagement = (id, raw) => {
  console.log(" engagement data sent ", JSON.stringify(raw));
  return axios
    .post(CLIENTS_ENGAGEMENT_URL(id), raw)
    .then((response) => {
      console.log("success engagement", response);
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      console.log("Error engagement", error.response.data);
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const updateVehicle = (raw, id) => {
  return axios
    .put(UPDATE_CLIENT_VEHICLE_URL(id), raw)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const uploadClientDocument = (raw, config) => {
  return axios
    .post(UPLOAD_CLIENT_DOCUMENT_URL, raw, config)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const uploadInsuranceDocument = (raw, config) => {
  return axios
    .post(UPLOAD_INSURANCE_DOCUMENT_URL, raw, config)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const uploadGeneralDocument = (raw, config) => {
  return axios
    .post(UPLOAD_GENERAL_DOCUMENT_URL, raw, config)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const actionInsuranceCompany = (raw, method) => {
  return axios({
    method: method,
    url: ADD_INSURANCE_COMPANY_URL,
    data: raw,
  })
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const actionGarageCompany = (raw, method) => {
    return axios({
        method: method,
        url: ADD_GARAGE_COMPANY_URL,
        data: raw})
        .then((response) => {
            return {
                error: false,
                payload: response.data
            }
        }).catch((error) => {
            console.log(error.response)
            return {
                error: true,
                payload: error.response.data
            }
        });
};

export const getVehicleInfo = (id) => {
  return axios
    .get(GET_VEHICLE_INFO_URL(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const deleteVehicle = (id) => {
  return axios
    .delete(DELETE_VEHICLE_URL(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const saveUser = (raw) => {
  return axios
    .post(CLIENT_REGISTRATION, raw)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const updateUser = (id, raw) => {
  return axios
    .put(UPDATE_CLIENT_URL(id), raw)
    .then((response) => {
      return {
        error: false,
        payload: response.data.result,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const getClientPolicies = (id) => {
  return axios
    .get(GET_CLIENT_ALL_POLICIES_URL(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const getClientPolicy = (client_id, policy_id) => {
  return axios
    .get(GET_CLIENT_POLICIES_URL(client_id, policy_id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const getTpActRatesPolicyExtension = (id, data) => {
  return axios
    .post(GET_TP_ACT_RATES_POLICY_EXTENSION_URL(id), data)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const extendThirdPartyActOnlyPolicy = (id, data) => {
  return axios
    .post(EXTEND_THIRD_PARTY_ACT_ONLY_POLICY_URL(id), data)
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const getClientDetail = (id) => {
  return axios
    .get(GET_CLIENT_DETAIL_URL(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};

export const getClientDocument = (id) => {
  return axios
    .get(GET_CLIENT_DOCUMENT_URL(id))
    .then((response) => {
      return {
        error: false,
        payload: response.data,
      };
    })
    .catch((error) => {
      return {
        error: true,
        payload: error.response.data,
      };
    });
};
