import React, { useEffect, useState } from "react";
import {
  getClientPolicy,
  getTpActRatesPolicyExtension,
  extendThirdPartyActOnlyPolicy,
  uploadToRtsaAutomatic,
} from "../../../request";
import { gariCodes } from "../../../data/gariCodes";
import $ from "jquery";
import "components-jqueryui";
import "parsleyjs";
import "select2";
import "datatables.net";
import "datatables.net-responsive";
import moment from "moment";
import Marker from "../../../components/Marker";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import formatNumber from "../../../utils/formatNumber";
import cogoToast from "cogo-toast";

Geocode.setApiKey("AIzaSyBPFiN9t9SBWZMQQi9haZJNtbTKD7gIqR0");

// set response language. Defaults to english.
Geocode.setLanguage("en");

const PolicyDetails = (props) => {
  document.title = "Policy Details";

  const [loading, setLoading] = useState(true);
  const [uploadToRtsaLoading, setUploadToRtsaLoading] = useState(false);
  const [vehicleStatus, setVehicleStatus] = useState("");
  const [policyStatus, setPolicyStatus] = useState("");
  const [claimLoading, setClaimLoading] = useState(false);
  const [policyLoading, setPolicyLoading] = useState(false);
  const [rateLoading, setRateLoading] = useState(false);
  const [policyRate, setPolicyRate] = useState({});
  const [productId, setProductId] = useState(null);
  const [policy, setPolicy] = useState();
  const [center, setCenter] = useState({ lat: -15.4422694, lng: 28.3111257 });
  const [userSelected, setUserSelected] = useState(false);
  const [payment, setPayment] = useState([
    { name: "Cash", code: "Cash" },
    { name: "Cheque", code: "Cheque" },
    { name: "ETF", code: "ETF" },
    { name: "Pay Later", code: "PAYLATER" },
  ]);
  const [location, setLocation] = useState("");
  const [zoom, setZoom] = useState(11);

  useEffect(() => {
    getClientPolicy(props.match.params.id, props.match.params._id).then(
      (response) => {
        if (!response.error) {
          setLoading(false);

          //Policy Detail
          setProductId(response.payload.product.id);
          setPolicyStatus(response.payload.status);
          setPolicy(response.payload);

          $("#policyNumber").text(response.payload.policyNumber);
          $("#startDate").text(
            moment(response.payload.startDate)
              .add(1, "days")
              .format("Do MMM, YYYY")
          );
          $("#endDate").text(
            moment(response.payload.endDate).format("Do MMM, YYYY")
          );
          $("#sourceOfBusiness").text(response.payload.sourceOfBusiness);
          $("#certificate")
            .text("View Document")
            .attr(
              "href",
              `https://clientsapi.gari.dev/documents/${props.match.params._id}/certificate`
            )
            .attr("target", "_blank");
          $("#schedule")
            .text("View Document")
            .attr(
              "href",
              `https://clientsapi.gari.dev/documents/${props.match.params._id}/schedule`
            )
            .attr("target", "_blank");
          $("#wordings")
            .text("View Document")
            .attr(
              "href",
              `https://clientsapi.gari.dev/documents/${props.match.params._id}/wordings`
            )
            .attr("target", "_blank");
          $("#clauses")
            .text("View Document")
            .attr(
              "href",
              `https://clientsapi.gari.dev/documents/${props.match.params._id}/clauses`
            )
            .attr("target", "_blank");
          $("#receipt")
            .text("View Document")
            .attr(
              "href",
              `https://clientsapi.gari.dev/documents/${props.match.params._id}/receipt`
            )
            .attr("target", "_blank");
          $("#debit")
            .text("View Document")
            .attr(
              "href",
              `https://clientsapi.gari.dev/documents/${props.match.params._id}/debit-note`
            )
            .attr("target", "_blank");

          //Populate client detail
          $("#type").val(response.payload.client.clientType);
          $("#accountType").text(response.payload.client.clientType);
          $("#name").text(
            `${response.payload.client.firstName} ${
              response.payload.client.middleName
                ? response.payload.client.middleName
                : ""
            } ${response.payload.client.lastName}`
          );
          $("#fullName").text(
            `${response.payload.client.firstName} ${
              response.payload.client.middleName
                ? response.payload.client.middleName
                : ""
            } ${response.payload.client.lastName}`
          );
          $("#gender").text(response.payload.client.gender);
          $("#occupation").text(response.payload.client.occupation);
          $("#mobile").text(response.payload.client.phoneNumber);
          $("#dob").text(
            moment(response.payload.client.dateOfBirth).format("Do MMM, YYYY")
          );
          $("#email").text(response.payload.client.email);
          $("#idType").text(`${response.payload.client.idType}:`);
          $("#idNumber").text(response.payload.client.idNumber || "Blank");
          $("#address").text(
            response.payload.client.physicalAddress || "Blank"
          );
          $("#accountCreated").text(
            moment(response.payload.client.createdAt).format(
              "Do MMM, YYYY - HH:mm"
            )
          );

          //Populate vehicle detail
          setVehicleStatus(response.payload.risks[0].vehicle.status);
          $("#regNumber").text(response.payload.risks[0].vehicle.regNumber);
          $("#make").text(response.payload.risks[0].vehicle.make);
          $("#model").text(response.payload.risks[0].vehicle.model);
          $("#engineNumber").text(
            response.payload.risks[0].vehicle.engineNumber
          );
          $("#chassisNumber").text(
            response.payload.risks[0].vehicle.chassisNumber
          );
          $("#cubicCapacity").text(
            response.payload.risks[0].vehicle.cubicCapacity
          );
          $("#vehicleStatus").text(response.payload.risks[0].vehicle.status);
          $("#yearOfManufacture").text(
            response.payload.risks[0].vehicle.yearOfManufacture
          );
          response.payload.risks[0].vehicle.whiteBookUrl
            ? $("#whitebook")
                .text("View Whitebook")
                .attr(
                  "href",
                  response.payload.risks[0].vehicle.whiteBookUrl || ""
                )
                .attr("target", "_blank")
            : $("#whitebook")
                .text("No Document available")
                .attr("href", "javascript:void(0)");
          response.payload.risks[0].vehicle.licenseUrl
            ? $("#license")
                .text("View License")
                .attr(
                  "href",
                  response.payload.risks[0].vehicle.licenseUrl || ""
                )
                .attr("target", "_blank")
            : $("#license")
                .text("No Document available")
                .attr("href", "javascript:void(0)");
          $("#color").text(response.payload.risks[0].vehicle.color);
          $("#category").text(
            response.payload.risks[0].vehicle.category || "Not Set"
          );
          $("#bodyType").text(
            response.payload.risks[0].vehicle.bodyType || "Not Set"
          );
          $("#createdAt").text(
            moment(response.payload.risks[0].vehicle.createdAt).format(
              "Do MMM, YYYY"
            )
          );
          $("#updatedAt").text(
            moment(response.payload.risks[0].vehicle.updatedAt).format(
              "Do MMM, YYYY"
            )
          );

          //Populate risks details
          $("#riskStartDate").text(
            moment(response.payload.risks[0].startDate).format(
              "Do MMM, YYYY - HH:mm"
            )
          );
          $("#riskEndDate").text(
            moment(response.payload.risks[0].endDate).format(
              "Do MMM, YYYY - HH:mm"
            )
          );
          $("#expiryQuarter").text(
            response.payload.risks[0].expiryQuarter
              ? response.payload.risks[0].expiryQuarter
              : "Not Set"
          );
          $("#sumInsured").text(
            parseFloat(response.payload.risks[0].sumInsured).toFixed(2)
          );
          $("#premiumLevy").text(
            parseFloat(response.payload.risks[0].premiumLevy).toFixed(2)
          );
          $("#basicPremium").text(
            parseFloat(response.payload.risks[0].basicPremium).toFixed(2)
          );
          $("#netPremium").text(
            parseFloat(response.payload.risks[0].netPremium).toFixed(2)
          );
        }
      }
    );
  }, []);

  $(function () {
    $("#Date_from").datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: "0y",
      changeMonth: true,
      changeYear: true,
      defaultDate: "+1w",
      numberOfMonths: 1,
      dateFormat: "yy/mm/dd",
    });
  });

  const checkStatus = (name, status) => {
    if (name === "policy") {
      return {
        Active: <span className="badge badge-success tx-white">{status}</span>,
        Expired: <span className="badge badge-warning tx-white">{status}</span>,
        Cancelled: <span className="badge badge-danger">{status}</span>,
        Inclaim: <span className="badge badge-warning">{status}</span>,
        PayLater: <span className="badge badge-secondary">{status}</span>,
        InClaim: <span className="badge badge-warning">{status}</span>,
        InReview: <span className="badge badge-info">InReview</span>,
      }[status];
    }

    return {
      HasPolicy: <span className="badge badge-success tx-white">{status}</span>,
      Inclaim: <span className="badge badge-warning tx-white">{status}</span>,
      NoPolicy: <span className="badge badge-danger tx-white">{status}</span>,
    }[status];
  };

  const onClaimSubmit = () => {};

  const onCloseModal = (id, form) => {
    let parsley = $(`#${form}`).parsley();
    $(":input", `#${form}`)
      .not(":button, :submit, :reset, :hidden")
      .val("")
      .prop("checked", false)
      .prop("selected", false);
    parsley.reset();
    parsley.destroy();
    $(`#${id}`).modal("hide");
    $(".modal-backdrop").remove();
  };

  const handleShowClaim = () => {
    $("#basicClaim").parsley();
    $("#modalClaim").modal({ backdrop: "static", keyboard: false }, "show");
  };

  const handleShowRenew = () => {
    $("#basicPolicy").parsley();
    $("#basicPayment").parsley();
    $("#modalPolicy").modal({ backdrop: "static", keyboard: false }, "show");
  };

  const handleMapClick = (e) => {
    setUserSelected(true);
    setCenter({ lat: e.lat, lng: e.lng });
    setLocation(
      `latitude: ${e.lat.toFixed(2)}, longitude: ${e.lng.toFixed(2)}`
    );
    Geocode.fromLatLng(e.lat, e.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const route = response.results[0].address_components.filter((data) => {
          return (
            data.types[0] ==
            data.types.filter((result) => {
              return result == "route";
            })
          );
        });
        const city = response.results[0].address_components.filter((data) => {
          return (
            data.types[0] ==
            data.types.filter((result) => {
              return result == "locality";
            })
          );
        });

        $("#map_address").val(address);
        $("#town").val(city[0].long_name);
        $("#city").val(city[0].long_name);
        $("#street").val(route[0].long_name);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onRateSubmit = (e) => {
    e.preventDefault();
    setRateLoading(true);

    let data = {
      policyStartDate: new Date($("#Date_from").val()),
      quarters: $("#quarter").val(),
    };

    getTpActRatesPolicyExtension(props.match.params._id, data).then(
      (response) => {
        setRateLoading(false);
        if (!response.error) {
          setPolicyRate(response.payload);
          let {
            startDate,
            endDate,
            currentStartDate,
            currentEndDate,
            quarters,
            premiumLevy,
            basicPremium,
            netPremium,
          } = response.payload;

          $("#rateStartDate").text(moment(startDate).format("Do MMM, YYYY"));
          $("#rateEndDate").text(moment(endDate).format("Do MMM, YYYY"));
          $("#rateCurrentStartDate").text(
            moment(currentStartDate).format("Do MMM, YYYY")
          );
          $("#rateCurrentEndDate").text(
            moment(currentEndDate).format("Do MMM, YYYY")
          );
          $("#rateQuarter").text(quarters);
          $("#ratePremiumLevy").text(formatNumber(premiumLevy.toFixed(2)));
          $("#rateBasicPremium").text(formatNumber(basicPremium.toFixed(2)));
          $("#rateNetPremium").text(formatNumber(netPremium.toFixed(2)));

          $("#amount").val(formatNumber(response.payload.netPremium));
          $("#nav_policy .active")
            .parent()
            .next("li")
            .find("a")
            .trigger("click");
        } else {
          cogoToast.error(response.payload.message, {
            position: "top-right",
            hideAfter: 4,
          });
        }
      }
    );
  };

  const policySubmit = (e) => {
    e.preventDefault();
    setPolicyLoading(true);

    let data = {
      clientId: props.match.params.id,
      productId: productId,
      endDate: policyRate.endDate,
      quarters: $("#quarter").val(),
      paymentMethod: $("#payment").val(),
      accountNumber: $("#account_number").val(),
      amount: policyRate.netPremium,
    };

    extendThirdPartyActOnlyPolicy(props.match.params._id, data).then(
      (response) => {
        if (!response.error) {
          onCloseModal.bind(null, "modalPolicy", "basicPolicy");
          cogoToast.success("Policy successfully Extended.", {
            position: "top-right",
            hideAfter: 2,
          });

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setPolicyLoading(false);
        }
      }
    );
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    $("#nav_policy .active").parent().prev("li").find("a").trigger("click");
  };

  const handleRtsaUpload = (e) => {
    setUploadToRtsaLoading(true);

    cogoToast.loading("uploading your policy...").then(() => {
      console.log("policy id", JSON.stringify(policy.id));
      uploadToRtsaAutomatic(policy.id).then((response) => {
        console.log("response", response);
        if (response.error) {
          setUploadToRtsaLoading(false);
          cogoToast.error(" Error: " + response.payload.message);
        } else {
          setUploadToRtsaLoading(false);
          cogoToast.success("Policy uploaded Successfully ");
        }
      });
    });
  };

  if (!loading) {
    return (
      <div className="content-body">
        <div className="container pd-x-0">
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <a href="#">Menu</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Policy</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Policy Details
                  </li>
                </ol>
              </nav>
              <h4 id="policyNumber" className="mg-b-0 tx-spacing--1">
                ---
              </h4>
            </div>
            <div className="d-none d-md-block">
              {policyStatus === "Active" ? (
                <button
                  className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none"
                  onClick={handleShowRenew.bind()}
                >
                  <i className="wd-10 mg-r-5 fa fa-refresh"></i> Extend Policy
                </button>
              ) : null}
              {policyStatus === "Expired" ? (
                <button
                  className="btn btn-sm pd-x-15 btn-brand-02 btn-uppercase mg-l-5 outline-none"
                  onClick={handleShowRenew.bind()}
                >
                  <i className="wd-10 mg-r-5 fa fa-refresh"></i> Renew Policy
                </button>
              ) : null}

              <button
                className="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-l-5 outline-none"
                onClick={handleShowClaim.bind()}
              >
                <i className="wd-10 mg-r-5 fa fa-edit"></i> Intimate Claim
              </button>
              <button
                className="btn btn-sm pd-x-15 btn-outline-primary  btn-uppercase  mg-l-5 outline-none"
                role="button"
                disabled={uploadToRtsaLoading}
                aria-expanded="false"
                onClick={() => {
                  handleRtsaUpload();
                }}
              >
                {uploadToRtsaLoading ? (
                  <div>
                    {" "}
                    <i className="wd-10 mg-r-5 fa fa-file"></i>
                    Uploading{" "}
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {" "}
                    <i className="wd-10 mg-r-5 fa fa-file"></i>Upload to RTSA
                  </>
                )}
              </button>
            </div>
          </div>

          <div className="row row-xs">
            <div className="col-md-12 mg-t-10">
              <div className="nav-wrapper mg-b-20 tx-13">
                <div>
                  <ul
                    id="nav_basic"
                    className="nav nav-line nav-fill tx-medium"
                  >
                    <li className="nav-item">
                      <a
                        href="#policy"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        <i className="ion-md-information-circle" /> Policy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#client" className="nav-link" data-toggle="tab">
                        <i className="ion-md-person" /> Client
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#vehicle" className="nav-link" data-toggle="tab">
                        <i className="ion-md-car" /> Vehicle
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tab-content">
                <div id="policy" className="tab-pane fade active show">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <h5 className="tx-spacing--1 tx-color-03">Basic</h5>
                      <p>
                        <strong>Start Date:</strong>{" "}
                        <span id="startDate">Blank</span>
                      </p>
                      <p>
                        <strong>End Date:</strong>{" "}
                        <span id="endDate">Blank</span>
                      </p>
                      <p>
                        <strong>Status:</strong>{" "}
                        <span id="status">
                          {checkStatus("policy", policyStatus)}
                        </span>
                      </p>
                      <p>
                        <strong>Source Of Business:</strong>{" "}
                        <span id="sourceOfBusiness">Blank</span>
                      </p>
                      <p>
                        <strong>Receipted Status:</strong>{" "}
                        <span id="receiptStatus">Blank</span>
                      </p>

                      <hr />
                      <h5 className="tx-spacing--1 tx-color-03">Risk</h5>
                      <p>
                        <strong>Start Date:</strong>{" "}
                        <span id="riskStartDate">Blank</span>
                      </p>
                      <p>
                        <strong>End Date:</strong>{" "}
                        <span id="riskEndDate">Blank</span>
                      </p>
                      <p>
                        <strong>Expiry Quarter:</strong>{" "}
                        <span id="expiryQuarter">Blank</span>
                      </p>
                      <p>
                        <strong>Sum Insured:</strong>{" "}
                        <span id="sumInsured">Blank</span>
                      </p>
                      <p>
                        <strong>Premium Levy:</strong>{" "}
                        <span id="premiumLevy">Blank</span>
                      </p>
                      <p>
                        <strong>Basic Premium:</strong>{" "}
                        <span id="basicPremium">Blank</span>
                      </p>
                      <p>
                        <strong>Net Premium:</strong>{" "}
                        <span id="netPremium">Blank</span>
                      </p>

                      <hr />
                      <h5 className="tx-spacing--1 tx-color-03">Discounts</h5>
                      <p className="tx-danger">No Discount</p>

                      <hr />
                      <h5 className="tx-spacing--1 tx-color-03">Extension</h5>
                      <p className="tx-danger">No Extension</p>

                      <hr />
                      <h5 className="tx-spacing--1 tx-color-03">
                        Limits Of Liability
                      </h5>
                      <p className="tx-danger">No Limits Of Liability</p>

                      <hr />
                      <h5 className="tx-spacing--1 tx-color-03">
                        Policy Documents
                      </h5>
                      <p>
                        <strong>Certificate of Insurance:</strong>{" "}
                        <a href="#" id="certificate">
                          No Document
                        </a>
                      </p>
                      <p>
                        <strong>Policy Schedule:</strong>{" "}
                        <a href="#" id="schedule">
                          No Document
                        </a>
                      </p>
                      <p>
                        <strong>Policy Wordings:</strong>{" "}
                        <a href="#" id="wordings">
                          No Document
                        </a>
                      </p>
                      <p>
                        <strong>Policy Clauses:</strong>{" "}
                        <a href="#" id="clauses">
                          No Document
                        </a>
                      </p>
                      <p>
                        <strong>Debit Note:</strong>{" "}
                        <a href="#" id="debit">
                          No Document
                        </a>
                      </p>
                      <p>
                        <strong>Receipt:</strong>{" "}
                        <a href="#" id="receipt">
                          No Document
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div id="client" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <p>
                        <strong>Account Type:</strong>{" "}
                        <span id="accountType">Blank</span>
                      </p>
                      <p>
                        <strong>Full Name:</strong>{" "}
                        <span id="fullName">Blank</span>
                      </p>
                      <p>
                        <strong>DOB:</strong> <span id="dob">Blank</span>
                      </p>
                      <p>
                        <strong>Gender:</strong> <span id="gender">Blank</span>
                      </p>
                      <p>
                        <strong>Occupation:</strong>{" "}
                        <span id="occupation">Blank</span>
                      </p>
                      <p>
                        <strong>Mobile Number:</strong>{" "}
                        <span id="mobile">Blank</span>
                      </p>
                      <p>
                        <strong id="idType">Passport:</strong>{" "}
                        <span id="idNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Email:</strong> <span id="email">Blank</span>
                      </p>
                      <p>
                        <strong>Physical Address:</strong>{" "}
                        <span id="address">Blank</span>
                      </p>
                      <p>
                        <strong>Account Created:</strong>{" "}
                        <span id="accountCreated">Blank</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div id="vehicle" className="tab-pane fade">
                  <div className="row row-sm">
                    <div className="col-md-12">
                      <p>
                        <strong>Registration #:</strong>{" "}
                        <span id="regNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Make:</strong> <span id="make">Blank</span>
                      </p>
                      <p>
                        <strong>Model:</strong> <span id="model">Blank</span>
                      </p>
                      <p>
                        <strong>Engine Number:</strong>{" "}
                        <span id="engineNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Chassis Number:</strong>{" "}
                        <span id="chassisNumber">Blank</span>
                      </p>
                      <p>
                        <strong>Cubic Capacity:</strong>{" "}
                        <span id="cubicCapacity">Blank</span>
                      </p>
                      <p>
                        <strong>Color:</strong> <span id="color">Blank</span>
                      </p>
                      <p>
                        <strong>Category:</strong>{" "}
                        <span id="category">Blank</span>
                      </p>
                      <p>
                        <strong>body Type:</strong>{" "}
                        <span id="bodyType">Blank</span>
                      </p>
                      <p>
                        <strong>Created At:</strong>{" "}
                        <span id="createdAt">Blank</span>
                      </p>
                      <p>
                        <strong>Updated At:</strong>{" "}
                        <span id="updatedAt">Blank</span>
                      </p>
                      <p>
                        <strong>Status:</strong>{" "}
                        <span>{checkStatus("vehicle", vehicleStatus)}</span>
                      </p>
                      <p>
                        <strong>Year Of Manufacture:</strong>{" "}
                        <span id="yearOfManufacture">Blank</span>
                      </p>
                      <p>
                        <strong>License:</strong>{" "}
                        <a href="#" id="license">
                          View License
                        </a>
                      </p>
                      <p>
                        <strong>White Book:</strong>{" "}
                        <a href="#" id="whitebook">
                          View Whitebook
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="modalPolicy"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered wd-650"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                  <a
                    href="#"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </a>
                  <div className="media-body">
                    <a
                      href="javascript:void(0)"
                      role="button"
                      className="close pos-absolute t-15 r-15"
                      onClick={onCloseModal.bind(
                        null,
                        "modalPolicy",
                        "basicPayment"
                      )}
                    >
                      <span aria-hidden="true">×</span>
                    </a>
                    <h4 className="tx-18 tx-sm-20 mg-b-2">
                      {policyStatus === "Active"
                        ? "Policy Extension"
                        : "Policy Renew"}
                    </h4>
                    <p className="tx-13 tx-color-02 mg-b-0">
                      Fill the information below.
                    </p>
                  </div>
                </div>
                <div className="modal-body pd-sm-b-40 pd-sm-x-30">
                  <div className="nav-wrapper mg-b-20 tx-13">
                    <div>
                      <ul
                        id="nav_policy"
                        className="nav nav-line nav-fill tx-medium nav-disabled"
                      >
                        <li className="nav-item">
                          <a
                            href="#basic"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            <i className="ion-md-information-circle" /> Policy
                            Form
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#confirmation"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            <i className="ion-ios-checkmark-circle" />{" "}
                            Confirmation
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div id="basic" className="tab-pane fade active show">
                      <form
                        id="basicPolicy"
                        className="parsley-style-1"
                        onSubmit={onRateSubmit.bind()}
                      >
                        <div className="row row-sm">
                          <div
                            id="makeWrapper"
                            className="form-group parsley-input col-sm-12"
                          >
                            <label>
                              Start Date<span className="tx-danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="Date_from"
                              className="form-control"
                              placeholder="Select Start Date"
                              autoComplete="off"
                              data-parsley-class-handler="#dateWrapper"
                              required
                              readOnly
                            />
                          </div>

                          <div
                            id="quarterWrapper"
                            className="form-group parsley-input col-sm-12"
                          >
                            <label>
                              Quarter<span className="tx-danger">*</span>
                            </label>
                            <select
                              className="custom-select"
                              id="quarter"
                              required
                            >
                              <option value="" disabled selected>
                                Select
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          </div>
                        </div>
                        {rateLoading ? (
                          <button
                            disabled
                            className="btn btn-brand-02 btn-block mt-2"
                          >
                            <span
                              className="spinner-border spinner-border-sm mg-r-10"
                              role="status"
                              aria-hidden="true"
                            />
                            Please Wait
                          </button>
                        ) : (
                          <button className="btn btn-brand-02 btn-block mt-2">
                            Check Rate <i className="ion-md-arrow-forward" />
                          </button>
                        )}
                      </form>
                    </div>

                    <div id="confirmation" className="tab-pane fade">
                      <div className="row row-sm">
                        <div className="col-md-12">
                          <h6 className="tx-purple">Rate Calculation</h6>
                          <p>
                            <strong>Start Date:</strong>{" "}
                            <span id="rateStartDate">Not Set</span>
                          </p>
                          <p>
                            <strong>End Date:</strong>{" "}
                            <span id="rateEndDate">Not Set</span>
                          </p>
                          <p>
                            <strong>Current Start Date:</strong>{" "}
                            <span id="rateCurrentStartDate">Not Set</span>
                          </p>
                          <p>
                            <strong>Current End Date:</strong>{" "}
                            <span id="rateCurrentEndDate">Not Set</span>
                          </p>
                          <p>
                            <strong>Quarters:</strong>{" "}
                            <span id="rateQuarter">Not Set</span>
                          </p>
                          <p>
                            <strong>Premium Levy:</strong>{" "}
                            <span id="ratePremiumLevy">Not Set</span>
                          </p>
                          <p>
                            <strong>Basic Premium:</strong>{" "}
                            <span id="rateBasicPremium">Not Set</span>
                          </p>
                          <p>
                            <strong>Net Premium:</strong>{" "}
                            <span id="rateNetPremium">Not Set</span>
                          </p>
                        </div>

                        <div className="col-md-12">
                          <form
                            id="basicPayment"
                            className="parsley-style-1"
                            onSubmit={policySubmit.bind()}
                          >
                            <div className="row">
                              <div
                                id="amountWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Amount<span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="amount"
                                  className="form-control"
                                  placeholder="Enter amount"
                                  type="number"
                                  autoComplete="off"
                                  data-parsley-class-handler="#amountWrapper"
                                  required
                                  disabled
                                />
                              </div>
                              <div
                                id="paymentWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Payment Method
                                  <span className="tx-danger">*</span>
                                </label>
                                <select
                                  className="custom-select"
                                  id="payment"
                                  required
                                >
                                  <option value="" disabled selected>
                                    Select
                                  </option>
                                  {payment.map((data) => {
                                    return (
                                      <option value={data.code}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              <div
                                id="yearWrapper"
                                className="form-group parsley-input col-sm-12"
                              >
                                <label>
                                  Account Number
                                  <span className="tx-danger">*</span>
                                </label>
                                <input
                                  id="account_number"
                                  className="form-control"
                                  placeholder="Enter account Number"
                                  type="number"
                                  autoComplete="off"
                                  data-parsley-class-handler="#yearWrapper"
                                  required
                                />
                              </div>
                            </div>
                            <hr />
                            <a
                              href="#"
                              className="btn btn-dark float-left btnPrevious"
                              onClick={handlePrevious.bind()}
                            >
                              <i className="ion-md-arrow-back" /> Back
                            </a>

                            {policyLoading ? (
                              <button
                                disabled
                                className="btn btn-brand-02 float-right"
                              >
                                <span
                                  className="spinner-border spinner-border-sm mg-r-10"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Please Wait
                              </button>
                            ) : (
                              <button className="btn btn-brand-01 float-right btnNextAd">
                                Proceed <i className="ion-md-arrow-forward" />
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="modalManualRtsa"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered wd-sm-650"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                    <a
                      href="#"
                      role="button"
                      className="close pos-absolute t-15 r-15"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </a>
                    <div className="media-body">
                      <a
                        href="javascript:void(0)"
                        role="button"
                        className="close pos-absolute t-15 r-15"
                        onClick={onCloseModal.bind(
                          null,
                          "modalManualRtsa",
                          "basicPayment"
                        )}
                      >
                        <span aria-hidden="true">×</span>
                      </a>
                      <h4 className="tx-18 tx-sm-20 mg-b-2">
                        Upload Policy To RTSA
                      </h4>
                      <p className="tx-13 tx-color-02 mg-b-0">
                        Fill the information below.
                      </p>
                    </div>
                  </div>
                  <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                    <form
                      id="basicRtsa"
                      className="row parsley-style-1"
                      // onSubmit={onClaimSubmit.bind()}
                    >
                      <div
                        id="numPlateWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>Number Plate</label>
                        <input
                          id="registrationNumber"
                          className="form-control"
                          placeholder="Enter Vehicle Number Plate"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#numPlateWrapper"
                        />
                      </div>
                      <div
                        id="numPlateWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>Policy Number</label>
                        <input
                          id="registrationNumber"
                          className="form-control"
                          placeholder="Enter Vehicle Number Plate"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#numPlateWrapper"
                        />
                      </div>
                      <div
                        id="numPlateWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>Number Plate</label>
                        <input
                          id="registrationNumber"
                          className="form-control"
                          placeholder="Enter Vehicle Number Plate"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#numPlateWrapper"
                        />
                      </div>
                      <div
                        id="coverTypeWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>
                          Select Insurance Company
                          <span className="tx-danger"></span>
                        </label>
                        <select className="custom-select" id="coverType">
                          <option value="" disabled selected>
                            Select
                          </option>
                          {gariCodes.map((data) => (
                            <option value={data.code}>
                              {data.description}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        id="coverTypeWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>
                          Cover Type<span className="tx-danger"></span>
                        </label>
                        <select className="custom-select" id="coverType">
                          <option value="" disabled selected>
                            Select
                          </option>
                          {gariCodes.map((data) => (
                            <option value={data.code}>
                              {data.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="modalClaim"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                  <a
                    href="#"
                    role="button"
                    className="close pos-absolute t-15 r-15"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </a>
                  <div className="media-body">
                    <a
                      href="javascript:void(0)"
                      role="button"
                      className="close pos-absolute t-15 r-15"
                      onClick={onCloseModal.bind(
                        null,
                        "modalPolicy",
                        "basicPayment"
                      )}
                    >
                      <span aria-hidden="true">×</span>
                    </a>
                    <h4 className="tx-18 tx-sm-20 mg-b-2">Intimate Claim</h4>
                    <p className="tx-13 tx-color-02 mg-b-0">
                      Fill the information below.
                    </p>
                  </div>
                </div>
                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                  <form
                    id="basicClaim"
                    className="parsley-style-1"
                    onSubmit={onClaimSubmit.bind()}
                  >
                    <div className="row row-sm">
                      <div
                        style={{
                          height: "500px",
                          width: "100%",
                          marginBottom: 30,
                        }}
                      >
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyC1DRm6IlqK5wj-iwSeycRXAA7rYlmZ7lk",
                          }}
                          defaultCenter={center}
                          defaultZoom={zoom}
                          onClick={handleMapClick.bind()}
                        >
                          {userSelected ? (
                            <Marker
                              lat={center.lat}
                              lng={center.lng}
                              name="My Marker"
                              color="red"
                            />
                          ) : null}
                        </GoogleMapReact>
                      </div>

                      <div
                        id="makeWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>
                          City<span className="tx-danger">*</span>
                        </label>
                        <input
                          id="city"
                          className="form-control"
                          placeholder="No Selection"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#makeWrapper"
                          disabled
                          required
                        />
                      </div>

                      <div
                        id="modelWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>
                          Town<span className="tx-danger">*</span>
                        </label>
                        <input
                          id="town"
                          className="form-control"
                          placeholder="No Selection"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#modelWrapper"
                          disabled
                          required
                        />
                      </div>

                      <div
                        id="streetWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>
                          Street<span className="tx-danger">*</span>
                        </label>
                        <input
                          id="street"
                          className="form-control"
                          placeholder="No Selection"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#streetWrapper"
                          disabled
                          required
                        />
                      </div>

                      <div
                        id="addressWrapper"
                        className="form-group parsley-input col-sm-6"
                      >
                        <label>
                          Address<span className="tx-danger">*</span>
                        </label>
                        <input
                          id="map_address"
                          className="form-control"
                          placeholder="No Selection"
                          type="text"
                          autoComplete="off"
                          data-parsley-class-handler="#addressWrapper"
                          disabled
                          required
                        />
                      </div>

                      <div
                        id="descriptionWrapper"
                        className="form-group parsley-input col-sm-12"
                      >
                        <label>
                          Description<span className="tx-danger">*</span>
                        </label>
                        <textarea
                          id="description"
                          className="form-control"
                          placeholder="No Selection"
                          rows={5}
                          autoComplete="off"
                          data-parsley-class-handler="#descriptionWrapper"
                          required
                        />
                      </div>
                    </div>
                    {claimLoading ? (
                      <button disabled className="btn btn-brand-02 btn-block">
                        <span
                          className="spinner-border spinner-border-sm mg-r-10"
                          role="status"
                          aria-hidden="true"
                        />
                        Please Wait
                      </button>
                    ) : (
                      <button className="btn btn-brand-02 btn-block mt-2">
                        <i className="ion-md-add" /> Intimate Claim
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-body">
      <div className="container d-flex justify-content-center ht-100p">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mg-t-10">Collecting Data</p>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetails;
