import axios from "axios";
import {CREATE_QUOTATION_URL, GET_QUOTATION_ID_URL, GET_POLICY_RECEIPT_URL, MAKE_PAYMENT_URL, ADD_QUOTATION_DISCOUNT, ADD_QUOTATION_EXTENSION, ADD_QUOTATION_LIMIT_OF_LIABILITY} from "../api";

export const getQuotation = (id) => {
    return axios.get(GET_QUOTATION_ID_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};

export const getPolicyReceipt = (clientId, id) => {
    return axios.get(GET_POLICY_RECEIPT_URL(clientId, id)).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};

export const createQuotation = (raw, id) => {
    return axios.post(CREATE_QUOTATION_URL(id), raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error.response.data
        }
    });
};

export const makePayment = (raw) => {
    return axios.post(MAKE_PAYMENT_URL, raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};

export const addDiscount = (raw) => {
    return axios.post(ADD_QUOTATION_DISCOUNT, raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};

export const addExtension = (raw) => {
    return axios.post(ADD_QUOTATION_EXTENSION, raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};

export const addLimitOfLiability = (raw) => {
    return axios.post(ADD_QUOTATION_LIMIT_OF_LIABILITY, raw).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        return {
            error: true,
            payload: error
        }
    });
};
