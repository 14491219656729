import React, {useEffect, useState} from 'react';
import {GET_PRODUCT_URL} from '../../../api';
import {saveProduct, uploadProductDocument} from '../../../request';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import 'select2';
import 'datatables.net';
import 'datatables.net-responsive';
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
import truncate from "../../../utils/trucate";
import products from "../../../data/products.json";
import {decodeQueryParameter} from "../../../utils/url";

var wordingsData = [];
var clausesData = [];
var perilsData = [];

const Products = (props) => {

    document.title = "Products";

    let {id, name, logoUrl, registrationNumber, companyAddress, companyEmail} = decodeQueryParameter(props.match.params.insurance);

    const [createLoading, setCreateLoading] = useState(false);
    const [perilsName, setPerilsName] = useState([]);
    const [perilsType, setPerilsType] = useState([]);
    const {gariCode} = useSelector((state) => state.organization.gariCode);

    console.log(decodeQueryParameter(props.match.params.insurance))

    useEffect(() => {
        var table = $('#product').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Product Data</p>'
            },
            "searching": false,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_PRODUCT_URL(id), {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        res.data.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.totalRecords,
                            recordsFiltered: res.totalRecords,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.code;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.policyNumberPrefix;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.claimNumberPrefix;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return data.gariCode ? data.gariCode.code : "Not Set";
                    },
                    "targets": 4
                }
            ]
        });

        $('#product tbody').on('click', 'tr', function () {
            var extract_data = table.row(this).data();
            props.history.push(`/product-details/${extract_data.raw.id}`);
        });
    }, []);

    const handleShowModal = () => {

        $('#wordingForm').parsley();
        $("#clausesForm").parsley();
        $('#basicInfo').parsley();

        $('#perilsName').prop('disabled', true);

        $('#td-wordings').DataTable({
            responsive: true,
            "searching": false,
            "lengthMenu": [[10], [10]],
            data: wordingsData,
            ordering: false,
            info: false,
            bFilter: false,
            processing: false,
            pageLength: 10,
            serverSide: false,
            "columnDefs": [
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 1
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 2
                }
            ]
        });

        $('#td-clauses').DataTable({
            responsive: true,
            "searching": false,
            "lengthMenu": [[10], [10]],
            data: clausesData,
            ordering: false,
            info: false,
            bFilter: false,
            processing: false,
            pageLength: 10,
            serverSide: false,
            "columnDefs": [
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 1
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 2
                }
            ]
        });

        $('#td-perils').DataTable({
            responsive: true,
            "searching": false,
            "lengthMenu": [[10], [10]],
            data: perilsData,
            ordering: false,
            info: false,
            bFilter: false,
            processing: false,
            pageLength: 10,
            serverSide: false,
            "columnDefs": [
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 1
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 2
                }
            ]
        });

        $("#modalAddProduct").modal({backdrop: 'static', keyboard: false}, "show");
    };

    const handleBasicSubmit = (e) => {
        e.preventDefault();
        $('#nav_basic .active').parent().next('li').find('a').trigger('click');
    };

    const handleProductSubmit = async(e) => {
        e.preventDefault();

        setCreateLoading(true);

        if(!perilsData.length) return cogoToast.error("Please, enter at least one perils or more.");

        let raw = {
            "name": $("#name").val(),
            "code": $("#code").val(),
            "description": $("#description").val(),
            "policyNumberPrefix": $("#policyNumber").val(),
            "claimNumberPrefix": $("#claimNumber").val(),
            "premiumRates": {
                "premium": $("#premiumName").val(),
                "levy": $("#levy").val(),
                "maximumLimit": $("#maximumLimit").val(),
                "minimumLimit": $("#minimumLimit").val()
            },
            "gariCodeId": $("#gariCode").val(),
            "perils": perilsData.map((data) => (
                {
                    name: data[0],
                    type: data[1],
                    description: data[2]
                }
            )),
            "policyWordings": [
                {
                    documentUrl: await uploadDocument("wordingsFile"),
                    description: $("#wordingsDescription").val()
                }
            ],
            "policyClauses": [
                {
                    documentUrl: await uploadDocument("clausesFile"),
                    description: $("#clausesDescription").val()
                }
            ]
        }

        saveProduct(raw, id).then((res) => {
            const options = {
                position: "top-right"
            };

            setCreateLoading(false);

            if(res.error) {
                setCreateLoading(false);
                return cogoToast.error("Something went wrong.", options)
            }

            $("#modalAddProduct").modal("hide");
            $(".modal-backdrop").remove();
            $('#product').DataTable().ajax.reload();

            perilsData = [];
            cogoToast.success("Product successfully Added.", options);
        });
    };

    const handleResetForm = () => {
        destroyForm( "basicInfo");
        destroyForm( "wordingForm");
        destroyForm( "clausesForm");
        destroyForm( "perilsForm");
        $('#td-wordings').DataTable().destroy();
        $('#td-clauses').DataTable().destroy();
        $('#td-perils').DataTable().destroy();
        $('#perilsName').prop('disabled', true);
        $("#modalAddProduct").modal("hide");
        $(".modal-backdrop").remove();
        $('#nav_basic a:first').tab('show');
        perilsData = [];
    };

    const destroyForm = (form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        parsley.reset();
        parsley.destroy();
    };

    const handleWordingSubmit = (e) => {
        e.preventDefault();
        $('#nav_basic .active').parent().next('li').find('a').trigger('click');
    };

    const handleClausesSubmit = (e) => {
        e.preventDefault();
        $('#nav_basic .active').parent().next('li').find('a').trigger('click');
    };

    const handlePerilsSubmit = (e) => {
        e.preventDefault();

        perilsData = [
            [$('#perilsName').val(), $('#perilsType').val(), $('#perilsDescription').val(), Math.random()], ...perilsData
        ];

        $('#td-perils').DataTable().destroy();

        reloadTable("td-perils", perilsData);

        $('#perilsName').val(null);
        $('#perilsType').val(null);
        $('#perilsDescription').val(null);
    };

    const reloadTable = (id, data) => {
        let table = $(`#${id}`).DataTable({
            responsive: true,
            "searching": false,
            "lengthMenu": [[3], [3]],
            data: data,
            ordering: false,
            info: false,
            bFilter: false,
            processing: false,
            pageLength: 3,
            serverSide: false,
            "columnDefs": [
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return truncate(data, 25, "...");
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_list" data-id=${data}><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_list"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 2
                }
            ]
        });

        $(`#${id}`).on('click', '.edit_list', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            if(extract_td !== undefined) {
                if(`${id.split('-').pop()}Data` === "perilsData") {
                    $(`#${id.split('-').pop()}Name`).val(extract_td[0]);
                    $(`#${id.split('-').pop()}Type`).val(extract_td[1]);
                    $(`#${id.split('-').pop()}Description`).val(extract_td[2]);
                } else {
                    $(`#${id.split('-').pop()}Heading`).val(extract_td[0]);
                    $(`#${id.split('-').pop()}Description`).val(extract_td[1]);
                }
            }

            return false;
        });

        $(`#${id}`).on('click', '.delete_list', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            if(extract_td !== undefined) {
                if(`${id.split('-').pop()}Data` === "wordingsData") {
                    wordingsData = wordingsData.filter((data) => {
                        return data[2] !== extract_td[2];
                    });

                    $(`#${id.split('-').pop()}Heading`).val(null);
                    $(`#${id.split('-').pop()}Description`).val(null);

                    reloadTable("td-wordings", wordingsData);

                } else if(`${id.split('-').pop()}Data` === "clausesData") {
                    clausesData = clausesData.filter((data) => {
                        return data[2] !== extract_td[2];
                    });

                    $(`#${id.split('-').pop()}Heading`).val(null);
                    $(`#${id.split('-').pop()}Description`).val(null);

                    reloadTable("td-clauses", clausesData);

                } else {
                    perilsData = perilsData.filter((data) => {
                        return data[2] !== extract_td[2];
                    });

                    $(`#PerilsName`).val(null);
                    $(`#PerilsDescription`).val(null);
                    $(`#PerilsType`).val(null);

                    reloadTable("td-perils", perilsData);

                }
            }

            return false;
        });
    };

    const uploadDocument = (id) => {
        let [files] = $(`#${id}`).prop('files');

        if(files === undefined) return null;

        const formData = new FormData();

        formData.append('File', files);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            encType: "multipart/form-data"
        };

        return uploadProductDocument(formData, config).then((result) => {
            if(!result.error) {
                return result.payload.url;
            }

            return null;
        });
    }

    const onProductNameChange = (e) => {
        e.preventDefault();
        let findData = products.find((data) => {
            return data.name === e.target.value;
        });

        $('#perilsType').val(null);
        $('#perilsName').val(null);

        setPerilsType(findData.type);
    };

    const onPerilsTypeChange = (e) => {
        e.preventDefault();
        let findData = perilsType.find((data) => {
            return data.name === e.target.value;
        });

        $('#perilsName').prop('disabled', false);

        setPerilsName(findData.data);
    };

    const handlePrevious = (e) => {
        e.preventDefault();
        $('#nav_basic .active').parent().prev('li').find('a').trigger('click');
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div className="media">
                        <div className="wd-100 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center">
                            <img src={logoUrl} className="img-fluid rounded-5"/>
                        </div>
                        <div className="media-body pd-l-25 my-auto">
                            <h5 className="mg-b-5">{name}</h5>
                            <p className="mg-b-3"><span
                                className="tx-medium tx-color-02">{registrationNumber}</span><br/>{companyAddress}</p>
                            <span className="d-block tx-13 tx-color-03">{companyEmail}</span>
                        </div>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Product</h6>
                                <div className="d-flex tx-18">
                                    <a href="javascript:void(0)" className="link-03 lh-0" onClick={() => $('#product').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="product" className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Code</th>
                                        <th className="wd-20p">Policy Prefix</th>
                                        <th className="wd-20p">Claim Prefix</th>
                                        <th className="wd-20p">Gari Code</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAddProduct" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="javascript:void(0)" role="button" className="close pos-absolute t-15 r-15" onClick={handleResetForm.bind()} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Create Product</h4><p className="tx-13 tx-color-02 mg-b-0">Create products to be added to the system</p></div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium nav-disabled">
                                        <li className="nav-item"><a href="#basic" className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> Basic</a></li>
                                        <li className="nav-item"><a href="#wordings" className="nav-link" data-toggle="tab"><i className="ion-md-document"/> Wordings</a></li>
                                        <li className="nav-item"><a href="#clauses" className="nav-link" data-toggle="tab"><i className="ion-md-document"/> Clauses</a></li>
                                        <li className="nav-item"><a href="#perils" className="nav-link" data-toggle="tab"><i className="ion-md-document"/> Perils</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <form id="basicInfo" className="parsley-style-1" onSubmit={handleBasicSubmit.bind()}>
                                        <div className="row row-sm">
                                            <div id="gariWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Gari Code<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="gariCode" required>
                                                    <option value="" disabled selected>Select</option>
                                                    {gariCode.map((data) => <option value={data.id}>{data.code}</option>)}
                                                </select>
                                            </div>

                                            <div id="nameWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Name<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="name" onChange={onProductNameChange.bind()} required>
                                                    <option value="" disabled selected>Select</option>
                                                    {products.map((data) => <option value={data.name}>{data.name}</option>)}
                                                </select>
                                            </div>

                                            <div id="codeWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Code</label>
                                                <input id="code" className="form-control"
                                                       placeholder="Enter code"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#codeWrapper" required/>
                                            </div>

                                            <div id="descriptionWrapper" className="form-group parsley-input col-sm-12">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description</label>
                                                <textarea id="description" rows="5" className="form-control" placeholder="Provide a description of the property" data-parsley-class-handler="#descriptionWrapper" required/>
                                            </div>

                                            <div id="premiumNameWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Premium Rate(%)<span className="tx-danger">*</span></label>
                                                <input id="premiumName" className="form-control"
                                                       placeholder="Enter amount"
                                                       type="number"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#premiumNameWrapper" required/>
                                            </div>

                                            <div id="levyWrapper" className="form-group parsley-input col-sm-4">
                                                <label>Levy Rate(%)</label>
                                                <input id="levy" className="form-control"
                                                       placeholder="Enter levy"
                                                       type="number"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#levyWrapper" required/>
                                            </div>

                                            <div id="minimumLimitWrapper" className="form-group parsley-input col-sm-4">
                                                <label>Minimum Limit Rate(%)</label>
                                                <input id="minimumLimit" className="form-control"
                                                       placeholder="Enter minimum limit"
                                                       type="number"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#minimumLimitWrapper" required/>
                                            </div>

                                            <div id="maximumLimitWrapper" className="form-group parsley-input col-sm-4">
                                                <label>Maximum Limit Rate(%)</label>
                                                <input id="maximumLimit" className="form-control"
                                                       placeholder="Enter maximum limit"
                                                       type="number"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#maximumLimitWrapper" required/>
                                            </div>

                                            <div id="policyPrefixWrapper" className="form-group parsley-input col-sm-6">
                                                <label>Policy Number Prefix<span className="tx-danger">*</span></label>
                                                <input id="policyNumber" className="form-control"
                                                       placeholder="Enter policy number prefix"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#policyPrefixWrapper" required/>
                                            </div>

                                            <div id="claimPrefixWrapper" className="form-group parsley-input col-sm-6">
                                                <label>Claim Number Prefix<span className="tx-danger">*</span></label>
                                                <input id="claimNumber" className="form-control"
                                                       placeholder="Enter claim prefix number"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#claimPrefixWrapper" required/>
                                            </div>
                                        </div>
                                        <button className="btn btn-brand-01 float-right btnNextAd">Next <i className="ion-md-arrow-forward"/></button>
                                    </form>
                                </div>

                                <div id="wordings" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="row row-sm">
                                                <form id="wordingForm" className="parsley-style-1 col-md-12" onSubmit={handleWordingSubmit.bind()}>
                                                    <div className="row row-sm">
                                                        <div id="headingWrapper" className="form-group parsley-input col-sm-12">
                                                            <label>Upload Wording File<span className="tx-danger">*</span></label>
                                                            <input id="wordingsFile" className="form-control"
                                                                   placeholder="Select"
                                                                   type="file"
                                                                   data-parsley-class-handler="#headingWrapper" required/>
                                                        </div>

                                                        <div id="wordingWrapper" className="form-group parsley-input col-sm-12">
                                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description<span className="tx-danger">*</span></label>
                                                            <textarea id="wordingsDescription" rows="5" className="form-control" placeholder="Provide a description of the wordings" data-parsley-class-handler="#wordingWrapper" required/>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        <hr/>
                                                        <a href="#" className="btn btn-dark float-left btnPrevious" onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back</a>
                                                        <button className="btn btn-brand-01 float-right btnNextAd">Next <i className="ion-md-arrow-forward"/></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="clauses" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="row row-sm">
                                                <form id="clausesForm" className="parsley-style-1 col-md-12" onSubmit={handleClausesSubmit.bind()}>
                                                    <div className="row row-sm">
                                                        <div id="clausesWrapper" className="form-group parsley-input col-sm-12">
                                                            <label>Upload Clauses File<span className="tx-danger">*</span></label>
                                                            <input id="clausesFile" className="form-control"
                                                                   placeholder="Select"
                                                                   type="file"
                                                                   data-parsley-class-handler="#clausesWrapper" required/>
                                                        </div>

                                                        <div id="clausesWrapper" className="form-group parsley-input col-sm-12">
                                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description<span className="tx-danger">*</span></label>
                                                            <textarea id="clausesDescription" rows="5" className="form-control" placeholder="Provide a description of the wordings" data-parsley-class-handler="#clausesWrapper" required/>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        <hr/>
                                                        <a href="#" className="btn btn-dark float-left btnPrevious" onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back</a>
                                                        <button className="btn btn-brand-01 float-right btnNextAd">Next <i className="ion-md-arrow-forward"/></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="perils" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <table id="td-perils" className="table" style={{width: "100%"}}>
                                                <thead>
                                                <tr>
                                                    <th className="wd-30p">Name</th>
                                                    <th className="wd-30p">Type</th>
                                                    <th className="wd-30p">Actions</th>
                                                </tr>
                                                </thead>
                                            </table>

                                            <div className="row row-sm">
                                                <form id="perilsForm" className="parsley-style-1 col-md-12" data-parsley-validate noValidate onSubmit={handlePerilsSubmit.bind()}>
                                                    <div className="row row-sm">
                                                        <div id="typeWrapper" className="form-group parsley-input col-sm-12">
                                                            <label>Type<span className="tx-danger">*</span></label>
                                                            <select className="custom-select" id="perilsType" onChange={onPerilsTypeChange.bind()} required>
                                                                <option value="" disabled selected>Select</option>
                                                                {perilsType.map((data) => <option value={data.name}>{data.name}</option>)}
                                                            </select>
                                                        </div>

                                                        <div id="perilsWrapper" className="form-group parsley-input col-sm-12">
                                                            <label>Name<span className="tx-danger">*</span></label>
                                                            <select className="custom-select" id="perilsName" required>
                                                                <option value="" disabled selected>Select</option>
                                                                {perilsName.map((data) => <option value={data}>{data}</option>)}
                                                            </select>
                                                        </div>

                                                        <div id="perilsDescriptionWrapper" className="form-group parsley-input col-sm-12">
                                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description<span className="tx-danger">*</span></label>
                                                            <textarea id="perilsDescription" type="text" rows="5" className="form-control" placeholder="Provide a descipriton of the perils" data-parsley-class-handler="#perilsDescriptionWrapper" required></textarea>
                                                        </div>

                                                        <div className="col-md-12 text-center">
                                                            <button className="btn btn-brand-02 mt-2"><i className="ion-md-add"/> Add List</button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="col-md-12 mt-3">
                                                    <hr/>
                                                    <button className="btn btn-dark float-left btnPrevious" onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back</button>
                                                    {createLoading ? <button disabled className="btn btn-brand-02 float-right"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-01 float-right btnNextFront" onClick={handleProductSubmit.bind()}>Finish <i className="ion-md-arrow-forward"/></button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Products;
