import React, { useEffect } from 'react';
import {GET_ALL_POLICIES_URL} from '../../api';
import {getStatistics} from '../../request';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import moment from "moment";
import {useSelector} from "react-redux";
import {encodeQueryParameter} from "../../utils/url";

const Dashboard = (props) => {

    document.title = "Dashboard";

    const {id} = useSelector((state) => state.auth.user.user);

    useEffect(() => {
        getStatistics().then((response) => {
            $('#clients').text(response.clients);
            $('#vehicles').text(response.vehicles);
            $('#policies').text(response.policies);
            $('#claims').text(response.claims);
        });

        var table = $('#policy').DataTable({
            responsive: true,
            language: {
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Policies Data</p>'
            },
            searching: false,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(`${GET_ALL_POLICIES_URL}`, {
                    type: 'GET',
                    data: {
                        pageNumber: (data.length + data.start) / data.length,
                        pageSize: data.length
                    },
                    success : function(res) {
                        let result = [];

                        res.data.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.totalRecords,
                            recordsFiltered: res.totalRecords,
                            data: result
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.policyNumber;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.product.name;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return moment(data.createdAt).add(1,'days').format("Do MMM, YYYY");
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.sourceOfBusiness;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return {
                            "Active" : `<span class="badge badge-success tx-white">${data.status}</span>`,
                            "Expired" : `<span class="badge badge-warning tx-white">${data.status}</span>`,
                            "Cancelled" : `<span class="badge badge-danger">${data.status}</span>`,
                            "Inclaim" : `<span class="badge badge-warning">${data.status}</span>`,
                            "InClaim" : `<span class="badge badge-warning">${data.status}</span>`,
                            "PayLater" : `<span class="badge badge-secondary">Pay Later</span>`,
                            "InReview" : `<span class="badge badge-info">InReview</span>`
                        }[data.status];
                    },
                    "targets": 4
                }
            ]
        });

        $('#policy tbody').on('click', 'tr', function () {
            var extract_data = table.row(this).data();
            props.history.push(`/policy-details/${extract_data.raw.clientId}/${encodeQueryParameter(extract_data.raw.id)}`);
        } );

    }, []);

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Dashboard</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Users</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="clients" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <a href="#/client"><span className="tx-11 tx-color-02 mg-b-0 mg-t-5">View</span></a>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Vehicles</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="vehicles" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">---</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Policies</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="policies" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <a href="#/policy"><span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">View</span></a>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Claims</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="claims" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <a href="#/claim"><span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">View</span></a>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Policies</h6>
                                <div className="d-flex tx-18">
                                    <a href="javascript:void(0)" onClick={() => $('#policy').DataTable().ajax.reload()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="policy" className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Policy #</th>
                                        <th className="wd-20p">Products</th>
                                        <th className="wd-20p">Cover From</th>
                                        <th className="wd-20p">Source of Business</th>
                                        <th className="wd-20p">Status</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Dashboard;
